@charset "UTF-8";

/* ---- Colors ---- */

:root {
  /* ------- Site colors --------- */
  --green: #61b045;
  --pink: #f55882;
  --blue: #094e9e;
  --purple: #893b97;
  --red-orange: #ff2c00;
  --orange: #f87f2f;
  --yellow: #e1b501;
  --light-blue: #54cdee;
  --light-pink: #f98eaa;
  --dark-green: #737a50;
  --dark-pink: #de40af;
  --main-pink: #ec1b53;
  --gray: #afaeae;
  /* ----- Text colors ------- */
  --text-primary: #000;
  --text-secondary: #fff;
  --text-pink: #ec1b53;
  --text-gray: #a7a7a7;
  --text-dark-gray: #707070;
  --text-green: #3c9e1c;
  /* ----- Icon colors ----- */
  --icon-gray: #707070;
  --icon-light-gray: #a7a7a7;
  --icon-pink: #ec1b53;
}

/* ---------------------------- */

/* ---- Null styles ---- */

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  color: var(--text-primary);
  line-height: 1;
  font-family: "Assistant";
  font-size: 1.25rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: "Assistant";
  font-size: inherit;
  line-height: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

summary {
  cursor: pointer;
}

/* ---------------------------------- */

html {
  direction: rtl;
}

/* ---- Body styles ---- */

body {
  position: relative;
  /* ---- scroll locked ---- */
}

body::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
  z-index: 105;
  pointer-events: none;
}

.lock body {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

body.menu-open {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

body.filter-open::before {
  opacity: 1;
  pointer-events: initial;
}

/* ---- Wrapper styles ---- */

.wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  /* ---- Footer pushed to bottom ---- */
}

@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}

.wrapper > main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.wrapper > * {
  min-width: 0;
}

/*
  container styles, will aply for all blocks with *__container
  like header__container, main__container etc
*/

[class*="__container"] {
  max-width: 77.5rem;
  margin: 0 auto;
  padding: 0 3.125rem;
}

/* ---- Buttons styles ---- */

.btn-container_center {
  text-align: center;
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--green);
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-secondary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn.main-cat {
  margin-bottom: 2vh;
  padding-left: 2vw;
}

.btn.copy-link {
  padding-left: 2em;
  position: relative;
  margin-top: 2vh;
}
.btn.copy-link .copy-link__tooltip {
  position: absolute;
  bottom: 100%;
  right: 100%;
}

.btn::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-white.svg") center/contain no-repeat;
}

.btn:active {
  background-color: var(--main-pink);
}

.btn._fw {
  width: 100%;
}

.btn-red {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--main-pink);
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-secondary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn-red::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-white.svg") center/contain no-repeat;
}

.btn-red._fw {
  width: 100%;
}

.btn-orange {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f8af1d;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-secondary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn-orange::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-white.svg") center/contain no-repeat;
}

.btn-orange._fw {
  width: 100%;
}

.btn-black {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-secondary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn-black::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-white.svg") center/contain no-repeat;
}

.btn-black:active {
  background-color: var(--green);
}

.btn-black._fw {
  width: 100%;
}

.btn-gray {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #959595;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-secondary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn-gray::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-white.svg") center/contain no-repeat;
}

.btn-gray._fw {
  width: 100%;
}

.btn-white {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-primary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.btn-white::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-black.svg") center/contain no-repeat;
}

.btn-white._fw {
  width: 100%;
}

.btn-outline-white {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.4375rem 0.9375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  font-weight: 600;
  color: var(--text-primary);
  border-radius: 1.5rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  border: 0.0625rem solid var(--text-primary);
}

.btn-outline-white::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.625rem;
  height: 1.25rem;
  background: url("../img/svg/arrow-left-black.svg") center/contain no-repeat;
}

.btn-outline-white._fw {
  width: 100%;
}

/* ------------------------------- */

/* ----- Input styles ----- */

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input {
  border-radius: 0.5rem !important;
  width: 100%;
  display: block;
  padding: 0.5rem 0.9375rem;
  border: 0.0625rem solid #d9d6d6;
  background-color: #fff;
  outline: none;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-primary);
  -webkit-transition: border-color 0.15s ease 0s;
  -o-transition: border-color 0.15s ease 0s;
  transition: border-color 0.15s ease 0s;
}
.city-street-select-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 10;
}
.input-address {
  pointer-events: none;
}

.input:focus {
  border-color: #000;
}

.input-error .input:not([type="search"]) {
  border-color: var(--red-orange) !important;
}
.input-error .input-title {
  color: var(--red-orange) !important;
}

.form-title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2777777778;
}

.form-title:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.input-wrapper {
  position: relative;
  display: block;
}
.input-wrapper.input-error .input-title {
  color: var(--red-orange);
}
.input-wrapper.input-error input:not([type="search"]),
.form-credentials-section__code-input-wrapper.input-error input:not([type="search"]) {
  border-color: var(--red-orange) !important;
}
.input-wrapper .error-text,
.form-edit-profile__input-wrapper .error-text,
.contact-us-form__input-wrapper .error-text {
  font-size: 1rem;
  color: var(--red-orange);
  line-height: 1.2;
  font-weight: 500;
  display: none;
}
.input-wrapper,
.form-edit-profile__input-wrapper .not-exist {
  font-size: 1rem;
  color: var(--red-orange);
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 120em) and (min-width: 47.99875em) {
  .credentials-page .input-wrapper .error-text {
    font-size: 2vh;
  }
 
}

.input-wrapper.input-error .error-text,
.form-edit-profile__input-wrapper.input-error .error-text,
.contact-us-form__input-wrapper.input-error .error-text {
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input-title {
  position: absolute;
  top: 0;
  right: 0.75rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 1.125rem;
  text-align: right;
  white-space: nowrap;
  color: var(--text-primary);
  padding: 0.0625rem 0.4375rem 0.25rem 0.5625rem;
  background-color: #fff;
  transition: color 0.15s ease 0s;
  -webkit-transition: color 0.15s ease 0s;
  -o-transition: color 0.15s ease 0s;
}

.input-title span {
  color: var(--text-green);
}

.mail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mail-wrapper:not(:last-child) {
  margin-bottom: 0.625rem;
}

.mail-wrapper label {
  display: block;
  margin-bottom: 0 !important;
}

.mail-wrapper label:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 36.8421052632%;
  flex: 0 0 36.8421052632%;
}

.mail-wrapper label:last-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55.2631578947%;
  flex: 0 0 55.2631578947%;
}

.tel-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
}

.tel-wrapper label {
  display: block;
  margin-bottom: 0 !important;
}

.tel-wrapper label:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70.2928870293%;
  flex: 0 0 70.2928870293%;
}

.tel-wrapper label:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.tel-wrapper::after {
  content: "-";
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--text-primary);
  align-self: flex-start;
  transform: translateY(55%);
}

.tel-wrapper:not(:last-child) {
  margin-bottom: 1.25rem;
}

.three-inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.three-inputs .input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27.1052631579%;
  flex: 0 0 27.1052631579%;
  width: 27.1052631579%;
}

input[placeholder]:not([data-placeholder-nohiden])::-webkit-input-placeholder {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[placeholder]:not([data-placeholder-nohiden])::-moz-placeholder {
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[placeholder]:not([data-placeholder-nohiden]):-ms-input-placeholder {
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[placeholder]:not([data-placeholder-nohiden])::-ms-input-placeholder {
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[placeholder]:not([data-placeholder-nohiden])::placeholder {
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[placeholder]:not([data-placeholder-nohiden]):focus::-webkit-input-placeholder {
  opacity: 0;
}

input[placeholder]:not([data-placeholder-nohiden]):focus::-moz-placeholder {
  opacity: 0;
}

input[placeholder]:not([data-placeholder-nohiden]):focus:-ms-input-placeholder {
  opacity: 0;
}

input[placeholder]:not([data-placeholder-nohiden]):focus::-ms-input-placeholder {
  opacity: 0;
}

input[placeholder]:not([data-placeholder-nohiden]):focus::placeholder {
  opacity: 0;
}

textarea.input {
  resize: none;
}

/* ---------------------------- */

.checkbox {
  position: relative;
}

.checkbox:not(:last-child) {
  margin-bottom: 0.3125em;
}

.checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox__input:checked + .checkbox__label::before {
  background: var(--text-green);
}

.checkbox_red .checkbox__input:checked + .checkbox__label::before {
  background: var(--main-pink);
}

.checkbox__input:checked + .checkbox__label::after {
  opacity: 1;
}

.checkbox__label {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  gap: 0.4375rem;
}

.checkbox__label::before {
  content: "";
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.375rem;
  flex: 0 0 1.375rem;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.0625rem solid #d9d6d6;
  border-radius: 0.4375rem;
  transition: border-color 0.2s ease 0s;
}

.checkbox__label::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.625rem;
  top: 0.375rem;
  right: 0.3125rem;
  background: url("../img/svg/check-white.svg") center/contain no-repeat;
  z-index: 2;
  opacity: 0;
}

.checkbox__text {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--text-secondary);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.2s ease 0s;
}
.btn .checkbox__text {
  color: var(--text-secondary);
}
.checkbox__label.input-error::before {
  border-color: var(--red-orange) !important;
}
.credentials-section .checkbox .checkbox__label.input-error .checkbox__text {
  color: var(--red-orange) !important;
}

.rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 1.5rem;
  line-height: 1;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
}

.rating.rating_sending {
  opacity: 0.2;
}

.rating.rating_set .rating__active,
.rating.rating_set .rating__item {
  cursor: pointer;
}

.rating__body {
  position: relative;
  width: 5.8125rem;
  height: 1.0625rem;
}

.rating__body::before {
  content: "";
  display: block;
  color: #fff;
}

.rating__active {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden;
}

.rating__active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: url("../img/svg/stars.svg") right center/auto 100% no-repeat;
}

.rating__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.rating__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  height: 100%;
  opacity: 0;
}

.rating__value {
  font-size: 50%;
  line-height: 1;
  padding: 0 0 0 0.625rem;
  display: none;
}

body::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.8s ease 0s;
  -o-transition: opacity 0.8s ease 0s;
  transition: opacity 0.8s ease 0s;
  pointer-events: none;
  z-index: 149;
}

.popup-show body::after {
  opacity: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1.875rem;
  -webkit-transition: visibility 0.8s ease 0s;
  -o-transition: visibility 0.8s ease 0s;
  transition: visibility 0.8s ease 0s;
  visibility: hidden;
  pointer-events: none;
}

.popup_show {
  z-index: 150;
  visibility: visible;
  overflow: auto;
  pointer-events: auto;
}

.popup_show .popup__content {
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.popup__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}

.popup__content {
  visibility: hidden;
  -webkit-transform: translateY(-6.25rem);
  -ms-transform: translateY(-6.25rem);
  transform: translateY(-6.25rem);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
  transition: opacity 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s, opacity 0.3s ease 0s;
  transition: transform 0.5s ease 0s, opacity 0.3s ease 0s;
  transition: transform 0.5s ease 0s, opacity 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
  background-color: transparent;
  padding: 1.875rem 1.25rem 3.75rem;
  min-height: 37.5rem;
  width: 100%;
  max-width: 37.5rem;
  border-radius: 2.8125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.lock .popup__content {
  visibility: visible;
}

.popup__close {
  position: absolute;
  z-index: 3;
  top: 2.1875rem;
  left: 2.8125rem;
  width: 1.875rem;
  height: 1.875rem;
}

.popup__close::before,
.popup__close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  height: 0.1875rem;
  background-color: #fff;
  border-radius: 0.3125rem;
}
.popup__close.already-bought::before,
.popup__close.already-bought::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;
  height: 0.1875rem;
  background-color: #000;
  border-radius: 0.3125rem;
}

.popup__close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.popup__close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup__body {
  position: relative;
  z-index: 2;
  max-width: 23.75rem;
  width: 100%;
  margin: 0 auto;
}

.popup__bg-ibg {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.popup__bg-ibg img {
  border-radius: 2.8125rem;
}

.popup__main-content {
  position: relative;
  z-index: 2;
}

.popup__image-ibg_contain {
  width: 9.1875rem;
  height: 8.8125rem;
  margin: auto;
}

.popup__image-ibg_contain:not(:last-child) {
  margin-bottom: 1.25rem;
}

.popup__image-ibg_contain > * {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.popup__title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  color: var(--text-secondary);
}

.popup__big-title {
  font-weight: bold;
  font-size: 3.125rem;
  line-height: 1.08;
  text-align: center;
  color: #fff;
}

.popup__price {
  text-align: center;
  font-size: 6.25rem;
  line-height: 1;
  font-weight: 600;
  color: var(--text-secondary);
}

.popup__price .money-icon {
  font-size: 0.33em;
}

.popup__buttons-container {
  max-width: 17.1875rem;
  margin: auto;
  margin-top: 1.25rem;
}

.popup__buttons-container > * {
  width: 100%;
}

.popup__buttons-container > *:not(:last-child) {
  margin-bottom: 1.25rem;
}

.popup__buttons-container > *::after {
  left: 0.875rem;
}

.popup__buttons-flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.625rem;
  max-width: 12.1875rem;
  margin: 1.625rem auto 0;
}

.popup__buttons-flex-container > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 0.3125rem);
  flex: 0 0 calc(50% - 0.3125rem);
  width: calc(50% - 0.3125rem);
  padding-right: 0.4375rem;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white .icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.75rem;
  z-index: 2;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white.gift .icon {
  width: 1.3125rem;
  height: 1.4375rem;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white.favorites .icon {
  width: 1.4375rem;
  height: 1.8125rem;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white.favorites[data-count] {
  position: relative;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white.favorites[data-count]::before {
  content: attr(data-count);
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 3;
  border-radius: 50%;
  font-size: 1.125rem;
  color: #fff;
  background-color: var(--green);
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#item-added-to-cart-popup .popup__buttons-container .btn-white.money .icon {
  width: 2.125rem;
  height: 2.1875rem;
}

#blessing-goodbye-popup .popup__content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#blessing-goodbye-popup .popup__body {
  padding-top: 2.5rem;
}

#blessing-created-popup .popup__body {
  max-width: 15.625rem;
}

#blessing-created-popup .popup__buttons-container {
  margin-top: 2.8125rem;
}

#blessing-created-popup .popup__buttons-container > * {
  border-radius: 1.125rem/1.4375rem;
}

#blessing-created-popup .popup__buttons-container > *:not(:last-child) {
  margin-bottom: 0.8125rem;
}

#blessing-created-popup .popup__buttons-container .btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.125rem;
  width: 1.3125rem;
  height: 2.0625rem;
  background: url("../img/svg/plus-circle.svg") center/contain no-repeat;
  z-index: 2;
}

#blessing-created-reminder-popup .popup__buttons-container {
  margin-top: 2.8125rem;
}

#blessing-created-reminder-popup .popup__buttons-container > * {
  border-radius: 1.125rem/1.4375rem;
}

#blessing-created-reminder-popup .popup__buttons-container > *:not(:last-child) {
  margin-bottom: 0.8125rem;
}

#blessing-created-reminder-popup .popup__buttons-container .btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.125rem;
  width: 1.3125rem;
  height: 2.0625rem;
  background: url("../img/svg/plus-circle.svg") center/contain no-repeat;
  z-index: 2;
}

#please-choose-category-popup .popup__buttons-container {
  max-width: 10.375rem;
}

#please-choose-category-popup .popup__buttons-container .btn-white::after {
  display: none;
}

#code-not-right-popup-green .popup__image-ibg_contain:not(:last-child) {
  margin-bottom: 0.625rem;
}

#code-not-right-popup-green .popup__buttons-container {
  margin-top: 2.0625rem;
  max-width: 10.375rem;
}

#code-not-right-popup-green .popup__buttons-container .btn-white::after {
  display: none;
}

#code-not-right-popup-pink .popup__buttons-container {
  margin-top: 2.0625rem;
  max-width: 12.5rem;
  width: 100%;
}

#code-not-right-popup-pink .popup__buttons-container .btn-white::after {
  display: none;
}

#we-send-you-sms-popup-green .popup__image-ibg_contain {
  height: 4.0625rem;
}

#we-send-you-sms-popup-green .popup__title {
  margin-top: -0.625rem;
}

#we-send-you-sms-popup-green .popup__buttons-container {
  margin-top: 2.0625rem;
  max-width: 10.375rem;
  width: 100%;
}

#we-send-you-sms-popup-green .popup__buttons-container .btn-white::after {
  display: none;
}

#we-send-you-sms-popup-pink .popup__title {
  margin-top: -4.375rem;
}

#we-send-you-sms-popup-pink .popup__buttons-container {
  margin-top: 2.0625rem;
  max-width: 12.5rem;
  width: 100%;
}

#we-send-you-sms-popup-pink .popup__buttons-container .btn-white::after {
  display: none;
}

#thanks-for-send-present-popup .popup__title {
  font-size: 1.6875rem;
  font-weight: 700;
}

#thanks-for-send-present-popup .popup__title:first-child {
  font-size: 2.1875rem;
}

#thanks-for-send-present-popup .popup__image-ibg_contain {
  width: 14.0625rem;
  height: 15.3125rem;
}

#thanks-for-send-money-popup .popup__title {
  font-size: 1.6875rem;
  font-weight: 700;
}

#thanks-for-send-money-popup .popup__title span {
  color: #ffd478;
}

#thanks-for-send-money-popup .popup__title:first-child {
  font-size: 2.1875rem;
  margin-top: 2.1875rem;
}

#thanks-for-send-money-popup .popup__image-ibg_contain {
  width: 14.0625rem;
  height: 15.3125rem;
}

#event-created-green-popup .popup__content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#event-created-green-popup .popup__body {
  padding-top: 2.5rem;
}

.popup_image-content .popup__content {
  padding: 0;
  overflow: hidden;
  min-height: 0;
  height: auto;
  max-width: 48.75rem;
}

.popup_image-content .popup__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
}

.popup_image-content .popup__top {
  position: relative;
  padding: 5.3125rem 1.875rem 2.8125rem 1.875rem;
}

.popup_image-content .popup__top > * {
  position: relative;
  z-index: 2;
}

.popup_image-content .popup__top .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

.popup_image-content .popup__image-ibg_contain {
  width: 15.3125rem;
  height: 15.3125rem;
}

.popup_image-content .popup__bottom {
  background-color: #fff;
  padding: 4.0625rem 1.875rem 5.625rem;
  color: var(--text-primary);
  text-align: center;
}

.popup_image-content .popup__subtitle {
  font-size: 1.6875rem;
  line-height: 1.037037037;
  font-weight: 600;
  max-width: 16.625rem;
  margin: 0 auto;
}

.popup_image-content .popup__subtitle:not(:last-child) {
  margin-bottom: 1.375rem;
}

.popup_image-content .popup__bold-text {
  font-size: 1.5rem;
  line-height: 1.1666666667;
  font-weight: 700;
}

.popup_image-content .popup__bold-text:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.popup_image-content .popup__price {
  font-size: 5rem;
  color: var(--text-green);
  font-weight: 600;
}

.popup_image-content .popup__price:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.popup_image-content .popup__price span {
  font-size: 0.4125em;
}

.popup_image-content .popup__buttons-container {
  max-width: 20rem;
}

.popup_image-content .popup__buttons-container .btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.25rem;
  width: 1.5rem;
  height: 1.6875rem;
  background: url("../img/svg/gift.svg") center/contain no-repeat;
}

.popup_image-content .popup__sad-smile {
  width: 5.5625rem;
  margin: 0 auto;
}

.popup_image-content .popup__sad-smile:not(:last-child) {
  margin-bottom: 0.625rem;
}

.popup_image-content .popup__sad-smile > * {
  width: 100%;
  height: auto;
}

.bank-popup .popup__title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.35;
}

.bank-popup .popup__title:not(:last-child) {
  margin-bottom: 1.875rem;
}

#bank-empty-popup .popup__subtitle:not(:last-child) {
  margin-bottom: 0.625rem;
}

#already-bought-popup {
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}

#already-bought-popup .popup__content {
  max-width: 32.3125rem;
  padding: 5rem 4.6875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}

#already-bought-popup .popup__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

#already-bought-popup .popup__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-green);
  line-height: 1.4166666667;
}

#already-bought-popup .popup__title:not(:last-child) {
  margin-bottom: 1.625rem;
}

#already-bought-popup .popup__list {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
  height: calc(100 * var(--vh) - 18.75rem);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100 * var(--vh) - 18.75rem);
  flex: 0 0 calc(100 * var(--vh) - 18.75rem);
}

#already-bought-popup .popup__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.4375rem 0;
  border-bottom: 0.0625rem solid #d9d6d6;
}

#already-bought-popup .popup__list-item .avatar {
  width: 3.25rem;
  height: 3.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 3.25rem;
  flex: 0 0 3.25rem;
  margin-left: 0.875rem;
}

#already-bought-popup .popup__list-item .avatar > * {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

#already-bought-popup .popup__list-item .text {
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--text-primary);
}

#already-bought-popup .popup__list-item .name {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4.875rem;
  flex: 0 0 4.875rem;
  margin-left: 1.25rem;
}

#already-bought-popup .popup__list-item .product {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-left: 1.5rem;
}

#already-bought-popup .switch {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 1.1875rem;
}

#already-bought-popup .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#already-bought-popup .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border: 0.0625rem solid #afaeae;
}

#already-bought-popup .slider::before {
  position: absolute;
  content: "";
  height: 0.9375rem;
  width: 0.9375rem;
  right: 0.125rem;
  top: 0.0625rem;
  background-color: #afaeae;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

#already-bought-popup input:checked + .slider::before {
  background-color: #f8af1d;
}

#already-bought-popup input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #f8af1d;
  box-shadow: 0 0 1px #f8af1d;
}

#already-bought-popup input:checked + .slider:before {
  -webkit-transform: translateX(-1.8125rem);
  -ms-transform: translateX(-1.8125rem);
  transform: translateX(-1.8125rem);
}

#already-bought-popup .slider.round {
  border-radius: 0.625rem;
}

#already-bought-popup .slider.round:before {
  border-radius: 50%;
}

[class*="-ibg"] {
  position: relative;
}

[class*="-ibg"] img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

[class*="-ibg_contain"] img {
  -o-object-fit: contain;
  object-fit: contain;
}

/* ---- Lazy load animation from 0 opacity to 1 ---- */

._lazy-load {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}

._lazy-load._lazy-loaded {
  opacity: 1;
}

/* ----------------------------- */

/* ----- Images with people or not ----------- */

.img-people {
  display: block;
}

.img-no-people {
  display: none;
}

.no-people-page .img-people {
  display: none;
}

.no-people-page .img-no-people {
  display: block;
}

/* ---- Common small blocks styles ---- */

.topbar {
  padding: 1.125rem 0;
  position: relative;
  overflow: hidden;
}

.topbar__bg-img-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.topbar__container {
  position: relative;
  z-index: 2;
}

.topbar__title-wrapper {
  text-align: center;
}

.topbar__title {
  font-family: "Segoe UI";
  font-weight: 700;
  font-size: 2.8125rem;
  line-height: 1.1111111111;
  text-align: center;
  color: #fff;
  padding: 0.625rem 7.5rem;
  display: inline-block;
  position: relative;
}

.topbar__title .icon {
  position: absolute;
  width: 4.6875rem;
  height: 100%;
  left: 0;
  top: 0;
}

.topbar__title .icon > * {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.breadcrumbs {
  padding: 0.5rem 0;
  background-color: #f2f2f2;
}

.breadcrumbs__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 0.1875rem;
}

.breadcrumbs__item a,
.breadcrumbs__item p {
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--text-gray);
  line-height: 1.25;
  position: relative;
  padding-left: 1.0625rem;
  display: block;
}

.breadcrumbs__item a::after {
  content: "";
  position: absolute;
  width: 0.875rem;
  height: 100%;
  left: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("../img/svg/double-arrow-left.svg") center/contain no-repeat;
}

.breadcrumbs__item:first-child a {
  opacity: 0.45;
}

.numbers-steps_orange .numbers-steps__list::before {
  background-color: #ffaf00;
}

.numbers-steps_orange.step-1 .numbers-steps__list-item:first-child .numbers-steps__step {
  color: var(--text-secondary);
  border-color: #ffaf00 !important;
  background-color: #ffaf00 !important;
}

.numbers-steps_orange.step-2 .numbers-steps__list-item:nth-child(2) .numbers-steps__step {
  color: var(--text-secondary);
  background-color: #ffaf00 !important;
  border-color: #ffaf00 !important;
}

.numbers-steps_orange.step-2 .numbers-steps__list-item:nth-child(1) .numbers-steps__step {
  color: #ffaf00 !important;
  background-color: #fff;
  border-color: #ffaf00 !important;
}

.numbers-steps_orange.step-3 .numbers-steps__list-item:nth-child(3) .numbers-steps__step {
  color: #fff;
  background-color: #ffaf00 !important;
  border-color: #ffaf00 !important;
}

.numbers-steps_orange.step-3 .numbers-steps__list-item:nth-child(1) .numbers-steps__step,
.numbers-steps_orange.step-3 .numbers-steps__list-item:nth-child(2) .numbers-steps__step {
  color: #ffaf00 !important;
  background-color: #fff;
  border-color: #ffaf00 !important;
}

.numbers-steps_orange.step-4 .numbers-steps__list-item:nth-child(4) .numbers-steps__step {
  color: #fff;
  background-color: #ffaf00 !important;
  border-color: #ffaf00 !important;
}

.numbers-steps_orange.step-4 .numbers-steps__list-item:nth-child(1) .numbers-steps__step,
.numbers-steps_orange.step-4 .numbers-steps__list-item:nth-child(2) .numbers-steps__step,
.numbers-steps_orange.step-4 .numbers-steps__list-item:nth-child(3) .numbers-steps__step {
  color: #ffaf00 !important;
  background-color: #fff;
  border-color: #ffaf00 !important;
}

.numbers-steps__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 34.0625rem;
  margin: auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.numbers-steps__list::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 0.125rem;
  background-color: #bbb;
}

.numbers-steps__list::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0.125rem;
  z-index: 2;
  background-color: var(--main-pink);
}

.numbers-steps__list-item {
  position: relative;
  z-index: 2;
}

.numbers-steps__step {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.125rem solid var(--text-gray);
  background-color: #fff;
  font-family: Anton;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 1.25;
  color: var(--text-gray);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.numbers-steps.step-1 .numbers-steps__list-item:first-child .numbers-steps__step {
  color: var(--text-secondary);
  background-color: var(--main-pink);
  border-color: var(--main-pink);
}

.numbers-steps.step-2 .numbers-steps__list::before {
  width: 33.3333%;
}

.numbers-steps.step-2 .numbers-steps__list-item:nth-child(2) .numbers-steps__step {
  color: var(--text-secondary);
  background-color: var(--main-pink);
  border-color: var(--main-pink);
}

.numbers-steps.step-2 .numbers-steps__list-item:nth-child(1) .numbers-steps__step {
  color: var(--main-pink);
  background-color: #fff;
  border-color: var(--text-pink);
}

.numbers-steps.step-3 .numbers-steps__list::before {
  width: 66.6666%;
}

.numbers-steps.step-3 .numbers-steps__list-item:nth-child(3) .numbers-steps__step {
  color: #fff;
  background-color: var(--main-pink);
  border-color: var(--main-pink);
}

.numbers-steps.step-3 .numbers-steps__list-item:nth-child(1) .numbers-steps__step,
.numbers-steps.step-3 .numbers-steps__list-item:nth-child(2) .numbers-steps__step {
  color: var(--main-pink);
  background-color: #fff;
  border-color: var(--text-pink);
}

.numbers-steps.step-4 .numbers-steps__list::before {
  width: 100%;
}

.numbers-steps.step-4 .numbers-steps__list-item:nth-child(4) .numbers-steps__step {
  color: #fff;
  background-color: var(--main-pink);
  border-color: var(--main-pink);
}

.numbers-steps.step-4 .numbers-steps__list-item:nth-child(1) .numbers-steps__step,
.numbers-steps.step-4 .numbers-steps__list-item:nth-child(2) .numbers-steps__step,
.numbers-steps.step-4 .numbers-steps__list-item:nth-child(3) .numbers-steps__step {
  color: var(--main-pink);
  background-color: #fff;
  border-color: var(--text-pink);
}

.filter-btn-container .filter-btn {
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 0.625rem;
  background-color: var(--green);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
}

.filter-btn-container .filter-btn:hover {
  background-color: #80c261;
}

.filter-btn-container .filter-btn > * {
  width: 50%;
  height: 64.8148148148%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  -o-object-fit: contain;
  object-fit: contain;
}

.side-menu {
  position: fixed;
  top: 50%;
  left: 1.25rem;
  height: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

.side-menu__body {
  border-radius: 2.15625rem;
  background: #fff;
  -webkit-box-shadow: 0.25rem 0.25rem 1.75rem rgba(0, 0, 0, 0.16);
  box-shadow: 0.25rem 0.25rem 1.75rem rgba(0, 0, 0, 0.16);
  padding: 1.4375rem 0.625rem 1.125rem;
}

.side-menu__links-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.side-menu__link.active {
  position: relative;
}
.side-menu__link.active::after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 50%;
}
.side-menu__link.active.red::after {
  background-color: rgba(234, 129, 129, 0.3);
}

.side-menu__link.active.green::after {
  background-color: rgba(129, 234, 174, 0.3);
  width: 100%;
  height: 100%;
}

.side-menu__link.active.orange::after {
  background-color: rgba(234, 141, 72, 0.3);
  /* width: 100%; */
  /* height: 100%; */
}
.side-menu__link.active.orange.behind::after {
  z-index: 0;
}
.side-menu__link .side-menu__toaster {
  display: none;
}
.side-menu__link.active .side-menu__toaster {
  position: absolute;
  display: block;
  left: calc(100% + 2px);
  width: max-content;
  top: 50%;
  transform: translateY(-50%);
  color: var(--green);
  font-size: 1.7vh;
  background-color: #fff;
  border-radius: 20px;
  line-height: 1;
  height: max-content;
  padding: 0px 3px;
}

.side-menu__item:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.side-menu__item:nth-child(2) a[data-count]::before {
  -webkit-transform: translate(-100%, -60%);
  -ms-transform: translate(-100%, -60%);
  transform: translate(-100%, -60%);
}

.side-menu__item a {
  display: block;
  width: 2.4375rem;
  height: 2.4375rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.4375rem;
  flex: 0 0 2.4375rem;
}

.side-menu__item a[data-count] {
  position: relative;
}

.side-menu__item a[data-count]::before {
  content: attr(data-count);
  position: absolute;
  width: 1.3125rem;
  height: 1.3125rem;
  top: 50%;
  right: 0;
  -webkit-transform: translate(5%, -65%);
  -ms-transform: translate(5%, -65%);
  transform: translate(5%, -65%);
  z-index: 2;
  border-radius: 50%;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--text-green);
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.side-menu__item a > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

/* -------------------------------------------- */

/* ---- Commons sections and items styles ---- */

main:not(:first-child) {
  padding-top: 9.75rem;
}

.single-event-type__content {
  display: block;
  background-color: #fff;
  border-radius: 0.625rem;
  overflow: hidden;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
}

.single-event-type__image-ibg {
  width: 100%;
  padding-bottom: 79.4797687861%;
}

.single-event-type__image-ibg img {
  border-radius: 0.625rem;
}

.single-event-type__text-content {
  padding-bottom: 1.25rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.single-event-type__icon {
  width: 2.625rem;
  height: 2.625rem;
  margin: 0 auto;
  margin-top: -1.3125rem;
  border-radius: 0.625rem;
  background-color: var(--pink);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.single-event-type__icon:not(:last-child) {
  margin-bottom: 0.6875rem;
}

.single-event-type__icon > * {
  width: 71.4285714286%;
  height: 71.4285714286%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 71.4285714286%;
  flex: 0 0 71.4285714286%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-event-type__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.125;
  text-align: center;
  color: var(--text-primary);
}

.single-event-type__title:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.single-event-type__btn-container .btn {
  font-size: 1.125rem;
  max-width: 14.375rem;
  width: 100%;
  padding: 0.625rem 0.9375rem;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

/* ------------------------------------------- */

/* ----- Header styles ----- */

.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-filter: drop-shadow(0 0.3125rem 2.4375rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0.3125rem 2.4375rem rgba(0, 0, 0, 0.16));
}

.header__desktop {
  padding-top: 1.0625rem;
  /* padding-bottom: 1rem; */
}

.header-submenu {
  position: absolute;
  top: 100%;
  background-color: #fff;
  width: 100vw;
  padding: 1.3125rem 2.5rem;
  display: none;
}
.header-submenu.visible {
  display: block;
}

.header-submenu__cont {
  max-width: 120rem;
  margin: 0 auto;
}

.header-submenu__selects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding-bottom: 0.9375rem;
  border-bottom: 2px solid #eee;
}

.header-submenu .custom-select {
  border: 2px solid #868686;
  padding: 0.5rem 0.3125rem;
  border-radius: 0.375rem;
  position: relative;
}
.header-submenu .custom-select .selected-option {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1;
  gap: 0.375rem;
  color: #868686;
}
.header-submenu .custom-select:hover .selected-option,
.header-submenu .custom-select.active .selected-option {
  color: #fff;
}
.header-submenu .custom-select.blue:hover,
.header-submenu .custom-select.blue.active {
  background-color: rgba(62, 39, 245, 0.8);
  border-color: rgba(62, 39, 245, 0.8);
}

.header-submenu .custom-select.light-green:hover {
  background-color: rgba(39, 245, 135, 0.8);
  border-color: rgba(39, 245, 135, 0.8);
}

.header-submenu .custom-select.purple:hover {
  background-color: rgba(110, 0, 153, 0.8);
  border-color: rgba(110, 0, 153, 0.8);
}

.header-submenu .custom-select.dark-red:hover {
  background-color: rgba(250, 0, 0, 1);
  border-color: rgba(250, 0, 0, 1);
}

.header-submenu .custom-select.dark-yellow:hover {
  background-color: rgba(169, 167, 0, 0.98);
  border-color: rgba(169, 167, 0, 0.98);
}

.header-submenu .custom-select.vivid-orange:hover {
  background-color: rgba(255, 156, 0, 0.61);
  border-color: rgba(255, 156, 0, 0.61);
}

.header-submenu .custom-select.turquoise:hover {
  background-color: rgba(25, 169, 204, 0.98);
  border-color: rgba(25, 169, 204, 0.98);
}

.header-submenu .custom-select.pink:hover {
  background-color: rgba(255, 179, 248, 0.98);
  border-color: rgba(255, 179, 248, 0.98);
}

.header-submenu .custom-select.olive-green:hover {
  background-color: rgba(96, 117, 53, 0.83);
  border-color: rgba(96, 117, 53, 0.83);
}

.header-submenu .custom-select.lime-green:hover {
  background-color: rgba(42, 201, 12, 1);
  border-color: rgba(42, 201, 12, 1);
}

.header-submenu .custom-select .arrow-down {
  position: relative;
  /* top: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-submenu .custom-select .arrow-down:hover {
  cursor: pointer;
}
.header-submenu .custom-select .arrow-down svg {
  width: 0.5rem;
  flex: 0 0 0.5rem;
  display: block;
  height: auto;
  /* fill: #868686; */
}
.header-submenu .custom-select .arrow-down svg path {
  fill: #868686;
}
.header-submenu .custom-select:hover .arrow-down svg path,
.header-submenu .custom-select.active .arrow-down svg path {
  fill: #fff;
}
.header-submenu .custom-select .arrow-down svg.white {
  width: 0.5rem;
  background-color: #fff;
}
.header-submenu .custom-select .icon {
  display: block;
  /* margin-left: 5px; */
  width: 0.9375rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-submenu .custom-select .icon img,
.header-submenu .custom-select .icon svg {
  width: 100%;
  flex: 0 0 100%;
  display: block;
}

.header-submenu .custom-select:hover .icon img,
.header-submenu .custom-select:hover .icon svg,
.header-submenu .custom-select.active .icon img,
.header-submenu .custom-select.active .icon svg {
  filter: grayscale(0) brightness(500%);
}

.main-gift-basket__my-purchases-list {
  margin-bottom: 2em;
}

.header-submenu .custom-select .options {
  position: absolute;
  top: calc(100% + 2px);
  background-color: #fff;
  width: 100%;
  right: 0px;
  left: 0px;
}
.header-submenu .custom-select .option {
  cursor: pointer;
}

.header-submenu__bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 1.25rem;
  padding: 1.25rem 0;
  min-height: 20rem;
}
.header-submenu__bottom .btn-categories-search {
  padding-left: 2em;
  position: absolute;
  bottom: 2em;
  left: 50%;
}

.header-submenu__col {
  width: calc(20% - 1rem);
  flex: 0 0 calc(20% - 1rem);
}
.header-submenu__col:nth-child(2) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.header-submenu__col:nth-child(3) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.header-submenu__col:nth-child(4) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.header-submenu__img-box {
  position: relative;
  height: 100%;
}

.header-submenu__img-box .btn {
  width: 80%;
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
}

.header-submenu__title {
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: 700;
}

.header-submenu__img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 0.875rem;
}

.submenu-tab:not(:last-child) {
  margin-bottom: 0.625rem;
}
.submenu-tab.active .submenu-tab__body,
.submenu-tab[open] .submenu-tab__body {
  display: block;
  /* width: calc(100% - 2.5vw); */
  width: calc(100% - 3rem);
  /* margin: 0 2.5vw 0 0; */
  margin: 0.25rem 3rem 0 0;
  /* max-width: calc(100% - 2.5vw); */
  max-width: calc(100% - 3rem);
}
.submenu-tab.active .submenu-tab__arrow,
.submenu-tab[open] .submenu-tab__arrow {
  /* transform: rotate(-90deg); */
  transform: rotate(0);
}

.submenu-tab__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625rem;
}
.submenu-tab__header:hover {
  background-color: var(--pink);
  color: #fff;
}

.submenu-tab__header:hover .submenu-tab__arrow svg path {
  fill: #fff;
}

.submenu-tab__arrow {
  color: var(--pink);
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  transform: rotate(90deg);
  margin-left: 0.5rem;
}
.submenu-tab__arrow img,
.submenu-tab__arrow svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}
.submenu-tab__arrow svg path {
  fill: var(--pink);
}

.submenu-tab__header:hover .submenu-tab__arrow {
  color: #fff;
}

.submenu-tab__icon {
  /* width: 2.1875vw;
  height: 2.1875vw; */
  width: 2.625rem;
  height: 2.625rem;
  background-color: var(--pink);
  /* border-radius: 0.520833333vw; */
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 1vw; */
  margin-left: 0.625rem;
}
.submenu-tab__icon img,
.submenu-tab__icon svg {
  width: 62%;
  flex: 0 0 62%;
  height: auto;
  display: block;
}

.submenu-tab__title {
  margin-left: auto;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
}

.submenu-tab__body .single-category-type__dropdown-list {
  /* margin-right: 3rem; */
  max-width: 100%;
}

.submenu-tab__body {
  /* position: absolute;
  top: 100%;
  right: 0; */
  /* display: none; */
}

.header-submenu .checkbox__label {
  gap: 0.625rem;
}
.header-submenu .checkbox__label::before {
  width: 0.8125rem;
  height: 0.8125rem;
  flex: 0 0 0.8125rem;
  border-radius: 0.1875rem;
  background: #fff;
  border: 0.03125rem solid #8f8f8f;
  align-self: center;
}
.header-submenu .checkbox__label::after {
  display: none;
}
.header-submenu .checkbox__text {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: right;
  color: var(--text-primary);
}
.header-submenu .checkbox__input:checked + .checkbox__label::before {
  background-color: var(--main-pink);
}

@media (max-width: 120em) {
  .header-submenu .checkbox__label {
    gap: 0.521vw;
  }
  .header-submenu .checkbox__label::before {
    width: 0.6771vw;
    height: 0.6771vw;
    flex: 0 0 0.6771vw;
    border-radius: 0.15625vw;
  }
  .header-submenu .checkbox__text {
    font-size: 0.7292vw;
  }
  .header-submenu {
    padding: 1.09375vw 2.08333vw;
  }
  .header-submenu__selects {
    row-gap: 1.041666vw;
    padding-bottom: 0.78125vw;
  }
  .header-submenu .custom-select {
    padding: 0.416666vw 0.26042vw;
    border-radius: 0.3125vw;
  }
  .header-submenu .custom-select .selected-option {
    font-size: 0.7291666vw;
    gap: 0.3125vw;
  }
  .header-submenu .custom-select .arrow-down svg {
    width: 0.416666vw;
    flex: 0 0 0.416666vw;
  }
  .header-submenu .custom-select .arrow-down svg.white {
    width: 0.416666vw;
  }
  .header-submenu .custom-select .icon {
    width: 0.78125vw;
    height: 1.0416666vw;
  }
  .header-submenu__bottom {
    column-gap: 1.0416666vw;
    padding: 1.0416666vw 0;
  }
  .header-submenu__col {
    width: calc(20% - 0.83333vw);
    flex: 0 0 calc(20% - 0.83333vw);
  }
  .header-submenu__col:nth-child(2) {
    padding-right: 1.25vw;
    padding-left: 1.25vw;
  }
  .header-submenu__col:nth-child(3) {
    padding-right: 1.25vw;
    padding-left: 1.25vw;
  }
  .header-submenu__col:nth-child(4) {
    padding-right: 1.25vw;
    padding-left: 1.25vw;
  }
  .header-submenu__img-box .btn {
    bottom: 1.0416666vw;
  }
  .header-submenu__title {
    top: 1.0416666vw;
  }
  .header-submenu__img {
    border-radius: 0.7291666vw;
  }
  .submenu-tab:not(:last-child) {
    margin-bottom: 0.521vw;
  }
  .submenu-tab.active .submenu-tab__body {
    width: calc(100% - 2.5vw);
    margin: 0.208333vw 2.5vw 0 0;
    max-width: calc(100% - 2.5vw);
  }
  .submenu-tab__header {
    border-radius: 0.521vw;
  }
  .submenu-tab__arrow {
    width: 0.625vw;
    height: 0.625vw;
    margin-left: 0.416666vw;
  }
  .submenu-tab__icon {
    width: 2.1875vw;
    height: 2.1875vw;
    border-radius: 0.521vw;
    margin-left: 0.521vw;
  }
  .submenu-tab__title {
    font-size: 1.0416666vw;
  }
  .submenu-tab__body.single-category-type__dropdown-list {
    margin-right: 2.5vw;
  }
}

/* ------------------------------------------------------ */

.header__logo {
  width: 10.375rem;
  display: block;
  margin: auto;
  margin-top: -1.25rem;
}

.header__logo:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.header__logo > * {
  width: 100%;
  height: auto;
}

.top-header__container {
  max-width: 120rem;
}

.top-header__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.top-header__search {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 11.25rem;
  flex: 0 1 11.25rem;
}

.search-form__input-wrapper {
  display: block;
  position: relative;
}

.search-form__input-wrapper .search-input {
  font-size: 1rem;
  padding: 0.125rem;
  display: block;
  width: 100%;
  border-bottom: 0.0625rem solid var(--gray);
  outline: none;
  color: #000;
  -webkit-transition: border-color 0.3s ease 0s;
  -o-transition: border-color 0.3s ease 0s;
  transition: border-color 0.3s ease 0s;
}

.search-form__input-wrapper .search-input:focus {
  border-color: #000;
}

.search-form__input-wrapper .search-input::-webkit-input-placeholder {
  color: #707070;
}

.search-form__input-wrapper .search-input::-moz-placeholder {
  color: #707070;
}

.search-form__input-wrapper .search-input:-ms-input-placeholder {
  color: #707070;
}

.search-form__input-wrapper .search-input::-ms-input-placeholder {
  color: #707070;
}

.search-form__input-wrapper .search-input::placeholder {
  color: #707070;
}

.search-form__input-wrapper .search-button {
  position: absolute;
  top: 50%;
  left: 0.1875rem;
  width: 0.6875rem;
  height: 0.6875rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-form__input-wrapper .search-button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.menu-top-header__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
}

.menu-top-header__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.menu-top-header__link .image {
  width: 1.5rem;
  height: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
}

.menu-top-header__link .image:not(:last-child) {
  margin-bottom: 0.1875rem;
}

.menu-top-header__link .image > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.menu-top-header__link .image[data-count] {
  position: relative;
}

.menu-top-header__link .image[data-count]::before {
  content: attr(data-count);
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  top: 50%;
  right: 0;
  -webkit-transform: translate(45%, -50%);
  -ms-transform: translate(45%, -50%);
  transform: translate(45%, -50%);
  z-index: 2;
  border-radius: 50%;
  font-size: 0.625rem;
  color: #fff;
  background-color: var(--green);
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-top-header__link .text {
  font-size: 0.625rem;
  font-weight: 600;
  text-align: center;
}

.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.625rem;
}

.menu__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.125rem;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: var(--text-primary);
  cursor: pointer;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.menu__link .icon {
  width: 1.875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.875rem;
  flex: 0 0 1.875rem;
  height: 1.4375rem;
}

.menu__link .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.menu__link.dropdown {
  position: relative;
}

.menu__link.dropdown .dropdown__body {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 0.9375rem;
  min-width: 12.5rem;
  z-index: 101;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
}

.menu__link.dropdown .dropdown__link {
  display: block;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: right;
  color: var(--text-primary);
  background-color: #fff;
  width: 100%;
  line-height: 1.3;
  padding: 0.625rem 1.75rem;
  white-space: nowrap;
  -webkit-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
  transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
}

.menu__link.dropdown .dropdown__link:not(:last-child) {
  border-bottom: 0.0625rem solid var(--main-pink);
}

.menu__link.dropdown:hover .dropdown__body {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

.menu__list {
  gap: 0.5208vw;
}

/* -------------------------------- */

/* ----- Mobile menu ----- */

.header-mobile {
  display: none;
}

.header-mobile__container {
  padding-left: 0.625rem;
  padding-right: 0;
}

.header-mobile__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-mobile__burger-container {
  position: relative;
  z-index: 103;
  padding: 1.0625rem 0.625rem;
}

.menu-open .header-mobile__burger-container {
  background-color: #fff;
}

.header-mobile__other-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}

.header-mobile__notifications {
  width: 2.5625rem;
  height: 2.75rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.5625rem;
  flex: 0 0 2.5625rem;
  position: relative;
}

.header-mobile__notifications > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.header-mobile__notifications[data-count] {
  position: relative;
}

.header-mobile__notifications[data-count]::before {
  content: attr(data-count);
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  top: 0;
  right: 0;
  -webkit-transform: translate(10%, 0);
  -ms-transform: translate(10%, 0);
  transform: translate(10%, 0);
  z-index: 2;
  border-radius: 50%;
  font-size: 1.125rem;
  color: #fff;
  background-color: var(--text-green);
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-mobile__prev {
  width: 2.6875rem;
  height: 2.6875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.6875rem;
  flex: 0 0 2.6875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.46);
  border-radius: 0.75rem;
}

.header-mobile__prev > * {
  width: 25%;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.mobile-menu {
  position: fixed;
  z-index: 102;
  width: 87.6168224299%;
  top: 3.6875rem;
  right: 0;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
  transition: transform 0.35s ease 0s;
  height: calc(100% - 3.375rem);
  overflow: auto;
  border-radius: 2.875rem 0 0 0;
  background-color: #fff;
}

.mobile-menu {
  -webkit-transition: -webkit-transform 0.5s ease 0s;
  transition: -webkit-transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}

.menu-open .mobile-menu {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu__body {
  min-height: calc(100vh - 3.375rem);
  -webkit-box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.1607843137);
  padding: 1.25rem 1rem 4.375rem 1.25rem;
  border-radius: 2.875rem 0 0 0;
  position: relative;
}

.mobile-menu__logo {
  width: 100%;
  max-width: 8.75rem;
  margin: 0 auto;
}

.mobile-menu__logo:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.mobile-menu__logo > * {
  width: 100%;
  height: auto;
}

.mobile-menu__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  padding: 0 7.5% 0 5%;
}

.mobile-menu__user-icon {
  width: 1.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.625rem;
  flex: 0 0 1.625rem;
}

.mobile-menu__user-icon > * {
  width: 100%;
  height: auto;
}

.mobile-menu__user-name {
  font-size: 1.125rem;
  line-height: 1.2777777778;
  font-weight: 600;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.mobile-menu__user-name span {
  color: var(--green);
}

.mobile-menu__user-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-menu__user-actions > * {
  display: block;
  width: 1rem;
}

.mobile-menu__user-actions > * > * {
  width: 100%;
  height: auto;
}

.mobile-menu__login-btn-container:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.mobile-menu__login-btn {
  width: 100%;
}

.mobile-menu__spollers-item[open] > .mobile-menu__spollers-title {
  background-color: var(--green);
  color: #fff;
}

.mobile-menu__spollers-item[open] > .mobile-menu__spollers-title::after {
  -webkit-filter: invert(1);
  filter: invert(1);
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.mobile-menu__spollers-title {
  list-style: none;
  font-size: 1.25rem;
  line-height: 1.2;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 700;
  position: relative;
  display: block;
  width: 100%;
}

.mobile-menu__spollers-title::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 1rem;
  top: 50%;
  left: 1rem;
  background: url("../img/svg/arrow-left-black.svg") center/contain no-repeat;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mobile-menu__spollers-body {
  padding: 0.5rem 1.5rem 0.5rem 0;
}

.mobile-menu__socials {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  background-color: #60b046;
  padding: 0.5rem;
}

.mobile-menu__socials-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1.5625rem;
}

.mobile-menu__socials-item a {
  display: block;
  width: 1.6875rem;
  height: 1.6875rem;
}

.mobile-menu__socials-item a > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

/* ------------------------ */

/* ----- Footer styles ----- */

.footer {
  background: url("../img/common/footer-bg.png") center/cover no-repeat;
  padding-bottom: 1.875rem;
}

.footer__body {
  padding-top: 6.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.875rem;
}

.footer__body:not(:last-child) {
  margin-bottom: 3.75rem;
}

.footer__col:nth-child(2) .footer__links-item:not(:last-child) {
  margin-bottom: 0.4375rem;
}

.footer__col_big {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20rem;
  flex: 0 0 20rem;
}

.footer__col_socials {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 14.375rem;
  flex: 0 1 14.375rem;
}

.footer__title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  text-align: right;
  color: var(--text-green);
}

.footer__title:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.footer__link {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4375;
}

.footer__col-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__col-item_100 {
  margin-top: 31.25%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

.footer__payments-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.footer__payments-list-item > * {
  width: 2.625rem;
  height: 2.625rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer__icon-links-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer__icon-links-block:not(:last-child) {
  margin-bottom: 2.8125rem;
}

.footer__link-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  text-align: right;
  color: #000;
}

.footer__link-icon:not(:last-child) {
  margin-bottom: 0.5625rem;
}

.footer__link-icon .icon {
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.375rem;
  flex: 0 0 1.375rem;
}

.footer__link-icon .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer__socials-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.3125rem;
}

.footer__socials-link {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--orange);
}

.footer__socials-item:nth-child(2) .footer__socials-link {
  background-color: #000;
}

.footer__socials-item:nth-child(3) .footer__socials-link {
  background-color: var(--main-pink);
}

.footer__socials-item:nth-child(4) .footer__socials-link {
  background-color: var(--text-green);
}

.footer__socials-link > * {
  width: 64.5833333333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 64.5833333333%;
  flex: 0 0 64.5833333333%;
  height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.9375rem;
}

.footer__copyright,
.footer__power-by {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875;
}

.footer__copyright span,
.footer__power-by span {
  color: var(--text-pink);
  font-weight: 700;
}

.top-footer {
  padding: 2.25rem 0;
  position: relative;
}

.top-footer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  z-index: 1;
}

.top-footer__container {
  position: relative;
  z-index: 2;
}

.top-footer__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.875rem;
}

.top-footer__titles {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.top-footer__title {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 1.3333333333;
  color: var(--text-secondary);
}

.top-footer__subtitle {
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--text-secondary);
}

.top-footer__form-block {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 40.3125rem;
  flex: 0 1 40.3125rem;
}

.top-footer__form {
  display: block;
  width: 100%;
}

.top-footer__input-wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.top-footer__input-wrapper input {
  display: block;
  width: 100%;
  border-radius: 0.625rem;
  background: #fff;
  height: 3.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  color: var(--text-primary);
  text-align: right;
  padding-right: 1.125rem;
  padding-left: 8.125rem;
}

.top-footer__input-wrapper input::-webkit-input-placeholder {
  color: #393838;
}

.top-footer__input-wrapper input::-moz-placeholder {
  color: #393838;
}

.top-footer__input-wrapper input:-ms-input-placeholder {
  color: #393838;
}

.top-footer__input-wrapper input::-ms-input-placeholder {
  color: #393838;
}

.top-footer__input-wrapper input::placeholder {
  color: #393838;
}

.top-footer__input-wrapper .btn {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  height: 100%;
  width: 7.25rem;
}

.top-footer__input-wrapper .btn::after {
  content: "";
  display: none;
}

/* ------------------------- */

.home-page .credentials-section__content {
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 5vh;
}

.home-page__logo {
  width: 100%;
  max-width: 20vh;
  margin: 0 auto;
  display: block;
}

.home-page__logo:not(:last-child) {
  margin-bottom: 2.5vh;
}

.home-page__logo > * {
  width: 100%;
  height: auto;
}

.home-page__links-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.6vh;
  margin: auto 0;
}

.home-page__links-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.3333% - 1.0666666vh);
  flex: 0 0 calc(33.3333% - 1.0666666vh);
}

.home-page__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 12vh;
  padding: 1.5vh;
  border-radius: 1.5vh;
  border: 0.3vh solid #fff;
  -webkit-box-shadow: 0 0.3vh 1vh rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 0.3vh 1vh rgba(0, 0, 0, 0.1607843137);
  background: -o-linear-gradient(313deg, #efefef 0%, #fdfdfd 33.3333%, #ffffff 66.6666%, #efefef 100%);
  background: linear-gradient(137deg, #efefef 0%, #fdfdfd 33.3333%, #ffffff 66.6666%, #efefef 100%);
}

.home-page__link .icon {
  display: block;
  width: 3.5vh;
  height: 4.3vh;
}

.home-page__link .icon:not(:last-child) {
  margin-bottom: 0.8vh;
}

.home-page__link .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.home-page__link .text {
  font-size: 1.8vh;
  text-align: center;
  font-weight: 600;
}

/* ---- Explaining steps (popup) styles ---- */

.steps-modal__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 2.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.47);
  min-height: 100vh;
  overflow: auto;
}

.steps-modal__content {
  min-width: 0;
  border-radius: 1.875rem;
  background-color: #fff;
  max-width: 49.0625rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.steps-modal__image-ibg {
  width: 100%;
  padding-bottom: 58.9743589744%;
}

.steps-modal__image-ibg img {
  border-radius: 1.875rem;
}

.steps-modal__text-content {
  padding-bottom: 2.1875rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.steps-modal__pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.5625rem;
}

.steps-modal__pagination .swiper-pagination-bullet {
  width: 0.625rem;
  height: 0.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.625rem;
  flex: 0 0 0.625rem;
  border-radius: 50%;
  background-color: #d6d6d6;
  -webkit-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  transition: background-color 0.1s;
}

.steps-modal__pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  cursor: pointer;
}

.steps-modal__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #d52957;
}

.steps-modal__skip-container {
  margin-top: 0.75rem;
  text-align: center;
}

.steps-modal__skip {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-dark-gray);
  line-height: 1.3;
  border-bottom: 0.0625rem solid var(--text-pink);
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  -o-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.steps-modal__skip.disabled {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.start-step-content {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  text-align: center;
  max-width: 28.125rem;
  margin: auto;
}

.start-step-content__title {
  font-size: 2.3125rem;
  line-height: 1.3243243243;
  font-weight: 700;
  color: var(--text-pink);
}
.start-step-content__title .black {
  color: #000;
}
.start-step-content__title .yellow {
  color: #F8AF1D;
}
.step-content__title .black {
  color: #000;
}
.step-content__title .yellow {
  color: #F8AF1D;
}

.start-step-content__title:not(:last-child) {
  margin-bottom: 0.8125rem;
}

.start-step-content__content {
  font-size: 1.6875rem;
  line-height: 1.3333333333;
  color: var(--text-primary);
  font-weight: 600;
}

.start-step-content__content img,
.start-step-content__content svg {
  vertical-align: middle;
  height: 1.04em;
  width: auto;
}

.start-step-content__content:not(:last-child) {
  margin-bottom: 0.875rem;
}

.start-step-content__subtitle {
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--text-green);
}

.start-step-content__subtitle:not(:last-child) {
  margin-bottom: 1.75rem;
}

.start-step-content__btn-container {
  max-width: 20.625rem;
  margin: auto;
}

.start-step-content__btn-container .btn {
  width: 100%;
}

.step-content {
  max-width: 20rem;
  margin: auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.swiper-slide:nth-child(5) .step-content {
  max-width: 22.5rem;
}

.step-content__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.0625rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.step-content__number-text {
  position: relative;
}

.step-content__number-text .number {
  font-family: "Anton";
  font-size: 11rem;
  line-height: 1.5056818182;
  color: #acacac;
  opacity: 0.29;
}

.step-content__number-text .text {
  font-size: 1.6875rem;
  line-height: 1.3333333333;
  color: #959595;
  font-weight: 700;
  background-color: #fff;
  position: absolute;
  letter-spacing: 0.47em;
  text-align: center;
  z-index: 2;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.step-content__title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  text-align: right;
  color: var(--text-pink);
}

.swiper-slide:nth-child(4) .step-content__title {
  font-size: 1.3125rem;
}

.step-content__btn-container .btn {
  width: 100%;
}

.final-step-content {
  max-width: 20.625rem;
  margin: auto;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.final-step-content__title {
  font-weight: 700;
  font-size: 1.875rem;
  color: var(--text-pink);
  line-height: 1.3333333333;
}

.final-step-content__title:not(:last-child) {
  margin-bottom: 0.1875rem;
}

.final-step-content__subtitle {
  font-weight: 600;
  font-size: 1.3125rem;
  color: var(--text-primary);
  line-height: 1.2857142857;
}

.final-step-content__subtitle:not(:last-child) {
  margin-bottom: 2.1875rem;
}

.final-step-content__image {
  max-width: 8.5rem;
  width: 100%;
  margin: 0 auto;
}

.steps-modal__start-step .final-step-content__image{
  margin-bottom: 0;
}

.final-step-content__image > * {
  width: 100%;
  height: auto;
}

.final-step-content__btn-container {
  margin: auto 0 0;
}

.final-step-content__btn-container .btn {
  width: 100%;
}

.steps-modal.yellow .start-step-content {
  max-width: 20.625rem;
}

.steps-modal.yellow .start-step-content__title {
  color: var(--text-primary);
  font-size: 1.6875rem;
}

.steps-modal.yellow .start-step-content__title span {
  color: var(--yellow);
}

.steps-modal.yellow .start-step-content__subtitle {
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--text-green);
}

.steps-modal.yellow .start-step-content__subtitle:not(:last-child) {
  margin-bottom: 0.875rem;
}

.steps-modal.yellow .final-step-content__image {
  max-width: 8.5rem;
  width: 100%;
}

.steps-modal.yellow .final-step-content__image:not(:last-child) {
  margin-bottom: 0.75rem;
}

.steps-modal.yellow .steps-modal__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--yellow);
}

.steps-modal.yellow .steps-modal__skip {
  border-bottom: 0.0625rem solid var(--green);
}

.steps-modal.yellow .step-content__title {
  color: var(--yellow);
}

/* ----------------------------- */

/* ---- Choose event type page styles ---- */

.main-choose-event {
  padding-bottom: 10.625rem;
}

.main-choose-event__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}

.main-choose-event__header:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-choose-event__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3.125rem;
  padding: 0.9375rem 0;
}

.main-choose-event__button {
  font-weight: 600;
  font-size: 1.25rem;
  padding: 0.3125rem 0;
  color: var(--text-gray);
  position: relative;
  -webkit-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  transition: color 0.2s ease 0s;
}

.main-choose-event__button::after {
  content: "";
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -0.9375rem;
  height: 0.125rem;
  background-color: var(--text-pink);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.main-choose-event__button.active {
  color: var(--text-primary);
}

.main-choose-event__button.active::after {
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.main-choose-event__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.75rem;
}

.main-choose-event__filter .text {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25;
  text-align: center;
  color: var(--text-primary);
  margin-left: 0.625rem;
}

.main-choose-event__filter .arrow {
  width: 0.9375rem;
}

.main-choose-event__filter .arrow > * {
  width: 100%;
  height: auto;
  display: block;
}

.main-choose-event__steps-numbers:not(:last-child) {
  margin-bottom: 1.125rem;
}

.main-choose-event__titles-block {
  text-align: center;
}

.main-choose-event__titles-block:not(:last-child) {
  margin-bottom: 2.5rem;
}

.main-choose-event__pretitle {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2272727273;
  color: var(--text-primary);
}

.main-choose-event__pretitle:not(:last-child) {
  margin-bottom: 0.375rem;
}

.main-choose-event__title {
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.2285714286;
  color: var(--text-pink);
}

.main-choose-event__title:not(:last-child) {
  margin-bottom: 0.375rem;
}

.main-choose-event__subtitle {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2272727273;
  color: var(--text-primary);
}

.main-choose-event__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 3.125rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-choose-event__list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33333% - 2.0833333125rem);
  flex: 0 0 calc(33.33333% - 2.0833333125rem);
}

.main-choose-event__list-item:nth-child(12n + 2) .single-event-type__icon {
  background-color: var(--blue);
}

.main-choose-event__list-item:nth-child(12n + 3) .single-event-type__icon {
  background-color: var(--purple);
}

.main-choose-event__list-item:nth-child(12n + 4) .single-event-type__icon {
  background-color: var(--red-orange);
}

.main-choose-event__list-item:nth-child(12n + 5) .single-event-type__icon {
  background-color: var(--yellow);
}

.main-choose-event__list-item:nth-child(12n + 6) .single-event-type__icon {
  background-color: var(--green);
}

.main-choose-event__list-item:nth-child(12n + 7) .single-event-type__icon {
  background-color: var(--orange);
}

.main-choose-event__list-item:nth-child(12n + 8) .single-event-type__icon {
  background-color: var(--light-blue);
}

.main-choose-event__list-item:nth-child(12n + 9) .single-event-type__icon {
  background-color: var(--light-pink);
}

.main-choose-event__list-item:nth-child(12n + 10) .single-event-type__icon {
  background-color: var(--dark-green);
}

.main-choose-event__list-item:nth-child(12n + 11) .single-event-type__icon {
  background-color: var(--dark-pink);
}

.main-choose-event__list-item:nth-child(12n) .single-event-type__icon {
  background-color: var(--dark-green);
}

.main-choose-event__my-events-item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-choose-event__history-item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-choose-event__books-item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.my-event-single__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  border-radius: 0.5rem;
  padding: 0.8125rem 0.9375rem 0.8125rem 3.125rem;
}

.my-event-single__image-ibg {
  width: 7.8125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.8125rem;
  flex: 0 0 7.8125rem;
  padding-bottom: 7.8125rem;
  margin-left: 2.1875rem;
}

.my-event-single__image-ibg img {
  border-radius: 0.75rem;
}

.my-event-single__text-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.my-event-single__title {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.0869565217;
  text-align: right;
  color: var(--text-primary);
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-left: 0.9375rem;
}

.my-event-single__date {
  padding-left: 1.25rem;
  margin-left: 4.375rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: right;
  color: var(--text-primary);
}

.my-event-single__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.25rem;
  flex: 0 0 19.1875rem;
  justify-content: flex-end;
}

.my-event-single__actions .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.5rem;
  height: 1.75rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
}

.my-event-single__actions .button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.my-event-single__actions .button-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.125rem;
  flex: 0 0 1.125rem;
  margin-right: 1.5625rem;
}

.my-event-single__actions .button-edit > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-history-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  border-radius: 0.5rem;
  padding: 0.8125rem 1rem 0.8125rem 1.75rem;
}

.single-history-item__image-ibg {
  width: 7.8125rem;
  padding-bottom: 7.8125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.8125rem;
  flex: 0 0 7.8125rem;
  margin-left: 2.1875rem;
}

.single-history-item__image-ibg img {
  border-radius: 0.75rem;
}

.single-history-item__text-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.25rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.single-history-item__title {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.0869565217;
  text-align: right;
  color: var(--text-primary);
}

.single-history-item__date {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: right;
  color: var(--text-primary);
}

.single-history-item__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.625rem;
}

.single-history-item__actions .button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-history-item__actions .button.delete {
  width: 1.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  height: 1.75rem;
}

.single-history-item__actions .button.gift {
  width: 1.75rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.75rem;
  flex: 0 0 1.75rem;
  height: 1.9375rem;
}

.single-books-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  border-radius: 0.5rem;
  padding: 0.8125rem 1rem 0.8125rem 1.75rem;
  position: relative;
  -webkit-transition: z-index 0.3s ease 0s, border-radius 0s ease 0.15s;
  -o-transition: z-index 0.3s ease 0s, border-radius 0s ease 0.15s;
  transition: z-index 0.3s ease 0s, border-radius 0s ease 0.15s;
}

.single-books-item__content:hover {
  border-radius: 0.5rem 0.5rem 0 0;
  -webkit-transition: z-index 0s ease 0s, border-radius 0s ease 0s;
  -o-transition: z-index 0s ease 0s, border-radius 0s ease 0s;
  transition: z-index 0s ease 0s, border-radius 0s ease 0s;
  z-index: 10;
}

.single-books-item__content:hover .single-books-item__dropdown-body {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
  transition: opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
}

.single-books-item__content:hover .single-books-item__actions .arrow {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.single-books-item__image-ibg {
  width: 7.8125rem;
  padding-bottom: 7.8125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.8125rem;
  flex: 0 0 7.8125rem;
  margin-left: 2.1875rem;
}

.single-books-item__image-ibg img {
  border-radius: 0.75rem;
}

.single-books-item__text-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.25rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.single-books-item__title {
  font-weight: 600;
  font-size: 1.4375rem;
  line-height: 1.0869565217;
  text-align: right;
  color: var(--text-primary);
  flex: 1 1 auto;
}

.single-books-item__date {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: right;
  padding-left: 1.25rem;
  margin-left: 4.375rem;
  color: var(--text-primary);
}

.single-books-item__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.625rem;
  flex: 0 0 19.1875rem;
  justify-content: flex-end;
}

.single-books-item__actions .button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-books-item__actions .button {
  width: 1.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.5rem;
  flex: 0 0 1.5rem;
  height: 1.75rem;
}

.single-books-item__actions .button.gift {
  width: 1.75rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.75rem;
  flex: 0 0 1.75rem;
  height: 1.9375rem;
}

.single-books-item__actions .arrow {
  width: 0.5625rem;
  height: 1.125rem;
  margin-right: 1.25rem;
  margin-bottom: -0.3125rem;
  display: block;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.single-books-item__actions .arrow > * {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-books-item__dropdown-body {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 0.75rem 1.25rem 0.9375rem 2.1875rem;
  border-radius: 0 0 0.5rem 0.5rem;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0.1s, -webkit-transform 0.3s ease 0s;
  transition: opacity 0.15s ease 0.1s, -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, opacity 0.15s ease 0.1s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0.1s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0.1s, -webkit-transform 0.3s ease 0s;
}

.single-books-item__dropdown-table {
  border-radius: 0.9375rem;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.3125rem 2.5rem;
  gap: 1.875rem;
  row-gap: 0.75rem;
}

.single-books-item__dropdown-table:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.single-books-item__dropdown-table-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 20rem;
  flex: 0 1 20rem;
}

.single-books-item__dropdown-table-column:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 26.5625rem;
  flex: 0 1 26.5625rem;
}

.single-books-item__dropdown-table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.625rem;
}

.single-books-item__dropdown-table-item:not(:last-child) {
  margin-bottom: 0.75rem;
}

.single-books-item__dropdown-table-item .name {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.3;
  text-align: right;
  color: var(--text-primary);
}

.single-books-item__dropdown-table-item .value {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.3;
  text-align: right;
  color: var(--text-green);
}

.single-books-item__dropdown-bottom-text {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1;
  text-align: center;
  color: var(--text-primary);
  padding: 0 0.625rem;
}

.events-history .topbar__title {
  padding: 1.25rem 5rem;
}

.events-history .topbar__title .icon {
  width: 4.375rem;
}

.events-history .main-choose-event__button.active::after {
  background-color: #f8981d;
}

/* -------------------------- */

/* ----- Gift basket page styles ----- */

.gift-basket__topbar .topbar__title {
  padding: 0.625rem 8.125rem;
}

.gift-basket__topbar .topbar__title .icon {
  height: 130%;
  width: 7.875rem;
  top: -15%;
}

.gift-basket__bottom-menu {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  border-radius: 1rem 1rem 0 0;
  display: none;
}

.gift-basket__bottom-menu-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gift-basket__bottom-menu-btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.25rem;
}

.gift-basket__bottom-menu-btn .icon {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  right: 0.75rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gift-basket__bottom-menu-btn .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.gift-basket__bottom-menu-btn::after {
  content: "";
}

.gift-basket__bottom-menu-btn.btn-white {
  border-radius: 0 1rem 0 0;
}

.gift-basket__bottom-menu-btn.btn {
  border-radius: 1rem 0 0 0;
}

.main-gift-basket {
  padding-bottom: 12.5rem;
}

.main-gift-basket__navigation {
  padding: 1.125rem 0;
}

.main-gift-basket__navigation:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.main-gift-basket__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2.5rem;
}

.main-gift-basket__nav-item button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.375rem;
  position: relative;
  padding: 0.3125rem 0.1875rem;
}

.main-gift-basket__nav-item button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.125rem;
  bottom: -0.625rem;
  right: 0;
  transform: scale(0, 1);
  transform-origin: left;
  transition: transform 0.3s ease 0s;
}

.main-gift-basket__nav-item button .icon {
  width: 1.125rem;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-gift-basket__nav-item button .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-gift-basket__nav-item button .icon > * * {
  stroke: var(--icon-light-gray);
  fill: transparent;
  -webkit-transition: fill 0.3s ease 0s, stroke 0.3s ease 0s;
  -o-transition: fill 0.3s ease 0s, stroke 0.3s ease 0s;
  transition: fill 0.3s ease 0s, stroke 0.3s ease 0s;
}

.main-gift-basket__nav-item button .text {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-gray);
  line-height: 1.3;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.main-gift-basket__nav-item.active button .text {
  color: var(--text-primary);
}

.main-gift-basket__nav-item.active button::after {
  transform: scale(1, 1);
  transform-origin: right;
}

.main-gift-basket__nav-item.red button::after {
  background-color: var(--main-pink);
}

.main-gift-basket__nav-item.red.active button .icon svg * {
  fill: var(--icon-pink);
  stroke: var(--icon-pink);
}

.main-gift-basket__nav-item.green button::after {
  background-color: var(--green);
}

.main-gift-basket__nav-item.green.active button .icon svg * {
  fill: var(--green);
  stroke: var(--green);
}

.main-gift-basket__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-gift-basket__header:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-gift-basket__header-product-name {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  color: var(--text-primary);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(27.4418604651% + 7.25rem);
  flex: 0 0 calc(27.4418604651% + 7.25rem);
  padding-right: 1.875rem;
  padding-left: 1.25rem;
}

.main-gift-basket__header-product-price {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  color: var(--text-primary);
  padding-left: 1.25rem;
}
.main-gift-basket__header-product-status {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  color: var(--text-primary);
  padding-left: 1.25rem;
  margin-right: 19vw;
  font-size: 0.9375vw;
}

.main-gift-basket__header-product-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.75rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.header-filter {
}
.header-filter__drop {
  position: relative;
}
.header-filter__body {
  position: absolute;
  z-index: 10;
  min-width: 100%;
  width: 80px;
  top: 100%;
  height: auto;
  right: 50%;
  transform: translateX(50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.header-filter__item button {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25;
  text-align: right;
  padding: 5px;
  background-color: #fff;
  transition: background-color 0.2s ease 0s;
}
.header-filter__item button:hover {
  background-color: #f7f7f7;
}

.main-gift-basket__header-product-filter .text {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25;
  text-align: center;
  color: var(--text-primary);
  margin-left: 0.625rem;
}

.main-gift-basket__header-product-filter .arrow {
  width: 0.9375rem;
}

.main-gift-basket__header-product-filter .arrow > * {
  width: 100%;
  height: auto;
}

.main-gift-basket__my-purchases-item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.my-purchase-single__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  border-radius: 0.5rem;
  padding: 0.8125rem 0.9375rem 0.8125rem 3.125rem;
}

.my-purchase-single__image-ibg {
  width: 7.25rem;
  height: 7.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.25rem;
  flex: 0 0 7.25rem;
  border-radius: 0.75rem;
}

.my-purchase-single__text-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my-purchase-single__name {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2916666667;
  text-align: right;
  color: var(--text-pink);
  padding-right: 2.1875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30.7612095933%;
  flex: 0 0 30.7612095933%;
  padding-left: 1.25rem;
}

.my-purchase-single__price {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.0833333333;
  text-align: right;
  color: var(--text-primary);
  padding-left: 1.25rem;
}

.my-purchase-single__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2.5rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 1.25rem;
}

.my-purchase-single__actions .button {
  width: 2rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2rem;
  flex: 0 0 2rem;
  height: 2.3125rem;
}

.my-purchase-single__actions .button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.my-purchase-single__actions .button.favorites {
  width: 2.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.5rem;
  flex: 0 0 2.5rem;
  height: 2.5625rem;
  margin-bottom: -0.5rem;
}

.my-purchase-single__actions .status {
  margin-right: 0.625rem;
  width: 3.4375rem;
  height: 3.4375rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 3.4375rem;
  flex: 0 0 3.4375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.my-purchase-single__actions .status p {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;
  color: #fff;
}

.my-purchase-single__actions .status.red {
  background-color: var(--text-pink);
}

.my-purchase-single__actions .status.green {
  background-color: #60b046;
}

.my-purchase-single__actions .status.gray {
  background-color: var(--gray);
}

/* ------------------------------------ */

.blessing-created {
  min-height: calc(100 * var(--vh));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-blessing-created {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
}

.main-blessing-created__bg-image-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.main-blessing-created__content-window {
  position: absolute;
  width: 100%;
  max-width: 55vh;
  height: 88%;
  top: 6%;
  right: 9.375%;
  z-index: 3;
  background: url("../img/blessing/content-bg.png") right bottom/cover no-repeat;
  padding: 6vh 3vh 4vh;
  border-radius: 0.9375rem;
}

.main-blessing-created__title {
  font-weight: bold;
  font-size: 5vh;
  line-height: 1.08;
  text-align: center;
  color: var(--text-secondary);
}

.main-blessing-created__title:not(:last-child) {
  margin-bottom: 0.875rem;
}

.main-blessing-created__subtitle {
  font-weight: 600;
  font-size: 2.7vh;
  line-height: 1.037037037;
  text-align: center;
  color: var(--text-secondary);
}

.main-blessing-created__subtitle:not(:last-child) {
  margin-bottom: 4.5vh;
}

.main-blessing-created__btn-container:not(:last-child) {
  margin-bottom: 8vh;
}

.main-blessing-created__btn-container .btn-white {
  width: 68.085106383%;
  font-size: 2.2vh;
}

.main-blessing-created__btn-container .btn-white::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 6.25%;
  width: 7.1875%;
  height: 57.1428571429%;
  background: url("../img/svg/calendar-green.svg") center/contain no-repeat;
  z-index: 2;
}

.main-blessing-created__btn-container .btn-white::after {
  left: 6.25%;
  width: 3.125%;
  height: 47.619047619%;
}

.main-blessing-created__bottom-info {
  padding-right: 22%;
}

.main-blessing-created__checkbox .checkbox__text {
  font-size: 2vh;
}

.main-blessing-created__checkbox-subtext {
  font-size: 1rem;
  line-height: 1.3125;
  color: #fff;
  padding-right: 1.8125rem;
  font-size: 1.75vh;
}

.event-created {
  min-height: calc(100 * var(--vh));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-event-created {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
}

.main-event-created__bg-image-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.main-event-created__content-window {
  position: absolute;
  width: 100%;
  max-width: 55vh;
  height: 88%;
  top: 6%;
  right: 9.375%;
  z-index: 3;
  background: url("../img/blessing/content-bg.png") right bottom/cover no-repeat;
  padding: 6vh 3vh 4vh;
  border-radius: 0.9375rem;
}

.main-event-created__title {
  font-weight: bold;
  line-height: 1.1851851852;
  text-align: center;
  color: #fff;
  font-size: 2.7vh;
  color: var(--text-secondary);
  max-width: 40vh;
  margin: auto;
}

.main-event-created__title:not(:last-child) {
  margin-bottom: 2.5vh;
}

.main-event-created__logo {
  width: 23.2vh;
  margin: auto;
}

.main-event-created__logo > * {
  width: 100%;
  height: auto;
}

.main-event-created__logo:not(:last-child) {
  margin-bottom: 2.8vh;
}

.main-event-created .btn-container_center:not(:last-child) {
  margin-bottom: 2vh;
}

.main-event-created .btn-container_center .btn {
  width: 68.085106383%;
  font-size: 2.2vh;
}

.main-event-created .btn-container_center .btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 6.25%;
  width: 9.6875%;
  height: 76.1904761905%;
  background: url("../img/svg/party-icon-small.svg") center/contain no-repeat;
  z-index: 2;
}

.main-event-created .btn-container_center .btn::after {
  left: 6.25%;
  width: 3.125%;
  height: 47.619047619%;
}

.main-event-created .btn-container_center .btn-white {
  width: 68.085106383%;
  font-size: 2.2vh;
}

.main-event-created .btn-container_center .btn-white::after {
  left: 6.25%;
  width: 3.125%;
  height: 47.619047619%;
}

.credentials-section {
  position: relative;
  min-height: calc(100 * var(--vh) - 9.75rem);
  padding: 1.875rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.credentials-section__bg-image-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.credentials-section__content-window {
  position: relative;
  width: 100%;
  max-width: 55vh;
  min-height: calc(90 * var(--vh) - 9.75rem);
  top: 5%;
  left: 16.6666666667%;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 2.8vh;
}

.credentials-section__steps-numbers {
  background-color: #fff;
  padding: 1.7vh 2.6vh;
  border-radius: 0.9375rem;
  -webkit-box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.16);
}

.credentials-section__steps-numbers .numbers-steps__list {
  max-width: 100%;
}

.credentials-section__steps-numbers .numbers-steps__step {
  width: 4.2vh;
  height: 4.2vh;
  font-size: 2.5vh;
  line-height: 1.25;
}

.credentials-section__content {
  background: #fff;
  padding: 3vh 4vh 3vh;
  border-radius: 0.9375rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.credentials-section__prev-btn {
  position: absolute;
  top: 3vh;
  left: 1.7vh;
  width: 3.4vh;
  height: 3.4vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 5;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e9e9e9;
  border-radius: 0.5rem;
}

.credentials-section__prev-btn > * {
  width: 30%;
  height: auto;
}

.credentials-section__title {
  font-weight: bold;
  font-size: 3vh;
  line-height: 1.3333333333;
  text-align: center;
  color: var(--text-pink);
}

.credentials-section__title:not(:last-child) {
  margin-bottom: 0.6vh;
}

.credentials-section__title .mobile-icon {
  display: none;
}

.credentials-section__subtitle {
  font-weight: 600;
  font-size: 2vh;
  line-height: 1.5;
  text-align: center;
  color: var(--text-primary);
}
.credentials-section__subtitle .fat{
  font-weight: 700;
}

.credentials-section__subtitle:not(:last-child) {
  margin-bottom: 1.1vh;
}

.credentials-section__small-subtitle {
  font-weight: bold;
  font-size: 2vh;
  text-align: center;
  line-height: 1.3;
}

.credentials-section__small-subtitle:not(:last-child) {
  margin-bottom: 0.625rem;
}

.credentials-section__text {
  font-size: 1.8vh;
  text-align: center;
  line-height: 1.2777777778;
}

.credentials-section__text:not(:last-child) {
  margin-bottom: 2vh;
}

.credentials-section__text.text-link:not(:last-child) {
  margin-bottom: 6vh;
}

.credentials-section__text a {
  color: var(--text-green);
  text-decoration: underline;
}

.credentials-section__facebook-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5vh;
  font-size: 1.8vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.2777777778;
}

.credentials-section__facebook-link:not(:last-child) {
  margin-bottom: 2vh;
}

.credentials-section__facebook-link > svg,
.credentials-section__facebook-link img {
  width: 2.4vh;
  height: 1.4vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.4vh;
  flex: 0 0 2.4vh;
  -o-object-fit: contain;
  object-fit: contain;
}

.credentials-section__large-image-ibg {
  width: 100%;
  padding-bottom: 38.7596899225%;
}

.credentials-section__large-image-ibg img {
  border-radius: 1.4375rem;
}

.credentials-section__large-image-ibg:not(:last-child) {
  margin-bottom: 2.3vh;
}

.credentials-section__table-block:not(:last-child) {
  margin-bottom: 1.1vh;
}

.credentials-section__table {
  border-radius: 1.25rem;
  background: #f5f5f5;
  padding: 1.2vh 2.3vh;
}

.credentials-section__table:not(:last-child) {
  margin-bottom: 1.1vh;
}

.credentials-section__table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 2vh;
}

.credentials-section__table-item:not(:last-child) {
  margin-bottom: 0.3vh;
}

.credentials-section__table-item-name {
  font-weight: 600;
  font-size: 2vh;
  color: var(--text-primary);
  line-height: 1.3;
}

.credentials-section__table-item-value {
  font-weight: 700;
  font-size: 2vh;
  color: var(--text-green);
  line-height: 1.3;
}

.credentials-section__under-table-text {
  font-weight: 600;
  font-size: 2vh;
  line-height: 1;
  text-align: center;
  color: var(--text-primary);
}

.credentials-section__bottom-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 3;
  gap: 2.6vh;
  padding: 0.1875rem 0;
}

.credentials-section__bottom-buttons .btn,
.credentials-section__bottom-buttons .btn-outline-white {
  width: 15.8vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15.8vh;
  flex: 0 0 15.8vh;
  font-size: 2.2vh;
  padding: 0.7vh 1.5vh;
}

.credentials-section__bottom-buttons .btn::after,
.credentials-section__bottom-buttons .btn-outline-white::after {
  left: 6.25%;
  width: 3.125%;
  height: 47.619047619%;
}

.credentials-section__bottom-buttons:not(:last-child) {
  margin-bottom: 1.1vh;
}

.credentials-section__progress {
  position: relative;
  padding-top: 4vh;
  z-index: 3;
}

.credentials-section__progress .percent {
  position: absolute;
  top: 0;
  -webkit-transform: translateX(53%);
  -ms-transform: translateX(53%);
  transform: translateX(53%);
  width: 3.9vh;
  height: 3.9vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0.4vh;
  background: url("../img/credentials/percent-bg.svg") center/contain no-repeat;
  font-weight: bold;
  font-size: 1.6vh;
  line-height: 1.3125;
  text-align: center;
  color: var(--text-primary);
}

.credentials-section__progress .bar {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.3125rem;
}

.credentials-section__progress .bar .background {
  width: 100%;
  background-color: #e2e2e2;
  height: 1vh;
}

.credentials-section__progress .bar .percent-line {
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  height: 220%;
  border-radius: 0.6875rem;
  background-color: var(--text-green);
}

.credentials-section__footer-mobile {
  display: none;
}

.credentials-section__bottom-buttons-mobile {
  text-align: center;
}

.credentials-section__bottom-buttons-mobile .btn-red {
  max-width: 20rem;
  width: 100%;
  border-bottom: 0.125rem solid var(--text-green);
  -webkit-transition: border-color 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  transition: border-color 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  -o-transition: border-color 0.3s ease 0s, transform 0.3s ease 0s;
  transition: border-color 0.3s ease 0s, transform 0.3s ease 0s;
  transition: border-color 0.3s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.credentials-section__bottom-buttons-mobile .btn-red:active {
  border-color: transparent;
  -webkit-transform: translateY(0.125rem);
  -ms-transform: translateY(0.125rem);
  transform: translateY(0.125rem);
}

.credentials-section__form-wrapper {
  overflow: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.register .credentials-section__form-wrapper {
  overflow: unset;
}

.credentials-section__form-wrapper:not(:last-child) {
  margin-bottom: 2vh !important;
}

.credentials-section .form-title {
  font-size: 1.8vh;
}

.credentials-section .form-title:not(:last-child) {
  margin-bottom: 0.8vh;
}

.credentials-section .input-title {
  right: 1.2vh;
  font-size: 1.8vh;
  padding: 0.1vh 0.7vh 0.4vh 0.9vh;
  top: 1vh;
}

.credentials-section .input {
  padding: 0.8vh 1.5vh;
  font-size: 2vh;
  border-radius: 0.8vh !important;
}

.credentials-section .input-wrapper {
  padding-top: 1vh;
}

.credentials-section .input-wrapper:not(:last-child) {
  margin-bottom: 1.4vh;
}

.credentials-section .birthday-field {
  margin-top: 0;
}

.credentials-section .birthday-field__birthday-body .birthday-field__input-wrapper:not(:last-child)::after {
  font-size: 2.5vh;
  left: -2vh;
}

.credentials-section .mail-wrapper:not(:last-child) {
  margin-bottom: 1vh;
}

.credentials-section .tel-wrapper::after {
  margin-left: 1.6vh;
  margin-right: 1.6vh;
  font-size: 3vh;
}

.credentials-section .tel-wrapper:not(:last-child) {
  margin-bottom: 2vh;
}

.credentials-section .event-info:not(:last-child) {
  margin-bottom: 3.5vh;
}

.credentials-section .event-info__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.credentials-section .event-info__body .input-wrapper:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26.273458445%;
  flex: 0 0 26.273458445%;
  width: 26.273458445%;
}

.credentials-section .event-info__body .input-wrapper:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21.1796246649%;
  flex: 0 0 21.1796246649%;
  width: 21.1796246649%;
}

.credentials-section .event-info__body .input-wrapper:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 47.9892761394%;
  flex: 0 0 47.9892761394%;
  width: 47.9892761394%;
}

.credentials-section .checkbox .checkbox__label {
  gap: 0.7vh;
}

.credentials-section .checkbox .checkbox__label::before {
  width: 2.2vh;
  height: 2.2vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.2vh;
  flex: 0 0 2.2vh;
  border-radius: 0.7vh;
}

.credentials-section .checkbox .checkbox__label::after {
  width: 1.2vh;
  height: 1vh;
  top: 0.6vh;
  right: 0.55vh;
}

.credentials-section .checkbox .checkbox__text {
  color: var(--text-primary) !important;
  font-size: 1.6vh;
  font-weight: 400;
}
.credentials-section .checkbox .checkbox__text--btn {
  color: var(--text-secondary) !important;
  margin-left: 1.5rem;
}

.credentials-section .checks-title {
  text-align: center;
  margin-top: 4vh;
}

.credentials-section .card-info:not(:last-child) {
  margin-bottom: 1.2vh;
}

.credentials-section .card-info__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.credentials-section .card-info__body .input-wrapper:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31.4814814815%;
  flex: 0 0 31.4814814815%;
}

.credentials-section .card-info__body .input-wrapper:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 29.6296296296%;
  flex: 0 0 29.6296296296%;
}

.credentials-section .card-info__body .input-wrapper:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25.1322751323%;
  flex: 0 0 25.1322751323%;
}

.credentials-section .card-info__body .input-title {
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.credentials-section .choose-payment:not(:last-child) {
  margin-bottom: 0.6vh;
}

.credentials-section .long-checkbox {
  position: relative;
}

.credentials-section .long-checkbox:not(:last-child) {
  margin-bottom: 1vh;
}

.credentials-section .long-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.credentials-section .long-checkbox__input:checked + .long-checkbox__label::after {
  background-color: var(--main-pink);
}

.credentials-section .long-checkbox__label {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.75vh 1.5vh 0.75vh 1.5vh;
  position: relative;
  gap: 1vh;
  border-radius: 1vh;
  background: #fff;
  border: 0.0625rem solid #d9d6d6;
  transition: border-color 0.2s ease 0s;
}

.credentials-section .long-checkbox__label::after {
  content: "";
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.2vh;
  flex: 0 0 2.2vh;
  width: 2.2vh;
  height: 2.2vh;
  border: 0.0625rem solid #d9d6d6;
  border-radius: 0.7vh;
  transition: border-color 0.2s ease 0s;
}
.credentials-section .long-checkbox__label.input-error {
  border-color: var(--red-orange);
}
.credentials-section .long-checkbox__label.input-error::after {
  border-color: var(--red-orange);
}

.credentials-section .long-checkbox__text {
  font-size: 1.8vh;
  line-height: 1.2777777778;
  font-weight: 600;
}

.credentials-section .long-checkbox__text > img,
.credentials-section .long-checkbox__text > svg {
  width: 11.4vh;
  height: 3.6vh;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: right center;
  object-position: right center;
}

.credentials-section .long-checkbox__text a {
  color: #f8af1d;
  text-decoration: underline;
}

.credentials-section .long-checkbox__radios {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.75vh 1.5vh 0.75vh 1.5vh;
  border-radius: 1vh;
  background: #fff;
  border: 0.0625rem solid #d9d6d6;
}

.credentials-section .long-checkbox__radios > p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13.4vh;
  flex: 0 0 13.4vh;
  font-size: 1.8vh;
  line-height: 1.2777777778;
  font-weight: 600;
}

.credentials-section .long-checkbox__radios .long-checkbox__label {
  border: 0;
  padding: 0;
  gap: 0.5vh;
}

.credentials-section .long-checkbox_with-content {
  padding: 0.75vh 1.5vh 0.75vh 1.5vh;
  border-radius: 1vh;
  background: #fff;
  border: 0.0625rem solid #d9d6d6;
}

.credentials-section .long-checkbox_with-content .long-checkbox__label {
  border: 0;
  padding: 0;
}

.credentials-section .long-checkbox_with-content .long-checkbox__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2vh;
  gap: 1.3vh;
}

.credentials-section .long-checkbox_with-content .long-checkbox__buttons > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  width: 45%;
  font-size: 1.8vh;
  padding: 0.25vh 0.5vh 0.25vh 2vh;
  border-radius: 0.5vh;
}

.credentials-section .long-checkbox_with-content .long-checkbox__buttons > *::after {
  content: "";
  left: 0.8vh;
  width: 1vh;
  height: 1.5vh;
}

.credentials-section .long-checkbox_with-content .long-checkbox__buttons .btn-gray {
  opacity: 0.75;
  pointer-events: none;
}

.credentials-section .long-checkbox_with-image .long-checkbox__label {
  padding-right: 0rem;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .image {
  display: block;
  margin-top: -0.75vh;
  margin-bottom: -0.75vh;
  padding-bottom: 26.525198939%;
  width: 30.2387267905%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30.2387267905%;
  flex: 0 0 30.2387267905%;
  height: 0;
  position: relative;
  margin-left: 3vh;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .image > *:not(.loupe-btn) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.5vh;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .image .loupe-btn {
  position: absolute;
  left: -0.55vh;
  bottom: 0.45vh;
  width: 3vh;
  height: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.8vh;
  background: #f3f1f1;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .image .loupe-btn > * {
  width: 53.3333333333%;
  height: 53.3333333333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 53.3333333333%;
  flex: 0 0 53.3333333333%;
  -o-object-fit: contain;
  object-fit: contain;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .text-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .text {
  font-size: 2.1vh;
  font-weight: 600;
  line-height: 1.0416666667;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .text:not(:last-child) {
  margin-bottom: 0.5vh;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .price {
  font-size: 3.6vh;
  font-weight: 600;
  color: var(--text-green) !important;
  line-height: 1.3333333333;
}

.credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .price .money-icon {
  font-size: 0.6111111111em;
}

.credentials-section__decor {
  position: absolute;
  left: 0;
  bottom: 15%;
  width: 45%;
  height: 33.3333%;
}

.credentials-section__decor > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.credentials-section__text-scroll {
  height: 40vh;
  overflow: auto;
  font-weight: normal;
  font-size: 1.8vh;
  text-align: right;
  padding-left: 2vh;
}

.credentials-section__text-scroll:not(:last-child) {
  margin-bottom: 1.6vh;
}

.credentials-section__text-scroll::-webkit-scrollbar {
  width: 0.8vh;
}

.credentials-section__text-scroll::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--main-pink);
  border-radius: 0.28125rem;
}

.blessing-form .credentials-section__content {
  height: 40vh;
  position: relative;
  padding: 3vh 6vh 2.3vh;
}

.blessing-form .credentials-section__form-wrapper {
  margin: 0 auto;
  padding: 0 3.5vh 2vh;
  z-index: 2;
}

.blessing-form .credentials-section__form-wrapper form {
  position: relative;
}

.blessing-form .credentials-section__form-wrapper::-webkit-scrollbar {
  width: 0.8vh;
}

.blessing-form .credentials-section__form-wrapper::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--main-pink);
  border-radius: 0.28125rem;
}

.blessing-form .credentials-section__bottom-buttons .btn {
  width: 32vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32vh;
  flex: 0 0 32vh;
}

.blessing-form .credentials-section__subtitle {
  color: var(--text-primary);
}

.blessing-form .credentials-section__subtitle:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.credentials-page_form .credentials-section__content {
  padding-left: 10vh;
  padding-right: 10vh;
}

.credentials-page_form .credentials-section__progress {
  margin-left: -6vh;
  margin-right: -6vh;
}

.credentials-page_orange .credentials-section__title {
  color: var(--text-green);
}

.credentials-page_orange .long-checkbox__input:checked + .long-checkbox__label::after {
  background-color: #f8af1d;
}

.credentials-page_orange .btn:active {
  background-color: #f8af1d;
}

.credentials-page_orange .credentials-section__top-nav-btn {
  font-weight: bold;
  font-size: 2.2vh;
  line-height: 1.1363636364;
  text-align: center;
  color: var(--text-gray);
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 46.0504201681%;
  flex: 0 0 46.0504201681%;
  width: 46.0504201681%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 1.2vh 1.2vh 0 0;
}

.credentials-page_orange .credentials-section__top-nav-btn.active {
  color: #f8af1d !important;
}

.credentials-page_orange .credentials-section__text-scroll::-webkit-scrollbar-thumb {
  background-color: #f8af1d;
}

.credentials-page_orange .credentials-section__form-wrapper::-webkit-scrollbar-thumb {
  background-color: #f8af1d;
}

.credentials-page_orange .credentials-section__content-block::-webkit-scrollbar-thumb {
  background-color: #f8af1d !important;
}

.form-credentials-section {
  position: relative;
  z-index: 3;
}

.form-credentials-section__select-wrapper {
  padding-top: 1vh;
  position: relative;
}

.form-credentials-section__select-wrapper:not(:last-child) {
  margin-bottom: 1.4vh;
}

.form-credentials-section__select-wrapper::after {
  content: "";
  position: absolute;
  top: calc(50% + 0.5vh);
  left: 1.6vh;
  width: 1.2vh;
  height: 0.8vh;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  background: url("../img/svg/arrow-bottom-black.svg") center/contain no-repeat;
}

.form-credentials-section__select-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-credentials-section__small-icon {
  width: 3vh;
  margin: 0 auto;
}

.form-credentials-section__small-icon:not(:last-child) {
  margin-bottom: 1.3vh;
}

.form-credentials-section__small-icon > * {
  width: 100%;
  height: auto;
}

.form-credentials-section__check {
  text-align: center;
}

.form-credentials-section__check:not(:last-child) {
  margin-bottom: 3vh;
}

.form-credentials-section__buttons-container {
  max-width: 32vh;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.form-credentials-section__buttons-container.resend .form-credentials-section__red-link {
  margin-top: 2vh;
}

.form-credentials-section__buttons-container [class*="btn"] {
  width: 100%;
  font-size: 2.2vh;
  padding: 0.7vh 1.5vh;
}

.form-credentials-section__buttons-container [class*="btn"]:not(:last-child) {
  margin-bottom: 1.6vh;
}

.form-credentials-section__buttons-container [class*="btn"]::after {
  content: "";
  left: 2vh;
  width: 1vh;
  height: 2vh;
}

.form-credentials-section__checks {
  margin: 0 auto;
  max-width: 26vh;
}

.form-credentials-section__checks:not(:last-child) {
  margin-bottom: 1.4vh;
}

.form-credentials-section__text-under-checks {
  font-weight: bold;
  font-size: 1.9vh;
  text-align: center;
}

.form-credentials-section__red-link {
  font-weight: 600;
  font-size: 2vh;
  line-height: 1.3;
  color: #d52957;
  display: inline-block;
}

.form-credentials-section .birthday-field__birthday-body .birthday-field__input-wrapper:not(:last-child)::after {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-credentials-section__code-wrapper {
  padding: 0 1.5vh;
}

.form-credentials-section__code-wrapper:not(:last-child) {
  margin-bottom: 3.8vh;
}

.form-credentials-section__code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-credentials-section__code-input-wrapper {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.4086687307%;
  flex: 0 0 16.4086687307%;
}

.form-credentials-section__code-input-wrapper .code-input {
  display: block;
  width: 100%;
  outline: none;
  font-size: 4vh;
  border-bottom: 0.0625rem solid #000;
  text-align: center;
  padding: 0.5vh;
  direction: ltr;
}

.form-credentials-section__how-much-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.85vh;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.form-credentials-section__how-much-wrapper.digits-parent {
  position: relative;
}

.form-credentials-section__how-much-wrapper .how-much-input {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 27.4vh;
  flex: 0 1 27.4vh;
  outline: none;
  width: 100%;
  max-width: 27.4vh;
  font-size: 2.4vh;
  font-weight: 600;
  padding: 0.5vh 0.8vh;
  border-bottom: 0.125rem solid #000;
}

.form-credentials-section__how-much-wrapper .money-icon {
  font-size: 3.3vh;
  font-weight: 600;
  line-height: 1;
}

.another-blessing-form .credentials-section__subtitle {
  font-weight: 700;
}

.another-blessing-form .form-credentials-section .remember {
  display: none;
}

.another-blessing-form .form-credentials-section__check .checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.another-blessing-form .form-credentials-section__check .checkbox__input:checked ~ .remember {
  display: block;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
  margin-bottom: 1.4vh;
}

.choose-application .form-credentials-section__checks {
  margin-top: 2vh;
}

.choose-application .form-credentials-section__checks .checkbox__label::after {
  display: none;
}

.choose-application .form-credentials-section__text-under-checks:not(:last-child) {
  margin-bottom: 3.3vh;
}

.choose-application .form-credentials-section__buttons-container {
  max-width: 37vh;
}

.choose-application .form-credentials-section__buttons-container [class*="btn"]::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2.7777777778%;
  width: 5.5555555556%;
  height: 47.619047619%;
  background: url("../img/svg/user-icon-white.svg") center/contain no-repeat;
  z-index: 2;
}

.choose-application .credentials-section__content {
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}

.payment-page .credentials-section__title {
  display: none;
}

.payment-page .credentials-section__subtitle {
  font-size: 2vh;
  color: var(--text-green);
  font-weight: 700;
}

.payment-page .form-credentials-section__text-under-checks {
  font-size: 1.6vh;
  font-weight: 600;
}

.payment-page .form-credentials-section .choose-payment__body:not(:last-child) {
  margin-bottom: 1.4vh;
}

.payment-page .choose-payment-checkbox__input:checked + .choose-payment-checkbox__label::after {
  background-color: var(--text-green);
}

.login-with .credentials-section__decor {
  bottom: 6%;
  left: -3%;
}

.login-with .form-credentials-section__buttons-container {
  max-width: 23vh;
}

.login-with_sms .form-credentials-section__red-link {
  margin-top: 7.5vh;
}

.login-with_sms .credentials-section__top-nav-btn.active {
  color: var(--green) !important;
}

.credentials-page_nav-buttons .credentials-section__content {
  margin-top: 5.6vh;
}

.credentials-page_nav-buttons .credentials-section__top-nav-buttons {
  position: absolute;
  z-index: 3;
  top: 0.1vh;
  right: 0;
  width: 100%;
  height: 5.6vh;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.credentials-page_nav-buttons .credentials-section__top-nav-btn {
  font-weight: bold;
  font-size: 2.2vh;
  line-height: 1.1363636364;
  text-align: center;
  color: var(--text-gray);
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 46.0504201681%;
  flex: 0 0 46.0504201681%;
  width: 46.0504201681%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 1.2vh 1.2vh 0 0;
}

.credentials-page_nav-buttons .credentials-section__top-nav-btn.active {
  color: var(--text-pink);
}

.register .credentials-section__top-nav-btn.active {
  color: var(--green) !important;
}

.register .form-credentials-section__check {
  text-align: right;
}

.register .form-credentials-section__check .checkbox__text {
  font-size: 1.8vh;
  font-weight: 600;
}

.register .form-credentials-section {
  padding-top: 1.25rem;
}

.register-user-details-form .form-credentials-section__check {
  text-align: right;
}

.register-user-details-form .form-credentials-section__check .checkbox__text {
  font-size: 1.8vh;
  font-weight: 600;
}

.register-user-details-form .form-credentials-section__check .checkbox__input:checked + .checkbox__label::before {
  background-color: var(--green);
}

.register-user-details-form .credentials-section__title:not(:last-child) {
  margin-bottom: 1.5vh;
}

.terms-form .credentials-section__title:not(:last-child) {
  margin-bottom: 1.8vh;
}

.how-much .form-credentials-section__how-much-wrapper {
  margin-top: 6.6vh;
}

.how-much .form-credentials-section__how-much-wrapper:not(:last-child) {
  margin-bottom: 6vh;
}

.how-much .form-credentials-section__buttons-container [class*="btn"]:not(:last-child) {
  margin-bottom: 2vh;
}

.shipping-type.credentials-page_form .credentials-section__content {
  padding-left: 9vh;
  padding-right: 9vh;
}

.shipping-type.credentials-page_form .credentials-section__progress {
  margin-left: -5vh;
  margin-right: -5vh;
}

.shipping-type .credentials-section {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.shipping-type .credentials-section__content-window {
  left: 15.625%;
  right: auto;
}

.shipping-type .form-credentials-section__buttons-container {
  margin-top: 2vh;
}

.shipping-type .long-checkbox_with-content {
  display: none;
}

.after-register .credentials-section__title {
  font-size: 2.7vh;
  color: var(--text-primary);
  margin-top: 4vh;
  line-height: 1.0857142857;
}

.after-register .credentials-section__title:not(:last-child) {
  margin-bottom: 4vh;
}

.after-register .credentials-section__image-icon {
  width: 100%;
  margin: 0 auto;
  max-width: 22.6vh;
}

.after-register .credentials-section__image-icon > * {
  width: 100%;
  height: auto;
}

.after-register .credentials-section__image-icon:not(:last-child) {
  margin-bottom: 2vh;
}

.after-register .credentials-section__logo {
  width: 100%;
  margin: 0 auto;
  max-width: 19.1vh;
}

.after-register .credentials-section__logo > * {
  width: 100%;
  height: auto;
}

.after-register .credentials-section__logo:not(:last-child) {
  margin-bottom: 4vh;
}

.after-register .form-credentials-section__buttons-container {
  max-width: 37vh;
}

.after-register .form-credentials-section__buttons-container:not(:last-child) {
  margin-bottom: 5vh;
}

.after-register .form-credentials-section__buttons-container .btn .icon {
  position: absolute;
  width: 1.9vh;
  height: 1.9vh;
  top: 50%;
  right: 1.5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.after-register .form-credentials-section__buttons-container .btn .icon > * {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.after-register .form-credentials-section__buttons-container > .mobile {
  display: none;
}

.create-event .form-edit-profile__birthday:not(:last-child) {
  margin-bottom: 5vh;
}

.money-present .long-checkbox__text.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1vh;
}

.money-present .long-checkbox__text.flex .icon {
  width: 4.5vh;
  height: 4.5vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 4.5vh;
  flex: 0 0 4.5vh;
}

.money-present .long-checkbox__text.flex .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.money-present .long-checkbox__text.flex-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.money-present .long-checkbox__text a.btn {
  color: #fff !important;
  font-size: 1.8vh;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.5vh;
  flex: 0 0 8.5vh;
  width: 8.5vh;
  text-decoration: none !important;
  border-radius: 0.5vh;
  padding: 0.5vh 0.25vh 0.5vh 1.25vh;
  margin-left: 4vh;
}

.money-present .long-checkbox__text a.btn::after {
  left: 0.75vh;
  width: 1vh;
  height: 1vh;
}

.money-present .credentials-section__content-block {
  height: 35vh;
  overflow: auto;
  padding-left: 1.5vh;
  padding-bottom: 1vh;
}

.money-present .credentials-section__content-block:not(:last-child) {
  margin-bottom: 3vh;
}

.money-present .credentials-section__content-block::-webkit-scrollbar {
  width: 0.8vh;
}

.money-present .credentials-section__content-block::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--main-pink);
  border-radius: 0.28125rem;
}

.blessing-write-title .credentials-section__title {
  color: #ffaf00;
}

.blessing-write-title .credentials-section__title:not(:last-child) {
  margin-bottom: 1.6vh;
}

.blessing-write-title .credentials-section__form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.blessing-write-title .form-credentials-section {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blessing-write-title .form-credentials-section__form-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.blessing-write-title .form-credentials-section__top-block,
.blessing-write-title .form-credentials-section__bottom-block {
  padding-left: 9vh;
  padding-right: 9vh;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.blessing-write-title .form-credentials-section__top-block {
  padding-bottom: 6vh;
  -webkit-filter: drop-shadow(0 3.2vh 2.5vh rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 3.2vh 2.5vh rgba(0, 0, 0, 0.16));
  border-radius: 1.875rem/1.25rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.blessing-write-title .form-credentials-section__bottom-block {
  border-radius: 1.875rem/1.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 2.5vh;
  -webkit-filter: drop-shadow(0 -3.2vh 2.5vh rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3.2vh 2.5vh rgba(0, 0, 0, 0.16));
}

.blessing-write-title .form-credentials-section__big-image-ibg {
  position: absolute;
  bottom: 11%;
  right: 0;
  width: 100%;
  padding-bottom: 73.4453781513%;
}

.blessing-write-title .form-credentials-section .input-wrapper {
  padding-top: 0;
}

.blessing-write-title .form-credentials-section .input-wrapper textarea {
  height: 10.4vh;
}

.main-contact-us {
  padding-top: 3.4375rem;
  padding-bottom: 6.25rem;
}

.main-contact-us__title {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: var(--text-primary);
}

.main-contact-us__title:not(:last-child) {
  margin-bottom: 2.1875rem;
}

.main-contact-us__form-block:not(:last-child) {
  margin-bottom: 5rem;
}

.main-contact-us__socials-title {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: var(--text-primary);
}

.main-contact-us__socials-title:not(:last-child) {
  margin-bottom: 0.8125rem;
}

.main-contact-us__socials-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 2.1875rem;
}

.main-contact-us__socials-link {
  width: 2.1875rem;
  height: 2.1875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-contact-us__socials-link > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.contact-us-form__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contact-us-form__body:not(:last-child) {
  margin-bottom: 3.125rem;
}

.contact-us-form__col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.9298245614%;
  flex: 0 0 41.9298245614%;
}

.contact-us-form__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 49.8245614035%;
  flex: 0 0 49.8245614035%;
}

.contact-us-form__input-wrapper {
  display: block;
  position: relative;
}

.contact-us-form__input-wrapper:not(:last-child) {
  margin-bottom: 2.5rem;
}

.contact-us-form__input-wrapper textarea.input {
  height: 100%;
}

.contact-us-form__input-wrapper_txta {
  height: 100%;
}

.contact-us-form__tel-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
}

.contact-us-form__tel-wrapper label {
  display: block;
}

.contact-us-form__tel-wrapper label:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70.2928870293%;
  flex: 0 0 70.2928870293%;
}

.contact-us-form__tel-wrapper label:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.contact-us-form__tel-wrapper::after {
  content: "-";
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--text-primary);
  align-self: flex-start;
  transform: translateY(50%);
}

.contact-us-form__tel-wrapper:not(:last-child) {
  margin-bottom: 2.5rem;
}

.contact-us-form__btn-container .btn {
  max-width: 20rem;
  width: 100%;
}

.main-terms-conditions {
  padding-top: 4.0625rem;
  padding-bottom: 6.25rem;
}

.main-terms-conditions__content {
  font-weight: normal;
  font-size: 1.125rem;
  text-align: right;
  color: var(--text-primary);
}

.main-terms-conditions__content:not(:last-child) {
  margin-bottom: 7.5rem;
}

.main-terms-conditions__submit-block .btn-container {
  text-align: left;
}

.main-terms-conditions__submit-block .btn-container .btn {
  max-width: 20rem;
  width: 100%;
  background-color: #acacac;
  pointer-events: none;
}

.main-terms-conditions__checkbox-wrap .checkbox__label:not(:last-child) {
  margin-bottom: 1.125rem;
}

.main-terms-conditions__checkbox-wrap .checkbox__text {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 400;
}

.main-terms-conditions__checkbox-wrap .checkbox__input:checked ~ .btn-container .btn {
  background-color: var(--green);
  pointer-events: initial;
}

.category .topbar {
  padding: 0;
}

.category .topbar .topbar__title-wrapper {
  text-align: left;
}

.category .topbar .topbar__title {
  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 1;
  text-align: center;
  color: var(--text-secondary);
  max-width: 36.75rem;
  padding: 2.1875rem 0;
  margin-left: 1.875rem;
}

.main-category {
  padding-top: 1.5625rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 10;
}

.main-category__steps-numbers:not(:last-child) {
  margin-bottom: 2.5rem;
}

.main-category__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  text-align: center;
  color: var(--text-primary);
}

.main-category__title:not(:last-child) {
  margin-bottom: 4.375rem;
}

.main-category__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 3rem;
  -moz-column-gap: 3rem;
  column-gap: 3rem;
  row-gap: 3.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-category__list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33333% - 2rem);
  flex: 0 0 calc(33.33333% - 2rem);
}

.single-category-type__content {
  display: block;
  background-color: #fff;
  border-radius: 0.625rem;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
}

.single-category-type__image-ibg {
  width: 100%;
  padding-bottom: 79.4797687861%;
}

.single-category-type__image-ibg img {
  border-radius: 0.625rem;
}

.single-category-type__text-content {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.single-category-type__icon {
  width: 2.625rem;
  height: 2.625rem;
  margin: 0 auto;
  margin-top: -1.3125rem;
  border-radius: 0.625rem;
  background-color: var(--pink);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.single-category-type__icon:not(:last-child) {
  margin-bottom: 0.6875rem;
}

.single-category-type__icon > * {
  width: 71.4285714286%;
  height: 71.4285714286%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 71.4285714286%;
  flex: 0 0 71.4285714286%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-category-type__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.125;
  text-align: center;
  color: var(--text-pink);
}

.single-category-type__title:not(:last-child) {
  margin-bottom: 0.0625rem;
}

.single-category-type__text {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.1666666667;
  text-align: center;
  color: var(--text-primary);
}

.single-category-type__text:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.single-category-type__btn-container:not(:last-child) {
  margin-bottom: 1.3125rem;
}

.single-category-type__btn-container .btn {
  font-size: 1.125rem;
  max-width: 14.375rem;
  width: 100%;
  padding: 0.625rem 0.9375rem;
}

.single-category-type__footer {
  border-top: 0.0625rem solid #c7c9d2;
}

.single-category-type__dropdown {
  position: relative;
  width: calc(100% + 1.875rem);
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.single-category-type__dropdown:hover .single-category-type__dropdown-body {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
}

.single-category-type__dropdown-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  gap: 0.625rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  color: var(--text-primary);
  position: relative;
  z-index: 2;
}

.single-category-type__dropdown-btn img {
  width: 1rem;
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
}

.single-category-type__dropdown-body {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 0.625rem;
  padding-top: 3.5625rem;
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
  transition: opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s, -webkit-transform 0.3s ease 0s;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  z-index: 1000;
}

.single-category-type__dropdown-list {
  max-width: 8.75rem;
  margin: auto;
}

.single-category-type__dropdown-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: right;
  color: var(--text-primary);
}
@media (max-width: 120em) and (min-width: 47.99875em) {
  .single-category-type__dropdown-list-item {
    gap: 0.521vw;
    font-size: 0.7292vw;
  }
}

.single-category-type__dropdown-list-item::before {
  content: "";
  width: 0.8125rem;
  height: 0.8125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.8125rem;
  flex: 0 0 0.8125rem;
  border-radius: 0.1875rem;
  background: #fff;
  border: 0.03125rem solid #8f8f8f;
}

.single-category-type__dropdown-list-item.active::before {
  background-color: var(--main-pink);
}

.event-add-presents__topbar .topbar__title {
  padding: 0.625rem 10rem;
}

.event-add-presents__topbar .topbar__title .icon {
  width: 7.875rem;
  height: 6.375rem;
  bottom: -1.375rem;
  top: auto;
}

.main-event-add-presents {
  padding-top: 1.875rem;
  padding-bottom: 9.375rem;
}

.main-event-add-presents__title {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  color: var(--text-primary);
}

.main-event-add-presents__title:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-event-add-presents__item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.single-event-presents {
  background: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  padding: 1.25rem 1.25rem 1.25rem 2.8125rem;
}

.single-event-presents__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5625rem;
}

.single-event-presents__image-ibg {
  width: 7.25rem;
  height: 7.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.25rem;
  flex: 0 0 7.25rem;
}

.single-event-presents__image-ibg img {
  border-radius: 0.75rem;
}

.single-event-presents__text-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.single-event-presents__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: right;
  color: var(--text-primary);
}

.single-event-presents__title:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.single-event-presents__title span {
  color: var(--orange);
}

.single-event-presents__date {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
  color: var(--text-primary);
}

.single-event-presents__btn-container {
  text-align: left;
  margin-top: -0.9375rem;
}

.single-event-presents__btn-container .btn {
  width: 13.125rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.single-event-presents__btn-container .btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.9375rem;
  width: 1.5rem;
  height: 1.6875rem;
  background: url("../img/svg/gift.svg") center/contain no-repeat;
}

.edit-profile .topbar__title {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.main-edit-profile {
  padding-top: 3.75rem;
  padding-bottom: 9.375rem;
}

.main-edit-profile__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 0.9375rem;
}

.main-edit-profile__title:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.main-edit-profile__title .icon {
  width: 1.125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.125rem;
  flex: 0 0 1.125rem;
  height: auto;
}

.main-edit-profile__title .icon > * {
  width: 100%;
  height: auto;
}

.main-edit-profile__title .text {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375;
  color: var(--text-primary);
}

.main-edit-profile__form-block {
  max-width: 56.25rem;
  margin: auto;
}

.birthday-field {
  margin-top: 2.8125rem;
}

.birthday-field__birthday-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.birthday-field__hidden-main-field {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 5;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  background: transparent;
}
input[type="time"] {
  text-align: right;
}
.birthday-field:not(:last-child) {
  margin-bottom: 1.4vh;
}
.birthday-field__birthday-body .birthday-field__input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27.1052631579%;
  flex: 0 0 27.1052631579%;
  width: 27.1052631579%;
  position: relative;
  margin-bottom: 0 !important;
}
.birthday-field__birthday-body .birthday-field__input-wrapper input {
  height: 100%;
  margin-bottom: 0;
}

.birthday-field__birthday-body .birthday-field__input-wrapper.input-error {
  margin-bottom: 1.75rem !important;
}
@media (max-width: 120em) and (min-width: 47.99875em) {
  .birthday-field__birthday-body .birthday-field__input-wrapper.input-error {
    margin-bottom: 4vh;
  }
}

.birthday-field__birthday-body .birthday-field__input-wrapper:not(:last-child)::after {
  content: "/";
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 1;
  color: var(--text-primary);
  position: absolute;
  top: 50%;
  left: -1.25rem;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
}

.birthday-field__birthday-title {
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--text-primary);
  line-height: 1.2777777778;
}

.birthday-field__birthday-title:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.form-edit-profile__form-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-edit-profile__form-body:not(:last-child) {
  margin-bottom: 3.4375rem;
}

.form-edit-profile__col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 42.2222222222%;
  flex: 0 0 42.2222222222%;
}

.form-edit-profile__input-wrapper {
  display: block;
  position: relative;
}

.form-edit-profile__input-wrapper:not(:last-child) {
  margin-bottom: 1.25rem;
}

.form-edit-profile__tel-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
}

.form-edit-profile__tel-wrapper label {
  display: block;
}

.form-edit-profile__tel-wrapper label:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70.2928870293%;
  flex: 0 0 70.2928870293%;
}

.form-edit-profile__tel-wrapper label:last-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  flex: 0 1 5rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.form-edit-profile__tel-wrapper::after {
  content: "-";
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--text-primary);
  transform: translateY(55%);
  align-self: flex-start;
}

.form-edit-profile__tel-wrapper:not(:last-child) {
  margin-bottom: 1.25rem;
}

.form-edit-profile__col-title {
  font-weight: bold;
  font-size: 1.125rem;
  color: var(--text-primary);
  line-height: 1.2777777778;
  margin-top: -0.9375rem;
}

.form-edit-profile__col-title:not(:last-child) {
  margin-bottom: 1.25rem;
}

.form-edit-profile__address-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 3.75rem;
}

.form-edit-profile__address-details .form-edit-profile__input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 27.1052631579%;
  flex: 0 0 27.1052631579%;
  width: 27.1052631579%;
}

.form-edit-profile__mail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.form-edit-profile__mail .form-edit-profile__input-wrapper:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 36.8421052632%;
  flex: 0 0 36.8421052632%;
  width: 36.8421052632%;
}

.form-edit-profile__mail .form-edit-profile__input-wrapper:last-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55.2631578947%;
  flex: 0 0 55.2631578947%;
  width: 55.2631578947%;
}

.form-edit-profile__checkbox {
  margin-top: 0.9375rem;
}

.form-edit-profile__checkbox .checkbox__text {
  color: var(--text-primary);
  font-weight: 400;
}

.form-edit-profile__btn-container .btn {
  max-width: 20rem;
  width: 100%;
}

.products .topbar {
  padding: 0;
}
.products.new-event-type .topbar {
  padding: 3rem 0;
}
.products.new-event-type .topbar__btn {
  padding: 0.4375rem 1.25rem 0.4375rem 2.5rem;
}
.products ~ #item-added-to-cart-popup .continue-edit {
  display: none;
}
.products.new-event-type ~ #item-added-to-cart-popup .btn-white.favorites,
.products.new-event-type ~ #item-added-to-cart-popup .btn-white.money {
  display: none;
}
.products.new-event-type ~ #item-added-to-cart-popup .continue-edit {
  display: inline-flex;
}

@media (max-width: 120em) and (min-width: 47.99875em) {
  .products.new-event-type .topbar {
    padding: 2.5vw 0;
  }
  .products.new-event-type .topbar__btn {
    padding: 0.3645833vw 1.042vw 0.3645833vw 2.08333vw;
  }
}

@media (max-width: 47.99875em) {
  .products.new-event-type .topbar .topbar__title-wrapper {
    align-items: center;
    height: 10rem;
  }

  .popup-phones .popup-phones__submit.add-phone {
    justify-content: center;
    margin-bottom: 0.5em;
  }
}

.products .topbar .topbar__title-wrapper {
  text-align: left;
}

.products .topbar .topbar__title {
  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 1;
  text-align: center;
  color: var(--text-primary);
  max-width: 36.75rem;
  padding: 3.875rem 0;
  margin-left: 3.75rem;
}

.main-products {
  padding-top: 1.5625rem;
  padding-bottom: 6.875rem;
}
.main-products.mb-0 {
  padding-bottom: 0;
}

.main-products_related {
  padding-top: 4.0625rem;
  padding-bottom: 3.4375rem;
  background-color: #f2f2f2;
}

.main-products_recent {
  padding-top: 5rem;
  padding-bottom: 9.375rem;
}

.main-products__steps:not(:last-child) {
  margin-bottom: 2.5rem;
}

.main-products__pretitle {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2727272727;
  text-align: center;
}

.main-products__pretitle:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.main-products__title {
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.2285714286;
  text-align: center;
  color: var(--text-pink);
}

.main-products__subtitle {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2272727273;
  text-align: center;
  color: var(--text-primary);
}

.main-products__subtitle:not(:last-child) {
  margin-bottom: 3.125rem;
}

.main-products__slider-block {
  position: relative;
}

.main-products__slider {
  padding: 3.125rem 0.9375rem;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}

.main-products__slider .single-products-card {
  -webkit-box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.16);
}

.main-products__slider-prev,
.main-products__slider-next {
  position: absolute;
  top: 50%;
  width: 2.375rem;
  height: 2.375rem;
  background-color: #e3e3e3;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease 0s, opacity 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s, opacity 0.3s ease 0s;
  transition: background-color 0.3s ease 0s, opacity 0.3s ease 0s;
}

.main-products__slider-prev.swiper-button-disabled,
.main-products__slider-next.swiper-button-disabled {
  pointer-events: none;
  opacity: 0;
}

.main-products__slider-prev:active,
.main-products__slider-next:active {
  background-color: #bebebe;
}

.main-products__slider-prev > *,
.main-products__slider-next > * {
  width: 20%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  height: auto;
}

.main-products__slider-prev {
  right: -3.875rem;
}

.main-products__slider-prev > * {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.main-products__slider-next {
  left: -3.875rem;
}

.main-products__btn-container .btn-outline-white {
  border-color: #bbb;
  width: 19.375rem;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1.6875rem;
  -webkit-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}

.main-products__btn-container .btn-outline-white::after {
  content: "";
  left: 0.8125rem;
}

.main-products__btn-container .btn-outline-white::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.8125rem;
  width: 1rem;
  height: 1.3125rem;
  background: url("../img/svg/filter-icon-black.svg") center/contain no-repeat;
  z-index: 2;
}

.main-products__btn-container .btn-outline-white::before,
.main-products__btn-container .btn-outline-white::after {
  -webkit-transition: -webkit-filter 0.3s ease 0s;
  transition: -webkit-filter 0.3s ease 0s;
  -o-transition: filter 0.3s ease 0s;
  transition: filter 0.3s ease 0s;
  transition: filter 0.3s ease 0s, -webkit-filter 0.3s ease 0s;
}

.main-products__view-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.625rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.main-products__view-type:not(:last-child) {
  margin-bottom: 1.875rem;
}

.main-products__view-type-btn {
  display: block;
  height: 1.1875rem;
}

.main-products__view-type-btn > * {
  height: 100%;
  width: auto;
}

.main-products__view-type-btn > * * {
  fill: #d8d8d8;
}

.main-products__view-type-btn.active > * * {
  fill: #000;
}

.main-products__mobile-header {
  display: none;
}

.main-products__header-title {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 700;
  padding-right: 0.9375rem;
}

.main-products__view-type-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
}

.main-products__view-type-mobile button {
  height: 1.25rem;
}

.main-products__view-type-mobile button > * {
  height: 100%;
  width: auto;
  opacity: 0.25;
}

.main-products__view-type-mobile button.active > * {
  opacity: 1;
}

.main-products__list-nav {
  display: none;
}

.main-products__list-prev,
.main-products__list-next {
  position: absolute;
  width: 3.75rem;
  height: 3.75rem;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  padding-right: 0.875rem;
  -webkit-box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}

.main-products__list-prev > *,
.main-products__list-next > * {
  width: 20%;
  height: auto;
}

.main-products__list-prev.swiper-button-disabled,
.main-products__list-next.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.main-products__list-prev {
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  -ms-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  -webkit-box-shadow: 0 -0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 -0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
}

.main-products__list-next {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.single-products-card {
  border-radius: 0.75rem;
  background: #fff;
  -webkit-box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.16);
  height: 100%;
}

.single-products-card:hover .single-products-card__link {
  opacity: 1;
  bottom: 25%;
  pointer-events: initial;
}

.single-products-card:hover .single-products-card__image-ibg::after {
  opacity: 0;
}

.single-products-card__image-ibg {
  width: 100%;
  padding-bottom: 92.6345609065%;
}

.single-products-card__image-ibg img {
  border-radius: 0.75rem 0.75rem 0 0;
}

.single-products-card__image-ibg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 35.4740061162%;
  bottom: 0;
  right: 0;
  z-index: 2;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  pointer-events: none;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2)));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}

.single-products-card__buttons {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  padding: 0.875rem 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 2;
}

.single-products-card__buttons .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1.6875rem;
  height: 2.125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.125rem;
  flex: 0 0 2.125rem;
}

.single-products-card__buttons .button:not(:last-child) {
  margin-bottom: 1.125rem;
}

.single-products-card__buttons .button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.single-products-card__buttons_mobile {
  display: none;
  bottom: 0;
  left: 1.25rem;
  height: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.single-products-card__link {
  position: absolute;
  bottom: 0;
  right: 50%;
  max-width: 17.5rem;
  width: 95%;
  font-size: 1.125rem;
  font-weight: 700;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  padding: 0.75rem 1.25rem;
  border-radius: 0.6875rem;
  opacity: 0;
  z-index: 3;
  pointer-events: none;
  -webkit-transition: bottom 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: bottom 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: bottom 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
}

.single-products-card__link::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.125rem;
  width: 1.375rem;
  height: 1.6875rem;
  background: url("../img/svg/shopping-bag.svg") center/contain no-repeat;
  z-index: 2;
}

.single-products-card__text-content {
  background-color: #fff;
  padding: 1.25rem 1.875rem;
  text-align: center;
  border-radius: 0 0 0.75rem 0.75rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.single-products-card__title {
  font-weight: bold;
  font-size: 1.4375rem;
  line-height: 1.3043478261;
  text-align: center;
  position: relative;
  display: inline-block;
  padding-bottom: 0.25rem;
}

.single-products-card__title:not(:last-child) {
  margin-bottom: 1.125rem;
}

.single-products-card__title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0;
  right: 0;
  background-color: #000;
}

.single-products-card__rating:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.single-products-card__text {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 600;
  line-height: 1.2777777778;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-products-card__text:not(:last-child) {
  margin-bottom: 0.625rem;
}

.single-products-card__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  gap: 0.625rem;
}

.single-products-card__price .new {
  font-weight: 600;
  font-size: 2.375rem;
  text-align: center;
  line-height: 1;
  color: var(--text-green);
}

.single-products-card__price .new .money-icon {
  font-size: 0.5789473684em;
}

.single-products-card__price .old {
  font-size: 1.25rem;
  color: var(--text-dark-gray);
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0.125rem;
}

.single-products-card__price .old .money-icon {
  font-size: 0.9em;
  margin-right: 0.3125rem;
}

.single-products-card__price .old .text {
  text-decoration: line-through;
}

.products .single-products-card__link {
  background-color: var(--main-pink);
}

.products .single-products-card__link:active {
  background-color: var(--green);
}

.main-single-product {
  padding-top: 4.375rem;
}

.main-single-product__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 5rem;
}

.main-single-product__mobile-bottom-text {
  display: none;
}

.main-single-product__gallery-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 47.1052631579%;
  flex: 0 0 47.1052631579%;
  min-width: 0;
}

.main-single-product__main-slider-block {
  position: relative;
}

.main-single-product__main-slider-block:not(:last-child) {
  margin-bottom: 1.5625rem;
}

.main-single-product__main-slider-block .search-btn {
  position: absolute;
  z-index: 2;
  top: 1.25rem;
  left: 0.4375rem;
  width: 2rem;
  height: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #a7a7a7;
  border-radius: 0.375rem;
}

.main-single-product__main-slider-block .search-btn > * {
  width: 65.625%;
  height: 65.625%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65.625%;
  flex: 0 0 65.625%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-single-product__main-slider-block .bottom-buttons {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.9375rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.84);
}

.main-single-product__main-slider-block .bottom-buttons button {
  height: 2.3125rem;
  width: 2.1875rem;
  display: block;
}

.main-single-product__main-slider-block .bottom-buttons button > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-single-product__main-navigation {
  display: none;
}

.main-single-product__main-next,
.main-single-product__main-prev {
  position: absolute;
  width: 3.75rem;
  height: 3.75rem;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  padding-right: 0.875rem;
  -webkit-box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  cursor: pointer;
}

.main-single-product__main-next > *,
.main-single-product__main-prev > * {
  width: 20%;
  height: auto;
}

.main-single-product__main-next.swiper-button-disabled,
.main-single-product__main-prev.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.main-single-product__main-prev {
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  -ms-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  -webkit-box-shadow: 0 -0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 -0.5rem 0.625rem rgba(0, 0, 0, 0.1607843137);
}

.main-single-product__main-next {
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.main-single-product__main-slide {
  position: relative;
}

.main-single-product__main-slide::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 1;
  z-index: 2;
  -webkit-transition: opacity 0.8s ease 0s;
  -o-transition: opacity 0.8s ease 0s;
  transition: opacity 0.8s ease 0s;
}

.main-single-product__main-slide.swiper-slide-active::before {
  opacity: 0;
}

.main-single-product__image-ibg {
  width: 100%;
  padding-bottom: 100%;
  border: 0.0625rem solid #a7a7a7;
}

.main-single-product__thumbs-slider-block {
  padding: 0 2.25rem;
  position: relative;
}

.main-single-product__thumbs-next,
.main-single-product__thumbs-prev {
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e3e3e3;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  cursor: pointer;
}

.main-single-product__thumbs-next > *,
.main-single-product__thumbs-prev > * {
  width: 30%;
  height: auto;
}

.main-single-product__thumbs-next.swiper-button-disabled,
.main-single-product__thumbs-prev.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.main-single-product__thumbs-prev {
  right: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.main-single-product__thumbs-next {
  left: 0;
}

.main-single-product__content-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48.3333333333%;
  flex: 0 0 48.3333333333%;
}

.main-single-product__title {
  font-weight: bold;
  font-size: 2.5rem;
  color: var(--text-primary);
}

.main-single-product__title:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.main-single-product__price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 0.1875rem;
}

.main-single-product__price .old {
  color: var(--text-dark-gray);
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 0.1875rem;
}

.main-single-product__price .old .text {
  text-decoration: line-through;
}

.main-single-product__price .old .money-icon {
  font-size: 0.6em;
}

.main-single-product__price .new {
  font-weight: 600;
  font-size: 3.125rem;
  color: var(--text-pink);
}

.main-single-product__price .new .money-icon {
  font-size: 0.44em;
  margin-right: -0.5rem;
}

.main-single-product__price:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.main-single-product__rating {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.main-single-product__rating:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.main-single-product__describe {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4444444444;
}

.main-single-product__describe:not(:last-child) {
  margin-bottom: 1.25rem;
}

.main-single-product__describe h2 {
  font-size: 1.75rem;
  line-height: 1.3214285714;
}

.main-single-product__describe h3 {
  font-size: 1.5rem;
  line-height: 1.3214285714;
}

.main-single-product__describe *:not(:last-child) {
  margin-bottom: 0.25em;
}

.main-single-product__describe ul li {
  padding-left: 0.3125rem;
}

.main-single-product__describe ul li:not(:last-child) {
  margin-bottom: 0.5em;
}

.main-single-product__describe ul li::before {
  content: "•";
  color: var(--green);
  margin-left: 0.3125rem;
}

.main-single-product__describe ol {
  padding-left: 1.5625rem;
}

.main-single-product__describe b,
.main-single-product__describe strong {
  font-weight: 700;
}

.main-single-product__describe i,
.main-single-product__describe em {
  font-style: italic;
}

.main-single-product__describe a,
.main-single-product__describe button {
  font-weight: 600;
  color: var(--main-pink);
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.main-single-product__describe a:active,
.main-single-product__describe button:active {
  color: #fdc02e;
}

.main-single-product__spollers-item {
  border-bottom: 0.0625rem solid #a7a7a7;
}

.main-single-product__spollers-item:first-child {
  border-top: 0.0625rem solid #a7a7a7;
}

.main-single-product__spollers-item[open] .main-single-product__spollers-title {
  color: var(--text-pink);
}

.main-single-product__spollers-title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  padding: 1.375rem 0;
  list-style: none;
  cursor: pointer;
}

.main-single-product__spollers-body {
  padding: 0 0 1.5rem;
}

.main-single-product__spollers-body * {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
}

.main-single-product__spollers-body *:not(:last-child) {
  margin-bottom: 1em;
}

.main-single-product__spollers-body * span {
  font-weight: 700;
}

.main-single-product__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.5rem 0.625rem 2.5rem 0;
  gap: 2.25rem;
}

.main-single-product__bottom-ratings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2.25rem;
}

.main-single-product__likes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-single-product__likes .icon {
  width: 1.0625rem;
}

.main-single-product__likes .icon > * {
  width: 100%;
  height: auto;
}

.main-single-product__likes .number {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2777777778;
  text-align: center;
}

.main-single-product__rating-wrapper .rating:not(:last-child) {
  margin-bottom: 0.375rem;
}

.main-single-product__rating-wrapper .rating__body {
  width: 7.75rem;
  height: 1.375rem;
}

.main-single-product__rating-wrapper .rating-text {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.6428571429;
  text-align: center;
}

.form-main-single-product__color-selector:not(:last-child) {
  margin-bottom: 1.625rem;
}

.form-main-single-product__title {
  font-weight: bold;
  font-size: 1.25rem;
}

.form-main-single-product__title:not(:last-child) {
  margin-bottom: 0.8125rem;
}

.form-main-single-product__color-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.form-main-single-product__color-options .options__input {
  display: none;
}

.form-main-single-product__color-options .options__input:checked + .options__label::before {
  opacity: 1;
}

.form-main-single-product__color-options .options__label {
  display: block;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid #959595;
  position: relative;
  cursor: pointer;
}

.form-main-single-product__color-options .options__label::before {
  content: "";
  position: absolute;
  width: calc(100% + 0.125rem);
  height: calc(100% + 0.125rem);
  top: -0.0625rem;
  left: -0.0625rem;
  border: 0.1875rem solid var(--main-pink);
  border-radius: 0.5rem;
  z-index: 2;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.form-main-single-product__size-selector:not(:last-child) {
  margin-bottom: 1.875rem;
}

.form-main-single-product__size-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.form-main-single-product__size-options .options__input {
  display: none;
}

.form-main-single-product__size-options .options__input:checked + .options__label {
  background-color: var(--main-pink);
}

.form-main-single-product__size-options .options__input:checked + .options__label .options__text {
  color: var(--text-secondary);
}

.form-main-single-product__size-options .options__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 0.5rem;
  background-color: #fff;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
  border: 0.0625rem solid var(--main-pink);
  cursor: pointer;
}

.form-main-single-product__size-options .options__text {
  font-weight: bold;
  font-size: 1.125rem;
  -webkit-transition: color 0.15s ease 0s;
  -o-transition: color 0.15s ease 0s;
  transition: color 0.15s ease 0s;
}

.form-main-single-product__quantity-wrapper:not(:last-child) {
  margin-bottom: 3.125rem;
}

.form-main-single-product__quantity-title {
  display: none;
}

.form-main-single-product__bottom-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.75rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-main-single-product__bottom-buttons .btn,
.form-main-single-product__bottom-buttons .btn-black {
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.0625rem 0.625rem;
  border-radius: 1.125rem;
}

.form-main-single-product__bottom-buttons .btn::after,
.form-main-single-product__bottom-buttons .btn-black::after {
  content: "";
  left: 0.875rem;
}

.form-main-single-product__bottom-buttons .btn {
  width: 20.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20.625rem;
  flex: 0 0 20.625rem;
}

.form-main-single-product__bottom-buttons .btn-black {
  width: 12.9375rem;
  padding: 1.0625rem 0.625rem 1.0625rem 1.875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.9375rem;
  flex: 0 0 12.9375rem;
}

.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border: 0.0625rem solid #707070;
  border-radius: 0.6875rem;
  height: 3.5625rem;
  width: 11.25rem;
}

.quantity__button {
  width: 33.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.4444444444;
  text-align: center;
  color: #000;
}

.quantity__input {
  width: 33.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.quantity__input input {
  display: block;
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  text-align: center;
  border: 0.0625rem solid #707070;
  border-top: 0;
  border-bottom: 0;
  font-size: 1.25rem;
}

.products__bottom-menu {
  -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  display: none;
}

.products__bottom-menu-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products__bottom-menu-body > * {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.products__bottom-menu-body .gift-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem;
  background-color: #fff;
}

.products__bottom-menu-body .gift-link .gift-icon {
  display: block;
  width: 1.8125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.8125rem;
  flex: 0 0 1.8125rem;
}

.products__bottom-menu-body .gift-link .gift-icon > * {
  width: 100%;
  height: auto;
}

.products__bottom-menu-body .gift-link .text {
  font-size: 1.375rem;
  line-height: 1;
  font-weight: 700;
}

.products__bottom-menu-body .gift-link .arrow {
  width: 0.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.625rem;
  flex: 0 0 0.625rem;
  display: block;
}

.products__bottom-menu-body .gift-link .arrow > * {
  width: 100%;
  height: auto;
}

.products__bottom-menu-body .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  font-weight: 700;
  border-radius: 0;
}

.products__bottom-menu-body .btn .icon {
  width: 1.3125rem;
  height: 1.3125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.3125rem;
  flex: 0 0 1.3125rem;
}

.products__bottom-menu-body .btn .icon > * {
  width: 100%;
  height: auto;
}

.my-bank .topbar__title {
  padding: 1.875rem 9.375rem;
}

.my-bank .topbar__title .icon {
  width: 9.375rem;
}

.main-my-bank__container.mobile {
  display: none;
}

.main-my-bank__body {
  background: url("../img/popups/popup-green-bg.png") center/cover no-repeat;
  min-height: calc(100 * var(--vh));
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.main-my-bank__body.empty .main-my-bank__subtitle:not(:last-child) {
  margin-bottom: 0.1875rem;
}

.main-my-bank__icon {
  max-width: 15.3125rem;
  margin: 0 auto;
}

.main-my-bank__icon:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.main-my-bank__icon > * {
  width: 100%;
  height: auto;
}

.main-my-bank__title {
  font-size: 1.6875rem;
  line-height: 1.3703703704;
  font-weight: 700;
}

.main-my-bank__title:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.main-my-bank__subtitle {
  font-size: 1.6875rem;
  line-height: 1.037037037;
  font-weight: 600;
  max-width: 16.5625rem;
  margin: 0 auto;
}

.main-my-bank__subtitle:not(:last-child) {
  margin-bottom: 1.6875rem;
}

.main-my-bank__price:not(:last-child) {
  margin-bottom: 2.1875rem;
}

.main-my-bank__price-text {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1666666667;
}

.main-my-bank__price-text:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.main-my-bank__price-value {
  font-size: 6.25rem;
  font-weight: 600;
}

.main-my-bank__price-value .money-icon {
  font-size: 0.33em;
  display: inline-block;
  margin-right: -0.4375rem;
}

.main-my-bank__btn-container.mobile-bank {
  /* display: flex;
  align-items: stretch; */
}
.main-my-bank__btn-container.mobile-bank > * {
  flex: 1;
}
.main-my-bank__btn-container.mobile-bank .btn-white {
  padding-left: 1.2em;
}

.main-my-bank__btn-container:not(:last-child) {
  margin-bottom: 4.0625rem;
}

.main-my-bank__btn-container > * {
  max-width: 20rem;
  width: 100%;
}

.main-my-bank__btn-container .btn-white::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1.5625rem;
  width: 1.25rem;
  height: 1.5rem;
  background: url("../img/svg/gift-black.svg") center/contain no-repeat;
  z-index: 2;
}

.main-my-bank__bottom-nav-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.875rem;
}

.main-my-bank__bottom-nav-buttons button {
  width: 2.375rem;
  height: 2.375rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.375rem;
  flex: 0 0 2.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
  border: 0.0625rem solid var(--text-dark-gray);
}

.main-my-bank__bottom-nav-buttons button:first-child {
  background-color: #f8981d;
}

.main-my-bank__bottom-nav-buttons button:nth-child(2) {
  background-color: var(--main-pink);
}

.main-my-bank__sad-emogi {
  max-width: 5.5625rem;
  width: 100%;
  margin: 0 auto;
  height: 5.3125rem;
}

.main-my-bank__sad-emogi:not(:last-child) {
  margin-bottom: 0.625rem;
}

.main-my-bank__sad-emogi > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.reminders .topbar__title {
  padding: 1.25rem 8.125rem;
}

.main-reminders {
  padding-top: 1.875rem;
  padding-bottom: 9.375rem;
}

.main-reminders__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}

.main-reminders__header:not(:last-child) {
  margin-bottom: 2.5rem;
}

.main-reminders__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3.125rem;
  padding: 0.9375rem 0;
}

.main-reminders__button {
  font-weight: 600;
  font-size: 1.25rem;
  padding: 0.3125rem 0;
  color: var(--text-gray);
  position: relative;
  -webkit-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  transition: color 0.2s ease 0s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.4375rem;
}

.main-reminders__button .icon {
  width: 1.3125rem;
  height: 1.3125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.3125rem;
  flex: 0 0 1.3125rem;
  border-radius: 50%;
  background-color: var(--text-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.main-reminders__button .icon > * {
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-reminders__button.active {
  color: var(--text-primary);
}

.main-reminders__nav-item:first-child .main-reminders__button.active .icon {
  background-color: var(--main-pink);
}

.main-reminders__nav-item:nth-child(2) .main-reminders__button.active .icon {
  background-color: var(--orange);
}

.main-reminders__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.75rem;
}

.main-reminders__filter .text {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25;
  text-align: center;
  color: var(--text-primary);
  margin-left: 0.625rem;
}

.main-reminders__filter .arrow {
  width: 0.9375rem;
}

.main-reminders__filter .arrow > * {
  width: 100%;
  height: auto;
}

.main-reminders__item:not(:last-child) {
  margin-bottom: 1.875rem;
}

.single-reminder__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.16));
  border-radius: 0.5rem;
  padding: 1.0625rem 1.1875rem 1.0625rem 1.6875rem;
}

.single-reminder__image-ibg {
  width: 7.25rem;
  padding-bottom: 7.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.25rem;
  flex: 0 0 7.25rem;
  margin-left: 1.5625rem;
}

.single-reminder__image-ibg img {
  border-radius: 0.75rem;
}

.single-reminder__text-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
  padding-left: 2rem;
}

.single-reminder__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.25;
}

.single-reminder__title span {
  color: var(--orange);
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.single-reminder__title:not(:last-child) {
  margin-bottom: 0.25em;
}

.single-reminder__text {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
}

.single-reminder__close {
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-12.5%);
  -ms-transform: translateX(-12.5%);
  transform: translateX(-12.5%);
}

.single-reminder__close::before,
.single-reminder__close::after {
  content: "";
  position: absolute;
  width: 75%;
  height: 0.125rem;
  top: 50%;
  left: 50%;
  border-radius: 0.375rem;
  background-color: var(--text-primary);
}

.single-reminder__close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.single-reminder__close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.reminders_pink .single-reminder span {
  color: var(--text-pink);
}

.main-all-categories {
  padding-bottom: 10.625rem;
  padding-top: 1.875rem;
}

.main-all-categories__steps-numbers:not(:last-child) {
  margin-bottom: 1.125rem;
}

.main-all-categories__titles-block {
  text-align: center;
}

.main-all-categories__titles-block:not(:last-child) {
  margin-bottom: 2.5rem;
}

.main-all-categories__title {
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.2285714286;
  color: var(--text-pink);
}

.main-all-categories__title:not(:last-child) {
  margin-bottom: 0.375rem;
}

.main-all-categories__subtitle {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.2272727273;
  color: var(--text-primary);
}

.main-all-categories__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 3.125rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-all-categories__list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33333% - 2.0833333125rem);
  flex: 0 0 calc(33.33333% - 2.0833333125rem);
}

.main-all-categories__list-item:nth-child(12n + 2) .single-event-type__icon {
  background-color: var(--blue);
}

.main-all-categories__list-item:nth-child(12n + 3) .single-event-type__icon {
  background-color: var(--purple);
}

.main-all-categories__list-item:nth-child(12n + 4) .single-event-type__icon {
  background-color: var(--red-orange);
}

.main-all-categories__list-item:nth-child(12n + 5) .single-event-type__icon {
  background-color: var(--yellow);
}

.main-all-categories__list-item:nth-child(12n + 6) .single-event-type__icon {
  background-color: var(--green);
}

.main-all-categories__list-item:nth-child(12n + 7) .single-event-type__icon {
  background-color: var(--orange);
}

.main-all-categories__list-item:nth-child(12n + 8) .single-event-type__icon {
  background-color: var(--light-blue);
}

.main-all-categories__list-item:nth-child(12n + 9) .single-event-type__icon {
  background-color: var(--light-pink);
}

.main-all-categories__list-item:nth-child(12n + 10) .single-event-type__icon {
  background-color: var(--dark-green);
}

.main-all-categories__list-item:nth-child(12n + 11) .single-event-type__icon {
  background-color: var(--dark-pink);
}

.main-all-categories__list-item:nth-child(12n) .single-event-type__icon {
  background-color: var(--dark-green);
}

.successful-payment {
  min-height: calc(100 * var(--vh));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-successful-payment {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: relative;
}

.main-successful-payment__bg-image-ibg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.main-successful-payment__content-window {
  position: absolute;
  width: 100%;
  max-width: 55vh;
  height: 88%;
  top: 6%;
  right: 9.375%;
  z-index: 3;
  background: url("../img/blessing/content-bg.png") right bottom/cover no-repeat;
  padding: 20vh 3vh 4vh;
  border-radius: 0.9375rem;
}

.main-successful-payment__title {
  font-weight: bold;
  font-size: 5vh;
  line-height: 1.08;
  text-align: center;
  color: var(--text-secondary);
}

.main-successful-payment__title:not(:last-child) {
  margin-bottom: 0.875rem;
}

.main-successful-payment__subtitle {
  font-weight: 600;
  font-size: 2.7vh;
  line-height: 1.037037037;
  text-align: center;
  color: var(--text-secondary);
}

.main-successful-payment__subtitle:not(:last-child) {
  margin-bottom: 4.5vh;
}

.choosed-presents .topbar {
  padding: 3.125rem 0rem 2.5rem 0rem;
}

.choosed-presents .topbar__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choosed-presents .topbar__text-part {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 38.6842105263%;
  flex: 0 0 38.6842105263%;
}

.choosed-presents .topbar__title {
  font-size: 2rem;
  padding: 0;
  font-family: "Assistant";
}

.choosed-presents .topbar__title span {
  color: #fbc558;
}

.choosed-presents .topbar__timer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.4035087719%;
  flex: 0 0 41.4035087719%;
}

.timer-topbar__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.375rem;
}

.timer-topbar__section {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(25% - 1.03125rem);
  flex: 0 0 calc(25% - 1.03125rem);
}

.timer-topbar__section-number {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 1.3333333333;
  text-align: center;
  color: var(--text-secondary);
  width: 100%;
  height: 3.1875rem;
  background-color: #f8af1d;
  border-radius: 0.3125rem 0.3125rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.timer-topbar__section-name {
  font-weight: bold;
  font-size: 0.875rem;
  text-align: center;
  color: var(--text-secondary);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.5rem;
  background-color: #fbc558;
  border-radius: 0 0 0.3125rem 0.3125rem;
  -webkit-box-shadow: 0 0.4375rem 0.3125rem rgba(0, 0, 0, 0.16);
  box-shadow: 0 0.4375rem 0.3125rem rgba(0, 0, 0, 0.16);
}

.main-choosed-presents {
  padding-top: 2.5rem;
  padding-bottom: 6.25rem;
}

.main-choosed-presents__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}

.main-choosed-presents__header:not(:last-child) {
  margin-bottom: 3.75rem;
}

.main-choosed-presents__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.9375rem;
}

.main-choosed-presents__nav-item:first-child .main-choosed-presents__nav-button::after {
  background-color: var(--green);
}

.main-choosed-presents__nav-item:first-child .main-choosed-presents__nav-button.active .icon > * * {
  fill: var(--green);
  stroke: var(--green);
}

.main-choosed-presents__nav-item:nth-child(2) .main-choosed-presents__nav-button::after {
  background-color: var(--orange);
}

.main-choosed-presents__nav-item:nth-child(2) .main-choosed-presents__nav-button.active .icon > * * {
  fill: var(--orange);
  stroke: var(--orange);
}

.main-choosed-presents__nav-button {
  font-weight: 600;
  font-size: 1.25rem;
  padding: 0.3125rem 0.1875rem;
  color: var(--text-gray);
  position: relative;
  -webkit-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  transition: color 0.2s ease 0s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.4375rem;
}

.main-choosed-presents__nav-button .icon {
  width: 1.125rem;
  height: 1rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.125rem;
  flex: 0 0 1.125rem;
}

.main-choosed-presents__nav-button .icon > * {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main-choosed-presents__nav-button .icon > * * {
  stroke: var(--icon-light-gray);
  -webkit-transition: fill 0.3s ease;
  -o-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.main-choosed-presents__nav-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.125rem;
  bottom: -0.625rem;
  right: 0;
  opacity: 0;
}

.main-choosed-presents__nav-button.active {
  color: var(--text-primary);
}

.main-choosed-presents__nav-button.active::after {
  opacity: 1;
}

.main-choosed-presents__header-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
}

.main-choosed-presents__header-buttons > * {
  width: 17.75rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 17.75rem;
  flex: 0 0 17.75rem;
  font-size: 1.25rem;
}

.main-choosed-presents__header-buttons > *::after {
  left: 0.875rem;
}

.main-choosed-presents__header-buttons > *:active {
  background-color: var(--text-green);
}

.main-choosed-presents__steps:not(:last-child) {
  margin-bottom: 2.8125rem;
}

.main-choosed-presents__title {
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.2285714286;
  text-align: center;
  color: var(--text-green);
}

.main-choosed-presents__title:not(:last-child) {
  margin-bottom: 2.6875rem;
}

.main-choosed-presents__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 2.1875rem;
  row-gap: 3.4375rem;
}

.main-choosed-presents__list-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.33333% - 1.458333125rem);
  flex: 0 0 calc(33.33333% - 1.458333125rem);
  width: calc(33.33333% - 1.458333125rem);
}

.main-choosed-presents .single-products-card__buttons {
  height: 100%;
}

.main-choosed-presents .single-products-card__buttons .heart {
  margin-bottom: 0;
  margin-top: auto;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  z-index: 1000;
  display: none;
}

.bottom-menu__body {
  position: relative;
  z-index: 2;
}

.menu__body .menu__item {
  padding-bottom: 1rem;
}

.bottom-menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bottom-menu__item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.bottom-menu__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0rem;
}

.bottom-menu__item a[data-count] {
  position: relative;
}

.bottom-menu__item a[data-count]::before {
  content: attr(data-count);
  position: absolute;
  width: 1.125rem;
  height: 1.125rem;
  top: 35%;
  right: 37%;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  z-index: 2;
  border-radius: 50%;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--green);
  border: 0.0625rem solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom-menu__item a .icon {
  height: 1.75rem;
  width: auto;
  margin: 0 auto;
  display: block;
}

.bottom-menu__item a .icon > * {
  height: 100%;
  width: auto;
}

.bottom-menu__item a .text {
  font-size: 0.9375rem;
  line-height: 1.6;
  font-weight: 600;
  color: var(--text-dark-gray);
}

.bottom-menu__item a.active {
  background-color: var(--main-pink);
}

.bottom-menu__item a.active .text {
  color: var(--text-secondary);
}

.bottom-menu__item_money a .icon {
  margin-left: 0;
  margin-right: auto;
}

.bottom-menu__item_money a[data-count]::before {
  width: auto;
  padding: 0 0.3125rem;
  border-radius: 0.3125rem;
}

.bottom-menu.bottom-menu-with-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% + 8.5625rem);
  bottom: 0;
  right: 0;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(65%, #fff), to(rgba(255, 255, 255, 0)));
  background: -o-linear-gradient(bottom, #fff 65%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to top, #fff 65%, rgba(255, 255, 255, 0) 100%);
}

.bottom-menu__top-link {
  position: absolute;
  max-width: 20rem;
  width: 100%;
  right: 50%;
  bottom: calc(100% + 1.5625rem);
  z-index: 2;
  -webkit-transform: translate(50%);
  -ms-transform: translate(50%);
  transform: translate(50%);
}

.filter {
  position: fixed;
  width: 100%;
  max-width: 20.8125rem;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}
.filter::-webkit-scrollbar {
  width: 0.5rem;
}
.filter::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 0.25rem 0 0 0.25rem;
}
.filter::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.25rem;
  background-color: var(--main-pink);
}

.filter {
  -webkit-transition: -webkit-transform 0.5s ease 0s;
  transition: -webkit-transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}

.filter-open .filter {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.filter__body {
  width: 100%;
  /* max-width: 20.8125rem; */
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  right: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.16);
  padding: 1rem 1.25rem;
}

.filter__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
}

.filter__header:not(:last-child) {
  margin-bottom: 1.25rem;
}

.filter__clear {
  font-size: 0.875rem;
  line-height: 1.2857142857;
}

.filter__title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.3;
  -webkit-transform: translateY(0.9375rem);
  -ms-transform: translateY(0.9375rem);
  transform: translateY(0.9375rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.3125rem;
}

.filter__title .icon {
  width: 1.1875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.1875rem;
  flex: 0 0 1.1875rem;
}

.filter__title .icon > * {
  width: 100%;
  height: auto;
}

.filter__close {
  width: 1.125rem;
  height: 1.125rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.125rem;
  flex: 0 0 1.125rem;
  position: relative;
  display: block;
}

.filter__close::before,
.filter__close::after {
  content: "";
  position: absolute;
  width: 77.7777777778%;
  height: 77.7777777778%;
  top: 50%;
  left: 50%;
  height: 0.0625rem;
  background-color: #000;
}

.filter__close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.filter__close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.filter__options-item[open] .filter__options-title .arrow {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.filter__options-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.125rem;
  padding-left: 0.375rem;
  line-height: 1.6666666667;
  text-align: right;
  color: var(--text-pink);
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #c7c9d2;
}

.filter__options-title .arrow {
  width: 0.875rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.875rem;
  flex: 0 0 0.875rem;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.filter__options-title .arrow > * {
  width: 100%;
  height: auto;
}

.filter__options-body {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.filter__option-categories-item:not(:last-child) {
  margin-bottom: 0.625rem;
}

.filter__option-categories-item[open] .filter__option-categories-title .arrow {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.filter__option-categories-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.6875rem;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
  border-radius: 0.625rem;
  cursor: pointer;
  padding-left: 0.375rem;
}

.filter__option-categories-title .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.625rem;
  height: 2.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2.625rem;
  flex: 0 0 2.625rem;
  border-radius: 0.625rem;
  background-color: #f55882;
  -webkit-transition: background-color 0.3s ease 0s;
  -o-transition: background-color 0.3s ease 0s;
  transition: background-color 0.3s ease 0s;
}

.filter__option-categories-title .icon > * {
  width: 59.5238095238%;
  height: 75%;
}

.filter__option-categories-title .text {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-transition: color 0.3s ease 0s;
  -o-transition: color 0.3s ease 0s;
  transition: color 0.3s ease 0s;
}

.filter__option-categories-title .arrow {
  width: 0.5625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.5625rem;
  flex: 0 0 0.5625rem;
}

.filter__option-categories-title .arrow > * {
  width: 100%;
  height: auto;
}

.filter__option-categories-title .arrow > * path {
  -webkit-transition: stroke 0.3s ease 0s;
  -o-transition: stroke 0.3s ease 0s;
  transition: stroke 0.3s ease 0s;
}

.filter__option-categories-body {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.filter .checkbox {
  padding-right: 3.4375rem;
}

.filter .checkbox:not(:last-child) {
  margin-bottom: 0.625rem;
}

.filter .checkbox__input:checked + .checkbox__label::before {
  background-color: var(--main-pink);
}

.filter .checkbox__label {
  gap: 0.625rem;
}

.filter .checkbox__label::after {
  display: none;
}

.filter .checkbox__text {
  color: var(--text-primary) !important;
  font-size: 1.125rem;
  line-height: 1.1666666667;
  font-weight: 400;
}

.filter__range-wrapper {
  direction: ltr;
  /* padding-top: 0.625rem; */
  overflow: hidden;
  margin: 0 -1rem;
  padding: 3.5rem 2rem 0.625rem;
}

.filter__range.noUi-target {
  height: 0.125rem;
  background-color: #61b045;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.filter__range .noUi-connect {
  background-color: #fff;
}

.filter__range .noUi-connect::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  top: 0.0625rem;
  -webkit-transform: translateY(-0.0625rem);
  -ms-transform: translateY(-0.0625rem);
  transform: translateY(-0.0625rem);
  left: 0;
  background-color: var(--main-pink);
}

.filter__range .noUi-handle {
  width: 0.8125rem !important;
  height: 0.8125rem !important;
  cursor: w-resize;
  border: none;
  border-radius: 50%;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--main-pink);
  right: 0 !important;
  top: 50% !important;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.filter__range .noUi-handle::before,
.filter__range .noUi-handle::after {
  display: none;
}

.filter__range-values {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.filter__range-values span {
  font-size: 0.7142857143em;
}

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  -webkit-transform-style: flat;
  transform-style: flat;
}

/* Offset direction
 */

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */

.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */

.noUi-target {
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  -webkit-box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

/* Handles and cursors;
 */

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  -webkit-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  -webkit-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

/* Handle stripes;
 */

.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */

.noUi-pips,
.noUi-pips * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */

.noUi-marker {
  position: absolute;
  background: #ccc;
}

.noUi-marker-sub {
  background: #aaa;
}

.noUi-marker-large {
  background: #aaa;
}

/* Horizontal layout;
 *
 */

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  -ms-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.swiper {
  overflow: hidden;
}

.swiper-initialized {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.swiper-vertical .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.swiper-initialized .swiper-slide {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.swiper-android .swiper-slide,
.swiper-android .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}

.buttons-mobile-wrapper {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 100;
}

.buttons-mobile-wrapper .btn.half-width {
  width: 50vw;
  border-radius: 0;
}
.buttons-mobile-wrapper .pink {
  border-radius: 0;
  background-color: #ec1b53;
  width: 100vw;
  padding-left: 2em;
}
.buttons-mobile-wrapper .pink.half-width {
  padding-left: 1.2em;
}
.buttons-mobile-wrapper .pink.half-width::after {
  left: 0.5em;
}
.buttons-mobile-wrapper .btn-white.half-width.left {
  border-top-left-radius: 1.5rem;
}
.buttons-mobile-wrapper .btn.half-width.right {
  border-top-right-radius: 1.5rem;
}
.btn-mobile-white {
  padding-left: 2em;
  /* border-top-right-radius:0; */
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  /* border-radius: 0; */
  width: 100%;
}
.btn-mobile-white span {
  margin-left: 1em;
  display: block;
}
.create-event-popup.credentials-page .btn-pink{
  background-color: var(--text-pink);
}

.create-event-popup.credentials-page .btn-pink{
  font-size: 1.3vw;
}

@media (min-width: 47.99875em) {
  .blessing-write-title .credentials-section__content {
    padding-left: 0;
    padding-right: 0;
  }

  .create-event-popup.credentials-page{
    position: fixed;
    top: -8.1vh;
    left: 29.7vw;
    /* transform: translate(-50%,-50%); */
    width: max-content;
    z-index: 1000;
  }  
  .create-event-popup.credentials-page .btn-pink{
    min-width: 9em;
  }

  .create-event-popup.credentials-page .credentials-section__bg-image-ibg{
    max-height: 41.5vh;
    height: 23.3vw;
  }
  .create-event-popup.credentials-page .credentials-section__content{
    padding-top: 24.1vw;
    border-radius: 0 0 3.6em 3.6em;
  }

  .create-event-popup.credentials-page .credentials-section__title{
    line-height: 1.15;
  }

  .create-event-popup.credentials-page .credentials-section__subtitle{
    line-height: 1.4;
  }
  .create-event-popup.credentials-page .credentials-section__content{
    flex: none;
  }
  .create-event-popup .credentials-section__content {
    padding: 3vh 2.25vw 4.2vw;

}
  .create-event-popup .credentials-section__form-wrapper {
    padding: 0 8.5vw;
}
  .create-event-popup .credentials-section__subtitle:not(:last-child){
    margin-bottom: 0.8vw;
  }
  .create-event-popup .credentials-section__content{
    padding-bottom: 4vw;
  }
  .create-event-popup .credentials-section .input-wrapper:not(:last-child){
    margin-bottom: 0;
  }
  
  .create-event-popup .form-credentials-section__buttons-container{
    max-width: 13vw;
  }
  .create-event-popup.create-event .form-edit-profile__birthday:not(:last-child){
    margin-bottom: 2.6vw;
  }
  
  .create-event-popup .credentials-section__form.form-credentials-section{
    max-width: 20vw;
    width: 100%;
  }
  .create-event-popup .birthday-field__birthday-title.form-title{
    position: relative;
    top: 0.45vw;
  }
  

  .create-event-popup.credentials-page .credentials-section__title{
    font-size: 2.7vw;
  }
  .create-event-popup .credentials-section__content-window {
    left: 0;
    max-width: 39em;
    /* min-height: 65vh; */
    max-height: 51.5vw;
    border-radius: 1.5em 1.5em 3.6em 3.6em;
    overflow: hidden;
  }
  .create-event-popup .credentials-section__bg-image-ibg {
  
  }
  .create-event-popup .credentials-section__subtitle{
  font-size: 1.4vw;
  }

  .main-products__list-wrapper {
    overflow: visible !important;
  }
  .main-gift-basket__container.desktop {
    display: block;
  }

  .products-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-column-gap: 2.1875vw;
    -moz-column-gap: 2.1875vw;
    column-gap: 2.1875vw;
    row-gap: 2.7604166vw;
  }

  .products-list__item {
    height: auto !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.33333% - 1.458333vw);
    flex: 0 0 calc(33.33333% - 1.458333vw);
  }
  .four-columns .products-list__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - 1.125vw);
    flex: 0 0 calc(25% - 1.125vw);
    width: calc(25% - 1.125vw);
  }
  .four-columns.main-products__list {
    column-gap: 1.5vw;
  }
  .four-columns .single-products-card:hover .single-products-card__link {
    bottom: 12%;
  }

  .single-products-card__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .single-products-card__text-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .products-list__item {
    margin: 0 !important;
  }

  .buttons-mobile-wrapper {
    display: none;
  }
}
@media (min-width:120em){
  .create-event-popup.credentials-page .credentials-section__title{
    font-size: 2.7vw;
  }
  .create-event-popup .credentials-section__subtitle:not(:last-child){
    /* margin-bottom: 0.6vh; */
  }
  .create-event-popup.credentials-page .credentials-section__content{
    padding-top: 42.5vh;
    padding-bottom: 7vh;
  }
  .credentials-section__title:not(:last-child){
    margin-bottom: 0;
  }
  .credentials-section__subtitle{
    line-height: 1.4;
  }
  .create-event-popup.credentials-page .credentials-section .input{
    font-size: 1vw;
  }
  .create-event-popup .credentials-section__content{

  }
  .create-event-popup .credentials-section__content{
    padding:3vh 2.25vw 2.2vw;
  }

  .create-event-popup .credentials-section__form-wrapper{
    padding: 0 8em;
  }
  .create-event-popup.create-event .form-edit-profile__birthday:not(:last-child){
    margin-bottom: 3.6vh;
  }
  .create-event-popup .form-credentials-section__buttons-container{
    max-width: 13em;
  }
}

@media(max-height: 770px){
  .create-event-popup .credentials-section__content {
    padding: 3vh 2.25vw 2.2vw;
}
.create-event-popup.credentials-page .credentials-section__content{
  padding-top: 22.6vw;
}
}

@media(max-width:48em){
  .main-my-bank__price-value{
    font-size: 2.5em;
  }
}

@media(min-width: 48em) and (max-width:65em){

  .create-event-popup.credentials-page{
    left: 50%;
    transform: translateX(-50%);
  }
  .create-event-popup .credentials-section__form.form-credentials-section{
    /* max-width: 19vw; */
  }
  .create-event-popup .credentials-section__content-window{
    max-width: 65vw;
  }
  .create-event-popup .form-credentials-section__buttons-container{
    max-width: 30vw;
  }
}

.create-event-popup{
  position: relative;
  z-index: 1000;
}
@media (max-width: 120em) {
  [class*="__container"] {
    max-width: 64.583333vw;
  }

  [class*="__container"] {
    padding: 0 2.604166666vw;
  }

  .topbar {
    padding: 0.9375vw 0;
  }

  .topbar__title {
    font-size: 2.3438vw;
    padding: 0.5208vw 6.25vw;
  }

  .topbar__title .icon {
    width: 3.9063vw;
  }

  .breadcrumbs {
    padding: 0.4167vw 0;
  }

  .breadcrumbs__item a,
  .breadcrumbs__item p {
    font-size: 0.625vw;
    padding-left: 0.8854vw;
  }

  .breadcrumbs__item a::after {
    width: 0.7292vw;
  }

  .side-menu {
    left: 1.0416666667vw;
  }

  .side-menu__body {
    border-radius: 1.796875vw;
    -webkit-box-shadow: 0.2083333333vw 0.2083333333vw 1.4583333333vw rgba(0, 0, 0, 0.16);
    box-shadow: 0.2083333333vw 0.2083333333vw 1.4583333333vw rgba(0, 0, 0, 0.16);
    padding: 1.1979166667vw 0.5208333333vw 0.9375vw;
  }

  .side-menu__item:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .side-menu__item a {
    width: 2.03125vw;
    height: 2.03125vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.03125vw;
    flex: 0 0 2.03125vw;
  }

  .side-menu__item a[data-count]::before {
    width: 1.09375vw;
    height: 1.09375vw;
    border-radius: 2.6041666667vw;
    font-size: 0.7291666667vw;
    border: 0.0520833333vw solid #fff;
  }

  main:not(:first-child) {
    padding-top: 8.125vw;
  }

  .header {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    -webkit-filter: drop-shadow(0 0.260416666vw 2.03125vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0.260416666vw 2.03125vw rgba(0, 0, 0, 0.16));
  }

  .header__desktop {
    padding-top: 0.8854166667vw;
  }
  .menu__body .menu__item {
    padding-bottom: 0.8333333333vw;
  }

  .header__logo {
    width: 8.6458333333vw;
    display: block;
    margin: auto;
    margin-top: -1.0416666667vw;
  }

  .header__logo:not(:last-child) {
    margin-bottom: 1.3020833333vw;
  }

  .header__logo > * {
    width: 100%;
    height: auto;
  }

  .top-header__container {
    max-width: 100vw;
  }

  .top-header__search {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 9.375vw;
    flex: 0 1 9.375vw;
  }

  .search-form__input-wrapper .search-input {
    font-size: 0.8333vw;
    padding: 0.1042vw;
    border-bottom: 0.0521vw solid var(--gray);
  }

  .search-form__input-wrapper .search-button {
    left: 0.1563vw;
    width: 0.5729vw;
    height: 0.5729vw;
  }

  .menu-top-header__list {
    gap: 1.0417vw;
  }

  .menu-top-header__link .image {
    width: 1.25vw;
    height: 1.25vw;
  }

  .menu-top-header__link .image:not(:last-child) {
    margin-bottom: 0.1563vw;
  }

  .menu-top-header__link .image[data-count]::before {
    width: 0.7292vw;
    height: 0.7292vw;
    font-size: 0.4167vw;
    border: 0.0521vw solid #fff;
  }

  .menu-top-header__link .text {
    font-size: 0.5208vw;
    font-weight: 600;
  }

  .menu__link {
    font-size: 1.0417vw;
  }

  .menu__link .icon {
    width: 1.5625vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.5625vw;
    flex: 0 0 1.5625vw;
    height: 1.1979vw;
  }

  .menu__link.dropdown .dropdown__body {
    padding-top: 0.7813vw;
    min-width: 10.4167vw;
  }

  .menu__link.dropdown .dropdown__link {
    font-size: 1.0417vw;
    padding: 0.5208vw 1.4583vw;
  }

  .menu__link.dropdown .dropdown__link:not(:last-child) {
    border-bottom: 0.0521vw solid var(--main-pink);
  }

  .footer {
    padding-bottom: 1.5625vw;
  }

  .footer__body {
    padding-top: 5.2083vw;
    gap: 1.5625vw;
  }

  .footer__body:not(:last-child) {
    margin-bottom: 3.125vw;
  }

  .footer__col:nth-child(2) .footer__links-item:not(:last-child) {
    margin-bottom: 0.3646vw;
  }

  .footer__col_big {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6667vw;
    flex: 0 0 16.6667vw;
  }

  .footer__col_socials {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 12.0833vw;
    flex: 0 1 12.0833vw;
  }

  .footer__title {
    font-size: 0.9375vw;
  }

  .footer__title:not(:last-child) {
    margin-bottom: 0.2604vw;
  }

  .footer__link {
    font-size: 0.8333vw;
  }

  .footer__payments-list {
    gap: 1.0417vw;
  }

  .footer__payments-list-item > * {
    width: 2.1875vw;
    height: 2.1875vw;
  }

  .footer__icon-links-block:not(:last-child) {
    margin-bottom: 2.3438vw;
  }

  .footer__link-icon {
    gap: 0.8333vw;
    font-size: 0.8333vw;
  }

  .footer__link-icon:not(:last-child) {
    margin-bottom: 0.4688vw;
  }

  .footer__link-icon .icon {
    width: 1.1458vw;
    height: 1.1458vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.1458vw;
    flex: 0 0 1.1458vw;
  }

  .footer__socials-list {
    gap: 0.2604vw;
  }

  .footer__socials-link {
    width: 2.5vw;
    height: 2.5vw;
  }

  .footer__bottom {
    gap: 0.7813vw;
  }

  .footer__copyright,
  .footer__power-by {
    font-size: 0.8333vw;
  }

  .top-footer {
    padding: 1.875vw 0;
  }

  .top-footer__body {
    gap: 1.5625vw;
  }

  .top-footer__title {
    font-size: 1.5625vw;
  }

  .top-footer__subtitle {
    font-size: 1.0417vw;
  }

  .top-footer__form-block {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33.8542vw;
    flex: 0 1 33.8542vw;
  }

  .top-footer__input-wrapper input {
    height: 3.125vw;
    font-size: 0.9375vw;
    padding-right: 0.9375vw;
    padding-left: 6.7708vw;
    border-radius: 0.52083333vw;
  }

  .top-footer__input-wrapper .btn {
    font-size: 0.9375vw;
    width: 6.0417vw;
    border-radius: 0.52083333vw;
  }

  .steps-modal__body {
    padding: 1.666666vw;
  }

  .steps-modal__content {
    border-radius: 1.25vw;
    max-width: 32.71vw;
  }

  .steps-modal__image-ibg img {
    border-radius: 1.25vw;
  }

  .steps-modal__text-content {
    padding-bottom: 1.4584vw;
  }

  .steps-modal__pagination {
    gap: 0.375vw;
  }

  .steps-modal__pagination .swiper-pagination-bullet {
    width: 0.4168vw;
    height: 0.4168vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.4168vw;
    flex: 0 0 0.4168vw;
  }

  .steps-modal__skip-container {
    margin-top: 0.5vw;
  }

  .steps-modal__skip {
    font-size: 0.8336vw;
  }

  .steps-modal .btn {
    padding: 0.29168vw 0.625vw;
    font-size: 0.9168vw;
    border-radius: 1vw;
  }

  .steps-modal .btn::after {
    left: 0.8336vw;
    width: 0.4168vw;
    height: 0.8336vw;
  }

  .start-step-content {
    padding-top: 1.0416vw;
    padding-bottom: 1.0416vw;
    max-width: 18.75vw;
  }

  .start-step-content__title {
    font-size: 1.544vw;
  }

  .start-step-content__title:not(:last-child) {
    margin-bottom: 0.5416vw;
  }

  .start-step-content__content {
    font-size: 1.125vw;
  }

  .start-step-content__content:not(:last-child) {
    margin-bottom: 0.584vw;
  }

  .start-step-content__subtitle {
    font-size: 1.4168vw;
  }

  .start-step-content__subtitle:not(:last-child) {
    margin-bottom: 1.666666vw;
  }

  .start-step-content__btn-container {
    max-width: 13.75vw;
  }

  .step-content {
    max-width: 13.33333vw;
    padding-top: 1.042vw;
    padding-bottom: 1.042vw;
  }

  .swiper-slide:nth-child(5) .step-content {
    max-width: 15vw;
  }

  .step-content__body {
    gap: 0.708vw;
  }

  .step-content__number-text .number {
    font-size: 6.5vw;
  }

  .step-content__number-text .text {
    font-size: 1.125vw;
  }

  .step-content__title {
    font-size: 1vw;
  }

  .swiper-slide:nth-child(4) .step-content__title {
    font-size: 0.875vw;
  }

  .final-step-content {
    max-width: 13.75vw;
    padding-top: 1.04168vw;
    padding-bottom: 1.04168vw;
  }

  .final-step-content__title {
    font-size: 1.25vw;
  }

  .final-step-content__title:not(:last-child) {
    margin-bottom: 0.125vw;
  }

  .final-step-content__subtitle {
    font-size: 0.875vw;
  }

  .final-step-content__subtitle:not(:last-child) {
    margin-bottom: 1.4584vw;
  }

  .final-step-content__image {
    max-width: 5.66666vw;
    margin-bottom: 2em;
  }

  .steps-modal.yellow .start-step-content {
    max-width: 14vw;
  }

  .steps-modal.yellow .start-step-content__title {
    font-size: 1.2vw;
  }

  .steps-modal.yellow .start-step-content__subtitle {
    font-size: 1.2vw;
  }

  .steps-modal.yellow .final-step-content__image {
    max-width: 7vw;
  }

  .gift-basket__topbar .topbar__title {
    padding: 0.520833vw 6.77083333vw;
  }

  .gift-basket__topbar .topbar__title .icon {
    width: 6.5625vw;
  }

  .main-gift-basket {
    padding-bottom: 10.41666vw;
  }

  .main-gift-basket__navigation:not(:last-child) {
    margin-bottom: 1.302083333vw;
  }

  .main-gift-basket__nav-list {
    gap: 2.083333vw;
  }

  .main-gift-basket__header:not(:last-child) {
    margin-bottom: 1.5625vw;
  }
}

@media (max-width: 80em) {
  .steps-modal__body {
    padding: 2.08325vw;
  }

  .steps-modal__content {
    border-radius: 1.5625vw;
    max-width: 40.885vw;
  }

  .steps-modal__image-ibg img {
    border-radius: 1.5625vw;
  }

  .steps-modal__text-content {
    padding-bottom: 1.823vw;
  }

  .steps-modal__pagination {
    gap: 0.46875vw;
  }

  .steps-modal__pagination .swiper-pagination-bullet {
    width: 0.521vw;
    height: 0.521vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.521vw;
    flex: 0 0 0.521vw;
  }

  .steps-modal__skip-container {
    margin-top: 0.625vw;
  }

  .steps-modal__skip {
    font-size: 1.042vw;
  }

  .steps-modal .btn {
    padding: 0.3646vw 0.78125vw;
    font-size: 1.146vw;
    border-radius: 1.25vw;
  }

  .steps-modal .btn::after {
    left: 1.042vw;
    width: 0.521vw;
    height: 1.042vw;
  }

  .start-step-content {
    padding-top: 1.302vw;
    padding-bottom: 1.302vw;
    max-width: 23.4375vw;
  }

  .start-step-content__title {
    font-size: 1.93vw;
  }

  .start-step-content__title:not(:last-child) {
    margin-bottom: 0.677vw;
  }

  .start-step-content__content {
    font-size: 1.40625vw;
  }

  .start-step-content__content:not(:last-child) {
    margin-bottom: 0.73vw;
  }

  .start-step-content__subtitle {
    font-size: 1.771vw;
  }

  .start-step-content__subtitle:not(:last-child) {
    margin-bottom: 1.458333vw;
  }

  .start-step-content__btn-container {
    max-width: 17.1875vw;
  }

  .step-content {
    max-width: 16.666666vw;
    padding-top: 1.3021vw;
    padding-bottom: 1.3021vw;
  }

  .swiper-slide:nth-child(5) .step-content {
    max-width: 18.75vw;
  }

  .step-content__body {
    gap: 0.885vw;
  }

  .step-content__number-text .number {
    font-size: 9.1667vw;
  }

  .step-content__number-text .text {
    font-size: 1.40625vw;
  }

  .step-content__title {
    font-size: 1.25vw;
  }

  .swiper-slide:nth-child(4) .step-content__title {
    font-size: 1.09375vw;
  }

  .final-step-content {
    max-width: 17.1875vw;
    padding-top: 1.3021vw;
    padding-bottom: 1.3021vw;
  }

  .final-step-content__title {
    font-size: 1.5625vw;
  }

  .final-step-content__title:not(:last-child) {
    margin-bottom: 0.15625vw;
  }

  .final-step-content__subtitle {
    font-size: 1.09375vw;
  }

  .final-step-content__subtitle:not(:last-child) {
    margin-bottom: 1.823vw;
  }

  .final-step-content__image {
    max-width: 7.083333vw;
  }

  .steps-modal.yellow .start-step-content {
    max-width: 23.4375vw;
  }

  .steps-modal.yellow .start-step-content__title {
    font-size: 1.93vw;
  }

  .steps-modal.yellow .start-step-content__subtitle {
    font-size: 1.771vw;
  }

  .steps-modal.yellow .final-step-content__image {
    max-width: 8vw;
  }
}

@media (max-width: 25em) {
  .bottom-menu__item a {
    padding: 2vw 2vw 0rem;
  }

  .bottom-menu__item a .icon {
    height: 7vw;
  }

  .bottom-menu__item a .text {
    font-size: 3.75vw;
  }
}

@media (max-width: 61.99875em) {
  .steps-modal__body {
    padding: 3.124875vw;
  }

  .steps-modal__content {
    border-radius: 2.34375vw;
    max-width: 61.3275vw;
  }

  .steps-modal__image-ibg img {
    border-radius: 2.34375vw;
  }

  .steps-modal__text-content {
    padding-bottom: 2.7345vw;
  }

  .steps-modal__pagination {
    gap: 0.703125vw;
  }

  .steps-modal__pagination .swiper-pagination-bullet {
    width: 0.7815vw;
    height: 0.7815vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.7815vw;
    flex: 0 0 0.7815vw;
  }

  .steps-modal__skip-container {
    margin-top: 0.9375vw;
  }

  .steps-modal__skip {
    font-size: 1.563vw;
  }

  .steps-modal .btn {
    padding: 0.5469vw 1.171875vw;
    font-size: 1.719vw;
    border-radius: 1.875vw;
  }

  .steps-modal .btn::after {
    left: 1.563vw;
    width: 0.7815vw;
    height: 1.563vw;
  }

  .start-step-content {
    padding-top: 1.953vw;
    padding-bottom: 1.953vw;
    max-width: 35.15625vw;
  }

  .start-step-content__title {
    font-size: 2.895vw;
  }

  .start-step-content__title:not(:last-child) {
    margin-bottom: 1.1055vw;
  }

  .start-step-content__content {
    font-size: 2.109375vw;
  }

  .start-step-content__content:not(:last-child) {
    margin-bottom: 1.095vw;
  }

  .start-step-content__subtitle {
    font-size: 2.6565vw;
  }

  .start-step-content__subtitle:not(:last-child) {
    margin-bottom: 2.1874995vw;
  }

  .start-step-content__btn-container {
    max-width: 25.78125vw;
  }

  .step-content {
    max-width: 25vw;
    padding-top: 1.95315vw;
    padding-bottom: 1.95315vw;
  }

  .swiper-slide:nth-child(5) .step-content {
    max-width: 28.125vw;
  }

  .step-content__body {
    gap: 1.3275vw;
  }

  .step-content__number-text .number {
    font-size: 13.75vw;
  }

  .step-content__number-text .text {
    font-size: 2.109375vw;
  }

  .step-content__title {
    font-size: 1.875vw;
  }

  .swiper-slide:nth-child(4) .step-content__title {
    font-size: 1.640625vw;
  }

  .final-step-content {
    max-width: 25.78125vw;
    padding-top: 1.95315vw;
    padding-bottom: 1.95315vw;
  }

  .final-step-content__title {
    font-size: 2.34375vw;
  }

  .final-step-content__title:not(:last-child) {
    margin-bottom: 0.234375vw;
  }

  .final-step-content__subtitle {
    font-size: 1.640625vw;
  }

  .final-step-content__subtitle:not(:last-child) {
    margin-bottom: 2.7345vw;
  }

  .final-step-content__image {
    max-width: 10.6249995vw;
  }

  .steps-modal.yellow .start-step-content {
    max-width: 35.15625vw;
  }

  .steps-modal.yellow .start-step-content__title {
    font-size: 2.895vw;
  }

  .steps-modal.yellow .start-step-content__subtitle {
    font-size: 2.6565vw;
  }

  .steps-modal.yellow .final-step-content__image {
    max-width: 10.6249995vw;
  }

  .contact-us-form__input-wrapper:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .contact-us-form__tel-wrapper:not(:last-child) {
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 47.99875em) {
  [class*="__container"] {
    max-width: 100%;
  }

  [class*="__container"] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .popup__content {
    border-radius: 2.1875rem;
  }

  .popup__bg-ibg img {
    border-radius: 2.1875rem;
  }

  #already-bought-popup .popup__content {
    padding: 3.75rem 1.875rem;
  }

  #already-bought-popup .popup__list {
    height: calc(100 * var(--vh) - 16.25rem);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100 * var(--vh) - 16.25rem);
    flex: 0 0 calc(100 * var(--vh) - 16.25rem);
  }

  .topbar {
    padding: 1.125rem 0;
  }

  .topbar__title {
    font-size: 2rem;
    padding: 0.625rem 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    gap: 0.625rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .topbar__title .icon {
    position: relative;
    top: unset;
    left: unset;
    height: 4.125rem;
    width: 4.125rem;
  }

  .breadcrumbs {
    display: none;
  }

  .side-menu {
    display: none;
  }

  main:not(:first-child) {
    padding-top: 0;
  }

  .header {
    display: none;
  }

  .header-mobile {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 101;
    background-color: transparent;
  }

  .header-mobile::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
    opacity: 0;
    z-index: 101;
    pointer-events: none;
  }

  .menu-open .header-mobile::before {
    opacity: 1;
  }

  .icon-menu {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: block;
    position: relative;
    width: 3.375rem;
    height: 1.5625rem;
    cursor: pointer;
    z-index: 103;
  }

  .icon-menu span,
  .icon-menu::before,
  .icon-menu::after {
    content: "";
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    right: 0;
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    border-radius: 0.3125rem;
    background-color: #fff;
  }

  .icon-menu::after {
    width: 80%;
  }

  .icon-menu::before {
    top: 0;
  }

  .icon-menu::after {
    bottom: 0;
  }

  .icon-menu span {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .menu-open .icon-menu span {
    width: 0;
  }

  .menu-open .icon-menu::before,
  .menu-open .icon-menu::after {
    background-color: #000;
  }

  .menu-open .icon-menu::before {
    top: 50%;
    -webkit-transform: rotate(-45deg) translate(0, -50%);
    -ms-transform: rotate(-45deg) translate(0, -50%);
    transform: rotate(-45deg) translate(0, -50%);
  }

  .menu-open .icon-menu::after {
    width: 100%;
    bottom: 50%;
    -webkit-transform: rotate(45deg) translate(0, 50%);
    -ms-transform: rotate(45deg) translate(0, 50%);
    transform: rotate(45deg) translate(0, 50%);
  }

  .footer {
    display: none;
  }

  .home-page .credentials-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .home-page .credentials-section__content {
    padding: 1.25rem 1.625rem 1.5625rem;
  }

  .home-page .credentials-section__bg-image-ibg {
    top: 0;
  }

  .home-page__logo {
    max-width: 8.75rem;
  }

  .home-page__logo:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .home-page__links-list {
    gap: 1rem;
    width: 100%;
  }

  .home-page__links-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3333% - 0.66666625rem);
    flex: 0 0 calc(33.3333% - 0.66666625rem);
    width: calc(33.3333% - 0.66666625rem);
    padding-bottom: calc(33.3333% - 0.66666625rem);
    position: relative;
  }

  .home-page__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 5%;
    border-radius: 0.9375rem;
    border: 0.1875rem solid #fff;
    -webkit-box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.1607843137);
  }

  .home-page__link .icon {
    width: 2.1875rem;
    height: 2.6875rem;
  }

  .home-page__link .icon:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .home-page__link .text {
    font-size: 1.125rem;
  }

  .main-choose-event {
    padding-bottom: 5.625rem;
  }

  .main-choose-event__header {
    display: none;
  }

  .main-choose-event__steps-numbers {
    display: none;
  }

  .main-choose-event__body {
    padding-top: 1.25rem;
  }

  .main-choose-event__list {
    gap: 1.875rem;
  }

  .main-choose-event__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 0.9375rem);
    flex: 0 0 calc(50% - 0.9375rem);
  }

  .my-event-single__content {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .my-event-single__image-ibg {
    margin-left: 1.5625rem;
  }

  .my-event-single__text-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0.375rem;
    position: relative;
  }

  .my-event-single__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 1.75rem;
  }

  .my-event-single__date {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .my-event-single__actions .button-edit {
    position: absolute;
    top: 0.5rem;
    left: 0;
  }

  .single-history-item__content {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .single-history-item__image-ibg {
    margin-left: 1.5625rem;
  }

  .single-history-item__text-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0.375rem;
    position: relative;
  }

  .single-history-item__text-content > * {
    width: 100%;
  }

  .single-history-item__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .single-history-item__date {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .single-history-item__actions {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .single-books-item__content {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .single-books-item__image-ibg {
    margin-left: 1.5625rem;
  }

  .single-books-item__text-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0.375rem;
    position: relative;
  }

  .single-books-item__text-content > * {
    width: 100%;
  }

  .single-books-item__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 1.75rem;
  }

  .single-books-item__date {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .single-books-item__actions {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    flex: 0 0 auto;
  }

  .single-books-item__actions .arrow {
    position: absolute;
    top: 0.5rem;
    left: 0;
  }

  .single-books-item__dropdown-body {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .single-books-item__dropdown-table {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 1.3125rem 1.5625rem;
  }

  .single-books-item__dropdown-table-column {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }

  .gift-basket__topbar .topbar__title {
    width: 100%;
    padding: 0;
    font-size: 1.6875rem;
  }

  .gift-basket__topbar .topbar__title .icon {
    width: 7.5rem;
    height: 6.0625rem;
  }

  .gift-basket__bottom-menu {
    display: block;
  }

  .main-gift-basket {
    padding-bottom: 5.625rem;
  }

  .main-gift-basket__navigation:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .main-gift-basket__nav-list {
    gap: 1.875rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main-gift-basket__header {
    display: none;
  }

  .main-gift-basket__my-purchases-item:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .my-purchase-single__content {
    overflow: hidden;
    padding: 0 0 0 0.8125rem;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .my-purchase-single__image-ibg {
    height: unset;
  }

  .my-purchase-single__text-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    padding: 0.9375rem 0;
    padding-right: 0.625rem;
  }

  .my-purchase-single__name {
    padding-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: var(--text-primary);
  }

  .my-purchase-single__price {
    font-size: 2rem;
    color: var(--text-pink);
  }

  .my-purchase-single__price:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .my-purchase-single__actions {
    width: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 0.3125rem;
  }

  .my-purchase-single__actions .status {
    position: absolute;
    top: 0.3125rem;
    left: 0;
  }

  .main-blessing-created__content-window {
    max-width: 28.125rem;
    width: 90%;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    padding: 3.125rem 1.25rem 1.875rem;
  }

  .main-blessing-created__title {
    font-size: 3.125rem;
  }

  .main-blessing-created__subtitle {
    font-size: 1.6875rem;
  }

  .main-blessing-created__btn-container .btn-white {
    font-size: 1.375rem;
  }

  .main-blessing-created__checkbox .checkbox__text {
    font-size: 1.125rem;
  }

  .main-blessing-created__checkbox-subtext {
    font-size: 1rem;
  }

  .main-event-created__content-window {
    max-width: 28.125rem;
    width: 90%;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    padding: 3.125rem 1.25rem 1.875rem;
  }

  .main-event-created__title {
    font-size: 1.6875rem;
    max-width: 100%;
  }

  .main-event-created__title:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .main-event-created__logo {
    width: 100%;
    max-width: 14.375rem;
  }

  .main-event-created__logo:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  .main-event-created .btn-container_center .btn {
    font-size: 1.375rem;
  }

  .main-event-created .btn-container_center .btn-white {
    font-size: 1.375rem;
  }

  .credentials-section {
    min-height: calc(100 * var(--vh));
    padding: 0;
    display: block;
  }

  .credentials-section__bg-image-ibg {
    top: 30%;
  }

  .credentials-section__bg-image-ibg img {
    -o-object-position: 40% center;
    object-position: 40% center;
  }
  .home-page .credentials-section__bg-image-ibg img {
    -o-object-position: 80% center;
    object-position: 80% center;
  }

  .credentials-section__content-window {
    gap: 0;
    top: 0;
    min-height: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
  }

  .credentials-section__steps-numbers {
    display: none;
  }

  .credentials-section__content {
    padding: 0 2.5rem 5.625rem;
  }

  .credentials-section__prev-btn {
    display: none;
  }

  .credentials-section__title {
    font-size: 1.6875rem;
    background: url("../img/common/topbar-bg.png") center/cover no-repeat;
    color: var(--text-secondary) !important;
    padding-top: 3.125rem;
    padding-bottom: 0.625rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .credentials-section__title:not(:last-child) {
    margin-bottom: 0;
  }

  .green-mobile .credentials-section__title {
    background: url("../img/common/topbar-bg-green.png") center/cover no-repeat;
  }

  .credentials-section__title_icon-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .credentials-section__title .mobile-icon {
    display: block;
    width: 6.9375rem;
    height: 6.9375rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.9375rem;
    flex: 0 0 6.9375rem;
  }

  .credentials-section__title .mobile-icon > * {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .credentials-section__subtitle {
    font-size: 1.5625rem;
    margin-top: 1.25rem;
    font-weight: 700;
    line-height: 1.08;
    color: var(--text-pink);
  }

  .credentials-section__subtitle:not(:last-child) {
    margin-bottom: 0.3125rem;
  }

  .credentials-section__small-subtitle {
    font-size: 1.25rem;
  }

  .credentials-section__text.text-link:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .credentials-section__text {
    font-size: 1.125rem;
  }

  .credentials-section__text:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .credentials-section__facebook-link {
    gap: 0.3125rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .credentials-section__facebook-link:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .credentials-section__facebook-link > svg,
  .credentials-section__facebook-link img {
    width: 1.5rem;
    height: 0.875rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.5rem;
    flex: 0 0 1.5rem;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .credentials-section__large-image-ibg {
    display: none;
  }

  .credentials-section__table-block:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .credentials-section__table {
    padding: 0.75rem 1.5625rem;
  }

  .credentials-section__table:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .credentials-section__table-item:not(:last-child) {
    margin-bottom: 0.1875rem;
  }

  .credentials-section__table-item-name {
    font-size: 1.25rem;
  }

  .credentials-section__table-item-value {
    font-size: 1.25rem;
  }

  .credentials-section__under-table-text {
    font-size: 1.25rem;
  }

  .credentials-section__bottom-buttons .btn,
  .credentials-section__bottom-buttons .btn-outline-white {
    font-size: 1.375rem;
    width: 9.875rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 9.875rem;
    flex: 0 1 9.875rem;
    padding: 0.4375rem 0.9375rem;
  }

  /* .credentials-section__bottom-buttons {
    display: none;
  } */

  .credentials-section__progress {
    display: none;
  }

  .credentials-section__footer-mobile {
    display: block;
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 1.875rem 1.875rem 0 0;
    padding: 0.9375rem 1.25rem 5rem;
  }

  .credentials-section__form-wrapper {
    overflow: unset;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .credentials-section__form-wrapper:not(:last-child) {
    margin-bottom: 1.25rem !important;
  }

  .credentials-section .form-title {
    font-size: 1.125rem;
  }

  .credentials-section .form-title:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .credentials-section .input-title {
    right: 0.75rem;
    font-size: 1.125rem;
    top: 0.625rem;
    padding: 0.0625rem 0.4375rem 0.25rem 0.5625rem;
  }

  .credentials-section .input {
    padding: 0.5rem 0.9375rem;
    font-size: 1.25rem;
    border-radius: 0.5rem !important;
  }

  .credentials-section .input-wrapper {
    padding-top: 0.625rem;
  }

  .credentials-section .input-wrapper:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .credentials-section .birthday-field__birthday-body .birthday-field__input-wrapper:not(:last-child)::after {
    font-size: 1.5625rem;
    left: -1.25rem;
  }

  .credentials-section .mail-wrapper:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .credentials-section .tel-wrapper::after {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.875rem;
  }

  .credentials-section .tel-wrapper:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .credentials-section .event-info:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .credentials-section .checkbox .checkbox__label {
    gap: 0.4375rem;
  }

  .credentials-section .checkbox .checkbox__label::before {
    width: 1.375rem;
    height: 1.375rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.375rem;
    flex: 0 0 1.375rem;
    border-radius: 0.4375rem;
  }

  .credentials-section .checkbox .checkbox__label::after {
    width: 0.75rem;
    height: 0.625rem;
    top: 0.375rem;
    right: 0.3125rem;
  }

  .credentials-section .checkbox .checkbox__text {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .credentials-section .checks-title {
    margin-top: 1.25rem;
  }

  .credentials-section .card-info:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .credentials-section .choose-payment:not(:last-child) {
    margin-bottom: 0.375rem;
  }

  .credentials-section .long-checkbox:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .credentials-section .long-checkbox__label {
    padding: 0.5rem 0.9375rem 0.5rem 0.9375rem;
  }

  .credentials-section .long-checkbox__label::after {
    content: "";
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.375rem;
    flex: 0 0 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    border: 0.0625rem solid #d9d6d6;
    border-radius: 0.25rem;
  }

  .credentials-section .long-checkbox__text {
    font-size: 1.125rem;
  }

  .credentials-section .long-checkbox__text > img,
  .credentials-section .long-checkbox__text > svg {
    width: 7.125rem;
    height: 2.25rem;
  }

  .credentials-section .long-checkbox__text a {
    color: var(--text-green);
  }

  .credentials-section .long-checkbox__radios {
    padding: 0.5rem 0.9375rem;
    border-radius: 0.625rem;
  }

  .credentials-section .long-checkbox__radios > p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.375rem;
    flex: 0 0 8.375rem;
    font-size: 1.125rem;
  }

  .credentials-section .long-checkbox__radios .long-checkbox__label {
    gap: 0.3125rem;
  }

  .credentials-section .long-checkbox_with-content {
    padding: 0.5rem 0.9375rem;
    border-radius: 0.625rem;
  }

  .credentials-section .long-checkbox_with-content .long-checkbox__buttons {
    margin-top: 1.25rem;
    gap: 0.8125rem;
  }

  .credentials-section .long-checkbox_with-content .long-checkbox__buttons > * {
    font-size: 1.125rem;
    padding: 0.15625rem 0.3125rem 0.15625rem 1.25rem;
    border-radius: 0.3125rem;
  }

  .credentials-section .long-checkbox_with-content .long-checkbox__buttons > *::after {
    left: 0.5rem;
    width: 0.625rem;
    height: 0.9375rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .image {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: 1.25rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .image > *:not(.loupe-btn) {
    border-radius: 0.9375rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .image .loupe-btn {
    left: -0.3125rem;
    bottom: 0.25rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.5rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .text {
    font-size: 1.125rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .text:not(:last-child) {
    margin-bottom: 0.3125rem;
  }

  .credentials-section .long-checkbox_with-image .long-checkbox__text .text-content .price {
    font-size: 2rem;
  }

  .credentials-section__decor {
    display: none;
  }

  .credentials-section__text-scroll {
    height: auto;
    margin-top: 1.25rem;
    font-size: 1.125rem;
    padding-left: 0;
    overflow: visible;
  }

  .credentials-section__text-scroll:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .blessing-form .credentials-section__content {
    padding: 0 1.5625rem 5.625rem;
    min-height: calc(100 * var(--vh));
    height: auto;
    border-radius: 0;
  }

  .blessing-form .credentials-section__form-wrapper {
    padding: 0 0 1.25rem;
  }
  .credentials-page.blessing-page .credentials-section__bottom-buttons,
  .blessing-form .credentials-section__bottom-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    margin-bottom: 0;
    padding: 0;
    gap: 0;
  }
  .blessing-form .credentials-section__bottom-buttons .btn {
    max-width: 100vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100vw;
    flex: 0 1 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* border-radius: 0; */
    /* max-width: 20rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 20rem;
    flex: 0 1 20rem; */
  }
  .credentials-page.blessing-page .credentials-section__bottom-buttons .btn,
  .credentials-page.blessing-page .credentials-section__bottom-buttons .btn-outline-white {
    width: 50vw;
    flex: 0 1 50vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .credentials-page.blessing-page .credentials-section__bottom-buttons .btn {
    border-top-right-radius: 0;
  }
  .credentials-page.blessing-page .credentials-section__bottom-buttons .btn-outline-white {
    border-top-left-radius: 0;
  }

  .blessing-form .credentials-section__bottom-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .credentials-page_form .credentials-section__content {
    min-height: calc(100 * var(--vh));
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    border-radius: 0;
  }

  .credentials-page_form .credentials-section__bg-image-ibg {
    display: none;
  }

  .form-credentials-section__select-wrapper {
    padding-top: 0.625rem;
  }

  .form-credentials-section__select-wrapper:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .form-credentials-section__select-wrapper::after {
    top: calc(50% + 0.3125rem);
    left: 1rem;
    width: 0.75rem;
    height: 0.5rem;
  }

  .form-credentials-section__small-icon {
    width: 1.875rem;
  }

  .form-credentials-section__small-icon:not(:last-child) {
    margin-bottom: 0.8125rem;
  }

  .form-credentials-section__check:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .form-credentials-section__buttons-container:not(.resend) {
    max-width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .form-credentials-section__buttons-container .btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 1.7vh;
    padding-bottom: 1.7vh;
  }
  .form-credentials-section__buttons-container.send-money {
    display: flex;
  }
  .form-credentials-section__buttons-container.send-money button {
    width: 50vw;
    margin-bottom: 0 !important;
  }
  .form-credentials-section__buttons-container.send-money .btn {
    border-top-right-radius: 0;
  }
  .form-credentials-section__buttons-container.send-money .btn-orange {
    border-top-left-radius: 0;
  }
  .form-credentials-section__buttons-container.send-money .btn,
  .form-credentials-section__buttons-container.send-money .btn-orange {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-credentials-section__buttons-container > * {
    font-size: 1.375rem;
    padding: 0.4375rem 0.9375rem;
  }

  .form-credentials-section__buttons-container > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .form-credentials-section__buttons-container > *::after {
    content: "";
    left: 1.25rem;
    width: 0.625rem;
    height: 1.25rem;
  }

  .form-credentials-section__checks {
    max-width: 16.25rem;
  }

  .form-credentials-section__checks:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .form-credentials-section__text-under-checks {
    font-size: 1.1875rem;
  }

  .form-credentials-section__red-link {
    font-size: 1.25rem;
  }

  .form-credentials-section__code-wrapper {
    padding: 0 0.9375rem;
  }

  .form-credentials-section__code-wrapper:not(:last-child) {
    margin-bottom: 5rem;
  }

  .form-credentials-section__code {
    max-width: 21.25rem;
    margin: 0 auto;
  }

  .form-credentials-section__code-input-wrapper .code-input {
    font-size: 2rem;
    padding: 0.625rem;
  }

  .form-credentials-section__how-much-wrapper {
    gap: 0.5625rem;
  }

  .form-credentials-section__how-much-wrapper .how-much-input {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 17.125rem;
    flex: 0 1 17.125rem;
    max-width: 17.125rem;
    font-size: 1.5rem;
    padding: 0.3125rem 0.5rem;
  }

  .form-credentials-section__how-much-wrapper .money-icon {
    font-size: 1.875rem;
  }

  .products.new-event-type .topbar__btn.continue {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .choose-login-or-register .credentials-section__form-wrapper {
    padding-top: 1.25rem;
  }

  .another-blessing-form .credentials-section__title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .another-blessing-form .credentials-section__title .mobile-icon {
    margin-right: -1.5625rem;
  }

  .another-blessing-form .form-credentials-section__check .checkbox__input:checked ~ .remember {
    margin-bottom: 0.875rem;
  }

  .choose-application .form-credentials-section__checks {
    margin-top: 1.25rem;
  }

  .choose-application .form-credentials-section__text-under-checks:not(:last-child) {
    margin-bottom: 2.0625rem;
  }

  .choose-application .form-credentials-section__buttons-container {
    max-width: 100vw;
    width: 100vw;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* max-width: 23.125rem; */
  }
  .choose-application .form-credentials-section__buttons-container .btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* max-width: 23.125rem; */
  }

  .choose-application .form-credentials-section__buttons-container [class*="btn"] {
    font-size: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .choose-application .form-credentials-section__buttons-container [class*="btn"]::after {
    content: "";
    left: 0.625rem;
  }

  .main-event__btn-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
  }
  .main-event__btn-wrapper .main-event-created__btn-container.btn-container_center {
    width: 50vw;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .main-event__btn-wrapper .main-event-created__btn-container.btn-container_center .btn {
    border-top-left-radius: 0;
  }
  .main-event__btn-wrapper .main-event-created__btn-container.btn-container_center .btn-white {
    border-top-right-radius: 0;
  }
  .main-event__btn-wrapper .main-event-created__btn-container.btn-container_center .btn,
  .main-event__btn-wrapper .main-event-created__btn-container.btn-container_center .btn-white {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .payment-page .credentials-section__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .payment-page .credentials-section__subtitle {
    font-size: 1.25rem;
    color: var(--text-primary);
  }

  .payment-page .form-credentials-section__text-under-checks {
    font-size: 1rem;
  }

  .payment-page .form-credentials-section .choose-payment__body:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .payment-page .form-credentials-section__buttons-container {
    position: fixed;
    bottom: 0;
    z-index: 10;
    right: 0;
    width: 100%;
    max-width: 100%;
    -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  }

  .payment-page .form-credentials-section__buttons-container [class*="btn"] {
    border-radius: 0.75rem 0.75rem 0 0;
    padding-right: 2.5rem;
  }

  .payment-page .form-credentials-section__buttons-container [class*="btn"]::before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 34%;
    width: 9.4444444444%;
    height: 83.3333333333%;
    background: url("../img/svg/money-hand-whte.svg") center/contain no-repeat;
    z-index: 2;
  }

  .login-with .credentials-section__form-wrapper {
    padding-top: 0.625rem;
  }

  .login-with .form-credentials-section__buttons-container:not(.resend) {
    max-width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .login-with .form-credentials-section__buttons-container .btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .login-with_sms .form-credentials-section__red-link {
    margin-top: 5rem;
  }

  .login-with_sms .form-credentials-section {
    text-align: center;
  }

  .login-with .credentials-section__text {
    font-size: 1.25rem;
    margin-top: 1.125rem;
    font-weight: 700;
  }

  .credentials-page_nav-buttons .credentials-section__content {
    margin-top: 0;
  }

  .credentials-page_nav-buttons .credentials-section__top-nav-buttons {
    display: none;
  }

  .register .form-credentials-section__check .checkbox__text {
    font-size: 1.125rem;
  }

  .register .form-credentials-section__check:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .register .credentials-section__text {
    display: none;
  }

  .register-user-details-form .form-credentials-section__check .checkbox__text {
    font-size: 1.125rem;
  }

  .register-user-details-form .credentials-section__title:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .terms-form .form-credentials-section {
    max-width: 20rem;
    margin: 0 auto;
  }

  .terms-form .form-credentials-section__check {
    text-align: right;
  }

  .how-much .credentials-section__title {
    padding-top: 5.625rem;
  }

  .how-much .form-credentials-section__how-much-wrapper {
    margin-top: 3.125rem;
  }

  .how-much .form-credentials-section__how-much-wrapper:not(:last-child) {
    margin-bottom: 3.125rem;
  }

  .how-much .form-credentials-section__buttons-container [class*="btn"]:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .shipping-type.credentials-page_form .credentials-section__content {
    min-height: calc(100 * var(--vh));
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    border-radius: 0;
  }

  .shipping-type .credentials-section__content-window {
    right: 0;
    left: auto;
  }

  .shipping-type .form-credentials-section__buttons-container {
    margin-top: 1.25rem;
  }

  .shipping-type .credentials-section__subtitle {
    color: var(--text-primary);
    font-size: 1.25rem;
  }

  .shipping-type .credentials-section__subtitle:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .shipping-type .long-checkbox.desktop {
    display: none;
  }

  .shipping-type .form-credentials-section__buttons-container {
    position: fixed;
    bottom: 0;
    z-index: 10;
    right: 0;
    width: 100%;
    max-width: 100%;
    -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  }

  .shipping-type .form-credentials-section__buttons-container [class*="btn"] {
    border-radius: 0.75rem 0.75rem 0 0;
    padding-right: 2.5rem;
  }

  .shipping-type .form-credentials-section__buttons-container [class*="btn"]::before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 34%;
    width: 9.4444444444%;
    height: 83.3333333333%;
    background: url("../img/svg/money-hand-whte.svg") center/contain no-repeat;
    z-index: 2;
  }

  .after-register {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .after-register .credentials-section {
    padding-top: 5.625rem;
  }

  .after-register .credentials-section__title {
    margin-top: 0;
    font-size: 2.1875rem;
    font-weight: 700;
    background: none !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    position: static;
    color: var(--text-secondary) !important;
    display: block;
  }

  .after-register .credentials-section__title:not(:last-child) {
    margin-bottom: 0.9375rem;
  }

  .after-register .credentials-section__image-icon {
    max-width: 14.125rem;
  }

  .after-register .credentials-section__image-icon:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .after-register .credentials-section__logo {
    max-width: 11.9375rem;
  }

  .after-register .credentials-section__logo:not(:last-child) {
    margin-bottom: 2.375rem;
  }

  .after-register .form-credentials-section__buttons-container > .mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .after-register .form-credentials-section__buttons-container {
    max-width: 20.625rem;
    margin: 0 auto;
  }

  .after-register .form-credentials-section__buttons-container:not(:last-child) {
    margin-bottom: 0rem;
  }

  .after-register .form-credentials-section__buttons-container .btn {
    background-color: var(--main-pink);
    font-size: 1.3125rem;
  }

  .after-register .form-credentials-section__buttons-container .btn .icon {
    display: none;
  }

  .after-register .form-credentials-section__buttons-container .btn::after {
    content: "";
    left: 0.9375rem;
  }

  .after-register .form-credentials-section__buttons-container .btn-white .icon {
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    top: 50%;
    right: 0.9375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .after-register .form-credentials-section__buttons-container .btn-white .icon > * {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .after-register .credentials-section__content {
    background-color: transparent;
    min-height: calc(100 * var(--vh));
  }

  .after-register .credentials-section__bg-image-ibg {
    display: none;
  }

  .create-event .form-edit-profile__birthday:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .create-event .credentials-section__subtitle {
    color: var(--text-primary);
    font-size: 1.25rem;
    margin-top: 1.875rem;
  }

  .create-event .credentials-section__subtitle:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .create-event .credentials-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .create-event .credentials-section__content {
    min-height: 0;
    height: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .create-event .credentials-section__bg-image-ibg {
    display: block;
    top: 0;
    height: calc(100% - 27.375rem);
  }

  .create-event .credentials-section__title {
    padding: 0.3125rem 0;
    background: rgba(236, 27, 83, 0.6);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    margin-bottom: -2.8125rem;
  }

  .money-present .long-checkbox__text.flex {
    gap: 0.625rem;
  }

  .money-present .long-checkbox__text.flex .icon {
    width: 2.8125rem;
    height: 2.8125rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.8125rem;
    flex: 0 0 2.8125rem;
  }

  .money-present .long-checkbox__text a.btn {
    font-size: 1.125rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 5.3125rem;
    flex: 0 0 5.3125rem;
    width: 5.3125rem;
    border-radius: 0.3125rem;
    padding: 0.3125rem 0.1875rem 0.3125rem 0.8125rem;
    margin-left: 1.25rem;
  }

  .money-present .long-checkbox__text a.btn::after {
    left: 0.5rem;
    width: 0.625rem;
    height: 0.625rem;
  }

  .money-present .credentials-section__content-block {
    padding-top: 1.25rem;
    height: auto;
    overflow: initial;
    padding-left: 0;
    padding-bottom: 0;
  }

  .money-present .credentials-section__content-block:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .blessing-write-title .credentials-section__title:not(:last-child) {
    margin-bottom: 1.875rem;
    padding-top: 5.625rem;
  }

  .blessing-write-title .form-credentials-section__form-body {
    display: block;
  }

  .blessing-write-title .form-credentials-section__top-block,
  .blessing-write-title .form-credentials-section__bottom-block {
    padding: 0;
  }

  .blessing-write-title .form-credentials-section__top-block {
    -webkit-filter: none;
    filter: none;
    padding: 0;
    border-radius: 0;
  }

  .blessing-write-title .form-credentials-section__top-block:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .blessing-write-title .form-credentials-section__bottom-block {
    -webkit-filter: none;
    filter: none;
    padding: 0;
    border-radius: 0;
  }

  .blessing-write-title .form-credentials-section__big-image-ibg {
    display: none;
  }

  .blessing-write-title .form-credentials-section .input-wrapper textarea {
    height: 6.25rem;
  }

  .main-contact-us__form-block:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .contact-us-form__body:not(:last-child) {
    margin-bottom: 4.375rem;
  }

  .contact-us-form__body {
    gap: 1.125rem;
  }

  .contact-us-form__col {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
  }

  .contact-us-form__input-wrapper:not(:last-child) {
    margin-bottom: 1.125rem;
  }

  .contact-us-form__input-wrapper textarea.input {
    height: 14.6875rem;
  }

  .contact-us-form__tel-wrapper:not(:last-child) {
    margin-bottom: 1.125rem;
  }

  .main-terms-conditions {
    padding-top: 2.8125rem;
  }

  .main-terms-conditions__content {
    max-width: 28.125rem;
    margin: auto;
  }

  .main-terms-conditions__content:not(:last-child) {
    margin-bottom: 3.75rem;
  }

  .main-terms-conditions__submit-block {
    max-width: 28.125rem;
    margin: auto;
    position: fixed;
    bottom: 5rem;
    right: 50%;
    -webkit-transform: translate(50%);
    -ms-transform: translate(50%);
    transform: translate(50%);
    width: 100%;
  }

  .main-terms-conditions__submit-block > * {
    position: relative;
    z-index: 2;
  }

  .main-terms-conditions__submit-block .btn-container {
    text-align: center;
  }

  .main-terms-conditions__submit-block::after {
    content: "";
    position: absolute;
    bottom: -2.5rem;
    right: 0;
    width: 100%;
    height: 200%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(80%, #fff), to(rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(bottom, #fff 80%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to top, #fff 80%, rgba(255, 255, 255, 0) 100%);
  }

  .category .topbar .topbar__bg-img-ibg img {
    -o-object-position: 78% center;
    object-position: 78% center;
  }

  .category .topbar .topbar__container {
    padding: 0;
  }

  .category .topbar .topbar__title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 18.125rem;
  }

  .category .topbar .topbar__title {
    margin-left: 0;
    font-size: 1.6875rem;
    padding: 0.625rem 0.9375rem;
    min-width: 100%;
    background-color: rgba(236, 27, 83, 0.6);
  }

  .main-category__steps-numbers {
    display: none;
  }

  .main-category__filter-btn-container {
    display: none;
  }

  .main-category__title:not(:last-child) {
    margin-bottom: 2.1875rem;
  }

  .main-category__list {
    row-gap: 2rem;
  }

  .main-category__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .single-category-type__content {
    -webkit-filter: none;
    filter: none;
    -webkit-box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1607843137);
  }

  .single-category-type__image-ibg {
    display: none;
  }

  .single-category-type__text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .single-category-type__icon {
    width: 3.8125rem;
    height: 3.8125rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 3.8125rem;
    flex: 0 0 3.8125rem;
    margin-top: 0;
  }

  .single-category-type__icon:not(:last-child) {
    margin-bottom: 0;
  }

  .single-category-type__title {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    color: var(--text-primary);
    margin-bottom: 0 !important;
    padding-left: 2.1875rem;
    position: relative;
  }

  .single-category-type__title::before {
    content: "";
    position: absolute;
    width: 0.875rem;
    height: 2rem;
    top: 50%;
    left: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: -webkit-transform 0.3s ease 0s;
    transition: -webkit-transform 0.3s ease 0s;
    -o-transition: transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    background: url("../img/svg/arrow-left-pink.svg") center/contain no-repeat;
  }

  .single-category-type__content.active .single-category-type__title::before {
    -webkit-transform: translateY(-50%) rotate(-90deg);
    -ms-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);
  }

  .single-category-type__text {
    display: none;
  }

  .single-category-type__btn-container {
    display: none;
  }

  .single-category-type__footer {
    border: none;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .single-category-type__dropdown {
    width: 100%;
    margin: 0 0 0 0;
  }

  .single-category-type__dropdown-btn {
    display: none;
  }

  .single-category-type__dropdown-body {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-filter: none;
    filter: none;
    position: static;
    width: auto;
    opacity: 1;
    height: 0;
    padding: 0;
    overflow: hidden;
    margin-right: 1.875rem;
    position: relative;
  }

  .single-category-type__dropdown-body::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0.0625rem;
    height: calc(100% - 2.1875rem);
    background-color: #8f8f8f;
  }

  .single-category-type__content.active .single-category-type__dropdown-body {
    height: auto;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
  }

  .single-category-type__dropdown-list {
    max-width: 100%;
  }

  .single-category-type__dropdown-list-item {
    font-size: 1.3125rem;
    gap: 1.25rem;
    font-weight: 600;
    padding-right: 1.875rem;
    position: relative;
  }

  .single-category-type__dropdown-list-item:not(:last-child) {
    margin-bottom: 0.3125rem;
  }

  .single-category-type__dropdown-list-item::before {
    width: 1.375rem;
    height: 1.375rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.375rem;
    flex: 0 0 1.375rem;
    border-radius: 0.375rem;
  }

  .single-category-type__dropdown-list-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 1.875rem;
    height: 0.0625rem;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-color: #8f8f8f;
  }

  .event-add-presents__topbar .topbar__title .icon {
    bottom: 0;
  }

  .main-event-add-presents {
    padding-bottom: 5.625rem;
  }

  .single-event-presents__btn-container {
    margin-top: 0.625rem;
  }

  .edit-profile .topbar__title {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .main-edit-profile {
    padding-top: 3.75rem;
    padding-bottom: 9.375rem;
  }

  .main-edit-profile__title {
    display: none;
  }

  .birthday-field {
    margin-top: 0rem;
  }

  .birthday-field {
    margin-top: 0rem;
  }

  .form-edit-profile__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .form-edit-profile__col-title {
    margin-top: 0;
  }

  .form-edit-profile__col-title:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .form-edit-profile__address-details {
    margin-top: 2.5rem;
  }

  .form-edit-profile__mail {
    margin-top: 1.25rem;
  }

  .form-edit-profile__checkbox {
    margin-top: 0.625rem;
  }

  .products .topbar .topbar__bg-img-ibg img {
    -o-object-position: 78% center;
    object-position: 78% center;
  }

  .products .topbar .topbar__container {
    padding: 0;
  }

  .products .topbar .topbar__title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 18.125rem;
  }

  .products .topbar .topbar__title {
    margin-left: 0;
    font-size: 1.6875rem;
    padding: 0.625rem 0.9375rem;
    min-width: 100%;
    background-color: rgba(236, 27, 83, 0.6);
    color: var(--text-secondary);
  }

  .main-products_related {
    display: none;
  }

  .main-products_recent {
    display: none;
  }

  .main-products__filter-btn-container {
    display: none;
  }
  .main-products__filter-btn-container.mobile {
    display: block;
    margin-bottom: 1em;
    margin-right: 0.9375rem;
  }

  .main-products__steps {
    display: none;
  }

  .main-products__pretitle {
    display: none;
  }

  .main-products__title {
    display: none;
  }

  .main-products__view-type {
    display: none;
  }

  .main-products__mobile-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .main-products__list-wrapper {
    padding: 2.5rem;
    margin-left: -1.25rem;
    position: relative;
    margin-right: -1.25rem;
  }

  .main-products__list-wrapper.list-view .main-products__list-nav {
    display: none;
  }

  .main-products__list-wrapper.list-view .products-list {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    gap: 0.6875rem !important;
  }

  .main-products__list-wrapper.list-view .products-list__item {
    width: 100% !important;
  }

  .main-products__list-wrapper.list-view .single-products-card__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.6875rem;
  }

  .main-products__list-wrapper.list-view .single-products-card__image-ibg {
    width: 8rem;
    height: 8rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8rem;
    flex: 0 0 8rem;
    padding-bottom: 0;
  }

  .main-products__list-wrapper.list-view .single-products-card__image-ibg::after {
    display: none;
  }

  .main-products__list-wrapper.list-view .single-products-card__image-ibg img {
    border-radius: 0.75rem;
  }

  .main-products__list-wrapper.list-view .single-products-card__text-content {
    padding: 0;
    background-color: transparent;
  }

  .main-products__list-wrapper.list-view .single-products-card__rating {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .main-products__list-wrapper.list-view .single-products-card__title {
    margin-bottom: 0 !important;
  }

  .main-products__list-wrapper__list-nav {
    display: block;
  }

  .single-products-card {
    position: relative;
  }

  .single-products-card__buttons {
    display: none;
  }

  .single-products-card__buttons .button:not(:last-child) {
    margin-bottom: 0;
  }

  .single-products-card__buttons_mobile {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .single-products-card__text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem 1.25rem;
  }

  .single-products-card__title {
    font-size: 1.6875rem;
    text-align: right;
    display: block;
  }

  .single-products-card__title::after {
    content: "";
    display: none;
  }

  .single-products-card__rating {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 5.625rem;
  }

  .single-products-card__rating:not(:last-child) {
    margin-bottom: 0rem;
  }

  .single-products-card__text {
    display: none;
  }

  .single-products-card__price {
    margin-bottom: 0.375rem;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 5.625rem;
  }

  .single-products-card__price .new {
    font-size: 2.25rem;
    color: var(--text-pink);
  }

  .main-single-product__body {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .main-single-product__gallery-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .main-single-product__content-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .main-single-product__title {
    font-size: 2.125rem;
  }

  .main-single-product__bottom-ratings {
    gap: 1.875rem;
  }

  .form-main-single-product__quantity-wrapper:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .quantity {
    width: 8.75rem;
    height: 2.75rem;
  }

  .my-bank__topbar {
    display: none;
  }

  .my-bank .main-products__list-wrapper {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .my-bank .main-products__list-wrapper .main-products__list {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .main-my-bank {
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    text-align: center;
  }

  .main-my-bank .main-my-bank__btn-container {
    /* position: fixed;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    width: 100vw;
    z-index: 1; */
  }
  .main-my-bank .main-my-bank__btn-container .btn {
    /* margin-bottom: 0;
    border-top-right-radius:0; */
  }
  .main-my-bank .main-my-bank__btn-container .btn-white {
    border-top-left-radius: 0;
  }
  .main-my-bank .main-my-bank__btn-container .btn-white.round {
    border-top-left-radius: 1.2em;
  }
  .main-my-bank .main-my-bank__btn-container > * {
    /* max-width: 50vw;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0; */
  }

  .main-my-bank__container.mobile {
    display: block;
    padding: 0;
  }

  .main-my-bank.desktop {
    display: none;
  }

  .main-reminders {
    padding-bottom: 5.625rem;
  }

  .main-reminders__header {
    display: none;
  }

  .main-reminders__item:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .single-reminder__content {
    padding: 0.9375rem;
  }

  .single-reminder__image-ibg {
    margin-left: 1.25rem;
  }

  .main-all-categories {
    padding-top: 0.9375rem;
    padding-bottom: 5.625rem;
  }

  .main-all-categories__steps-numbers {
    display: none;
  }

  .main-all-categories__titles-block:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .main-all-categories__list {
    gap: 1.875rem;
  }

  .main-all-categories__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 0.9375rem);
    flex: 0 0 calc(50% - 0.9375rem);
  }

  .main-successful-payment__content-window {
    max-width: 28.125rem;
    width: 90%;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    padding: 25vh 1.25rem 1.875rem;
  }

  .main-successful-payment__title {
    font-size: 3.125rem;
  }

  .main-successful-payment__subtitle {
    font-size: 1.6875rem;
  }

  .choosed-presents .topbar__body {
    gap: 2.5rem;
  }

  .choosed-presents .topbar__text-part {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 1.25rem);
    flex: 0 0 calc(50% - 1.25rem);
  }

  .choosed-presents .topbar__title {
    font-size: 1.625rem;
  }

  .choosed-presents .topbar__timer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 1.25rem);
    flex: 0 0 calc(50% - 1.25rem);
  }

  .main-choosed-presents {
    padding-bottom: 3.75rem;
  }

  .main-choosed-presents__header {
    display: none;
  }

  .main-choosed-presents__steps {
    display: none;
  }

  .main-choosed-presents__list {
    gap: 1.25rem;
    row-gap: 2.5rem;
  }

  .main-choosed-presents__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 0.625rem);
    flex: 0 0 calc(50% - 0.625rem);
    width: calc(50% - 0.625rem);
  }

  .bottom-menu {
    display: block;
  }

  .my-event-single__actions {
    flex: 0 0 auto;
  }
}

@media (max-width: 47.99875em) and (max-width: 22.5em) {
  .main-products__list-wrapper.list-view .single-products-card {
    padding-bottom: 1.875rem;
  }

  .main-products__list-wrapper.list-view .single-products-card__rating {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 37.5em) {
  .single-event-type__btn-container {
    display: none;
  }
  .btn.main-cat.desktop {
    display: none;
  }
  .steps-modal__body {
    padding: 0;
    display: block;
    min-height: 0;
  }

  .main-products__container.main-my-bank__container.desktop {
    display: none;
  }
  .main-my-bank__container.mobile .filter-btn-container {
    /* position: fixed;
    right: 0;
    top: 8vh;
    z-index: 1; */
  }

  .steps-modal__content {
    min-height: calc(var(--vh) * 100);
    height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    background-color: transparent;
  }

  .steps-modal__images-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .steps-modal__images-slider {
    min-height: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .steps-modal__image-ibg img {
    border-radius: 0;
  }

  .steps-modal__image-ibg {
    padding-bottom: 0;
    height: 100%;
  }

  .steps-modal__text-content {
    padding-bottom: 1.25rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #fff;
    border-radius: 1.25rem 1.25rem 0 0;
    margin-top: -1.875rem;
    position: relative;
    z-index: 2;
  }

  .steps-modal__pagination {
    gap: 0.5625rem;
  }

  .steps-modal__pagination .swiper-pagination-bullet {
    width: 0.625rem;
    height: 0.625rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.625rem;
    flex: 0 0 0.625rem;
  }

  .steps-modal__skip-container {
    margin-top: 0.75rem;
  }

  .steps-modal__skip {
    font-size: 1.25rem;
  }

  .steps-modal .btn {
    padding: 0.4375rem 0.9375rem;
    font-size: 1.375rem;
    border-radius: 1.5rem;
  }

  .steps-modal .btn::after {
    left: 1.25rem;
    width: 0.625rem;
    height: 1.25rem;
  }

  .start-step-content {
    max-width: 100%;
    padding: 2.5rem 1.25rem 1.875rem;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
  }

  .start-step-content__title {
    font-size: 1.6875rem;
  }

  .start-step-content__title:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .start-step-content__content {
    font-size: 1.25rem;
  }

  .start-step-content__content:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .start-step-content__subtitle {
    font-size: 1.5625rem;
  }

  .start-step-content__subtitle:not(:last-child) {
    margin-bottom: 1.0625rem;
  }

  .start-step-content__btn-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .step-content {
    max-width: 100%;
    padding: 2.5rem 2.5rem 1.875rem;
  }

  .swiper-slide:nth-child(5) .step-content {
    max-width: 100%;
  }

  .step-content__body {
    gap: 1.0625rem;
  }

  .step-content__number-text .number {
    font-size: 11rem;
    line-height: 1.2;
  }

  .step-content__number-text .text {
    font-size: 1.6875rem;
  }

  .step-content__title {
    font-size: 1.5rem;
  }

  .swiper-slide:nth-child(4) .step-content__title {
    font-size: 1.3125rem;
  }

  .final-step-content {
    max-width: 100%;
    padding: 2.5rem 1.25rem 1.875rem;
  }

  .final-step-content__title {
    font-size: 1.875rem;
  }

  .final-step-content__title:not(:last-child) {
    margin-bottom: 0.375rem;
  }

  .final-step-content__subtitle {
    font-size: 1.3125rem;
  }

  .final-step-content__subtitle:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  .final-step-content__image {
    max-width: 40%;
    margin: auto;
  }

  .steps-modal.yellow .start-step-content {
    max-width: 100%;
  }

  .steps-modal.yellow .start-step-content__title {
    font-size: 1.6875rem;
  }

  .steps-modal.yellow .start-step-content__subtitle {
    font-size: 1.5625rem;
  }

  .steps-modal.yellow .final-step-content__image {
    max-width: 40%;
    margin: auto;
  }

  .single-category-type__btn-container {
    display: none;
  }

  .choosed-presents .topbar__body {
    gap: 1.25rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .choosed-presents .topbar__text-part {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .choosed-presents .topbar__timer {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  /* .btn.main-cat{
    margin-bottom: 0;
    position: fixed;
    bottom:0;
    width: 100vw;
    left: 0;
  } */

  .create-event-popup{
    position: fixed;
    top: 0;
  }
  .create-event-popup.create-event .credentials-section{
    padding-bottom: 1em;
  }
  .create-event-popup.create-event .credentials-section__prev-btn{
    display: flex;
  }
}

@media (max-width: 29.99875em) {
  [class*="__container"] {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-top: 1em;
  }
  .single-products-card__text-content .star-svg {
    width: 18px;
    height: 18px;
  }
  .single-products-card__text-content {
    align-items: flex-start;
  }

  .popup {
    padding: 1.875rem 1.25rem;
  }

  .popup__content {
    min-height: 0;
    padding-top: 3.125rem;
  }

  .popup__close {
    top: 1.25rem;
    left: 1.25rem;
    width: 1.5625rem;
    height: 1.5625rem;
  }

  .popup__body {
    max-width: 16.875rem;
  }

  .popup__big-title {
    font-size: 2.625rem;
  }

  #blessing-goodbye-popup .popup__body {
    padding-top: 0;
  }

  #blessing-created-popup .popup__buttons-container > *:not(:last-child) {
    margin-bottom: 1.125rem;
  }

  #blessing-created-popup .popup__buttons-container {
    margin-top: 1.6875rem;
  }

  #blessing-created-reminder-popup .popup__buttons-container > *:not(:last-child) {
    margin-bottom: 1.125rem;
  }

  #blessing-created-reminder-popup .popup__buttons-container {
    margin-top: 1.6875rem;
  }

  #code-not-right-popup-pink .popup__title {
    margin-top: -0.625rem;
  }

  #we-send-you-sms-popup-green .popup__content {
    border-radius: 1.125rem;
    padding-bottom: 1.875rem;
  }

  #thanks-for-send-present-popup .popup__title {
    font-size: 1.5rem;
  }

  #thanks-for-send-present-popup .popup__title:first-child {
    font-size: 1.875rem;
  }

  #thanks-for-send-present-popup .popup__image-ibg_contain {
    width: 11.25rem;
    height: 12.25rem;
  }

  #thanks-for-send-money-popup .popup__title {
    font-size: 1.5rem;
  }

  #thanks-for-send-money-popup .popup__title:first-child {
    font-size: 1.875rem;
    margin-top: 0.9375rem;
  }

  #thanks-for-send-money-popup .popup__image-ibg_contain {
    width: 11.25rem;
    height: 12.25rem;
  }

  #event-created-green-popup .popup__body {
    padding-top: 0;
  }

  .popup_image-content .popup__top {
    padding: 3.125rem 1.25rem 1.875rem;
  }

  .popup_image-content .popup__image-ibg_contain {
    width: 11.25rem;
    height: 11.25rem;
  }

  .popup_image-content .popup__bottom {
    padding: 3.125rem 1.25rem 3.125rem;
  }

  .popup_image-content .popup__subtitle {
    font-size: 1.5rem;
  }

  .popup_image-content .popup__bold-text {
    font-size: 1.375rem;
  }

  .popup_image-content .popup__price {
    font-size: 3.75rem;
  }

  #already-bought-popup .popup__content {
    padding: 2.5rem 1.25rem;
  }

  #already-bought-popup .popup__list {
    height: calc(100 * var(--vh) - 13.75rem);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100 * var(--vh) - 13.75rem);
    flex: 0 0 calc(100 * var(--vh) - 13.75rem);
  }

  #already-bought-popup .popup__list-item {
    padding: 0.9375rem 0;
  }

  #already-bought-popup .popup__list-item .avatar {
    display: none;
  }

  #already-bought-popup .popup__list-item .name {
    margin-left: 0.625rem;
  }

  #already-bought-popup .popup__list-item .product {
    padding-left: 0.9375rem;
  }

  .topbar {
    padding-top: 4.6875rem;
  }

  .topbar__title {
    font-size: 1.5rem;
    padding: 0;
  }

  .single-event-type__content {
    overflow: visible;
    background-color: transparent;
    -webkit-filter: none;
    filter: none;
  }

  .single-event-type__image-ibg {
    padding-bottom: 100%;
  }

  .single-event-type__image-ibg img {
    border: 0.125rem solid #fff;
    -webkit-box-shadow: 0.625rem 0.625rem 1.875rem rgba(0, 0, 0, 0.2);
    box-shadow: 0.625rem 0.625rem 1.875rem rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
  }

  .single-event-type__text-content {
    padding-bottom: 0;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }

  .single-event-type__icon {
    display: none;
  }

  .single-event-type__title {
    margin-top: 0.625rem;
    font-size: 1.25rem;
  }

  .single-event-type__title:not(:last-child) {
    margin-bottom: 0;
  }

  .home-page__link .icon {
    width: 7.3vw;
    height: 8.96vw;
  }

  .home-page__link .text {
    font-size: 3.75vw;
  }

  .main-choose-event {
    padding-bottom: 3.75rem;
  }

  .main-choose-event__titles-block {
    display: none;
  }

  .main-choose-event__list {
    row-gap: 1.25rem;
  }

  .my-event-single__content {
    padding: 0.8125rem 0.9375rem 0.8125rem 0.9375rem;
  }

  .my-event-single__image-ibg {
    margin-left: 0.9375rem;
  }

  .my-event-single__actions {
    gap: 0.875rem;
  }

  .my-event-single .button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(16.66666% - 0.7291666625rem);
    flex: 0 0 calc(16.66666% - 0.7291666625rem);
    width: calc(16.66666% - 0.7291666625rem);
  }

  .single-history-item__content {
    padding: 0.8125rem 0.9375rem 0.8125rem 0.9375rem;
  }

  .single-history-item__image-ibg {
    margin-left: 0.9375rem;
  }

  .single-history-item__actions {
    gap: 0.875rem;
  }

  .single-books-item__content {
    padding: 0.8125rem 0.9375rem 0.8125rem 0.9375rem;
  }

  .single-books-item__image-ibg {
    margin-left: 0.9375rem;
  }

  .single-books-item__actions {
    gap: 0.875rem;
  }

  .single-books-item__dropdown-body {
    padding: 0.75rem 0.9375rem 0.9375rem 0.9375rem;
  }

  .single-books-item__dropdown-table {
    padding: 0.9375rem 0.625rem;
  }

  .single-books-item__dropdown-table-item .name {
    font-size: 1.125rem;
  }

  .single-books-item__dropdown-table-item .value {
    font-size: 1.125rem;
  }

  .main-gift-basket__navigation:not(:last-child) {
    margin-bottom: 0;
  }

  .main-gift-basket__nav-list {
    gap: 0.9375rem;
  }

  .main-gift-basket__nav-item button::after {
    bottom: 0;
  }

  .my-purchase-single__name {
    font-size: 1.4375rem;
    padding-left: 3.75rem;
  }

  .main-blessing-created__bg-image-ibg {
    display: none;
  }

  .main-blessing-created__content-window {
    padding-top: 8.125rem;
    right: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-radius: 0;
    background-color: var(--main-pink);
    background-position: 70% bottom;
  }

  .main-blessing-created__title:not(:last-child) {
    margin-bottom: 0;
  }

  .main-blessing-created__btn-container .btn-white {
    width: 100%;
    max-width: 20rem;
  }

  .main-blessing-created__bottom-info {
    padding-right: 0;
    max-width: 19.375rem;
    margin: auto;
  }

  .main-event-created__bg-image-ibg {
    display: none;
  }

  .main-event-created__content-window {
    padding-top: 8.125rem;
    right: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-radius: 0;
    background-color: var(--main-pink);
    background-position: 70% bottom;
  }

  .main-event-created .btn-container_center .btn {
    width: 100%;
    max-width: 20rem;
  }

  .main-event-created .btn-container_center .btn-white {
    width: 100%;
    max-width: 20rem;
  }

  .after-register .credentials-section__content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .main-contact-us {
    padding-top: 1.875rem;
  }

  .main-contact-us__title:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .main-contact-us__form-block:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .main-terms-conditions {
    padding-top: 2.1875rem;
  }

  .main-terms-conditions__content {
    max-width: 20rem;
  }

  .main-terms-conditions__content:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .main-terms-conditions__submit-block {
    max-width: 20rem;
  }

  .category .topbar .topbar__bg-img-ibg img {
    -o-object-position: 76% center;
    object-position: 76% center;
  }

  .main-category__title:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .main-category__list {
    row-gap: 1rem;
  }

  .main-event-add-presents {
    padding-bottom: 2.5rem;
  }

  .single-event-presents {
    padding: 0.875rem 0.75rem 0.875rem 0.9375rem;
  }

  .single-event-presents__body {
    gap: 0.9375rem;
  }

  .single-event-presents__image-ibg {
    width: 6.5625rem;
    height: 6.5625rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.5625rem;
    flex: 0 0 6.5625rem;
  }

  .single-event-presents__title {
    font-size: 1.25rem;
  }

  .single-event-presents__date {
    font-size: 1.125rem;
  }

  .single-event-presents__btn-container .btn {
    font-size: 1.125rem;
    max-width: 13.125rem;
    width: 100%;
  }

  .single-event-presents__btn-container .btn::before {
    right: 0.5rem;
    width: 1.125rem;
    height: 1.25rem;
  }

  .single-event-presents__btn-container .btn::after {
    left: 0.625rem;
    width: 0.5rem;
    height: 1rem;
  }

  .main-edit-profile {
    padding-top: 2.0625rem;
  }

  .form-edit-profile__form-body:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .form-edit-profile__address-details {
    margin-top: 1.25rem;
  }

  .form-edit-profile__mail {
    margin-top: 0;
  }

  .products .topbar .topbar__bg-img-ibg img {
    -o-object-position: 76% center;
    object-position: 76% center;
  }

  .main-products__list-wrapper {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
    padding: 1.875rem 0.9375rem;
  }

  .main-single-product__mobile-bottom-text {
    display: block;
    font-size: 1.25rem;
    line-height: 1.15;
    font-weight: 600;
  }

  .main-single-product__mobile-bottom-text span {
    font-weight: 700;
  }

  .main-single-product__main-slider-block .search-btn {
    display: none;
  }

  .main-single-product__main-slider-block {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }

  .main-single-product__main-navigation {
    display: block;
  }

  .main-single-product__image-ibg {
    border: none;
  }

  .main-single-product__thumbs-slider-block {
    display: none;
  }

  .main-single-product__title {
    font-size: 1.6875rem;
  }

  .main-single-product__price:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  .main-single-product__price {
    gap: 0.375rem;
  }

  .main-single-product__price .old {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    font-weight: 400;
  }

  .main-single-product__rating {
    display: none;
  }

  .main-single-product__describe {
    display: none;
  }

  .main-single-product__form-wrapper:not(:last-child) {
    margin-bottom: 0.875rem;
  }

  .main-single-product__spollers {
    display: none;
  }

  .main-single-product__footer {
    border-top: 0.0625rem solid #a7a7a7;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 0.625rem;
    padding-bottom: 5.625rem;
  }

  .main-single-product__bottom-ratings {
    gap: 1.25rem;
  }

  .main-single-product__likes .icon {
    width: 0.8125rem;
  }

  .main-single-product__rating-wrapper .rating:not(:last-child) {
    margin-bottom: 0.125rem;
  }

  .main-single-product__rating-wrapper .rating__body {
    width: 5.8125rem;
    height: 1.0625rem;
  }

  .main-single-product__rating-wrapper .rating-text {
    font-size: 1.125rem;
  }

  .form-main-single-product__color-selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form-main-single-product__title:not(:last-child) {
    margin-bottom: 0rem;
    font-size: 1.375rem;
    font-weight: 600;
  }

  .form-main-single-product__size-selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form-main-single-product__quantity-wrapper:not(:last-child) {
    margin-bottom: 0rem;
  }

  .form-main-single-product__quantity-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form-main-single-product__quantity-title {
    display: block;
    font-size: 1.375rem;
    font-weight: 600;
  }

  .form-main-single-product__bottom-buttons {
    display: none;
  }

  .quantity {
    width: 7.25rem;
    border-radius: 0.5rem;
    height: 2.3125rem;
  }

  .main-reminders {
    padding-bottom: 3.75rem;
  }

  .single-reminder__content {
    padding: 2.5rem 0.9375rem 0.8125rem 0.9375rem;
  }

  .single-reminder__image-ibg {
    margin-left: 0.625rem;
  }

  .single-reminder__text-content {
    padding-left: 0;
  }

  .single-reminder__title {
    font-size: 1.25rem;
  }

  .single-reminder__title:not(:last-child) {
    margin-bottom: 0;
  }

  .single-reminder__close {
    -webkit-transform: translate(-12.5%, -105%);
    -ms-transform: translate(-12.5%, -105%);
    transform: translate(-12.5%, -105%);
  }

  .main-all-categories {
    padding-bottom: 5rem;
  }

  .main-all-categories__titles-block:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .main-all-categories__list {
    gap: 2.5rem;
    row-gap: 1.25rem;
    max-width: 18.75rem;
    margin: auto;
  }

  .main-all-categories__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 1.25rem);
    flex: 0 0 calc(50% - 1.25rem);
  }

  .main-all-categories .single-event-type__image-ibg {
    display: none;
  }

  .main-all-categories .single-event-type__icon {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: 100%;
    border: 0.125rem solid var(--text-secondary);
    -webkit-box-shadow: 0.625rem 0.625rem 1.875rem rgba(0, 0, 0, 0.2);
    box-shadow: 0.625rem 0.625rem 1.875rem rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
  }

  .main-all-categories .single-event-type__icon > * {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .main-successful-payment__bg-image-ibg {
    display: none;
  }

  .main-successful-payment__content-window {
    right: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-radius: 0;
    background-color: var(--main-pink);
    background-position: 70% bottom;
  }

  .main-successful-payment__title:not(:last-child) {
    margin-bottom: 0;
  }

  .main-choosed-presents {
    padding-top: 1.875rem;
  }

  .main-choosed-presents__nav-button::after {
    bottom: 0;
  }

  .main-choosed-presents__list {
    row-gap: 1.875rem;
  }

  .main-choosed-presents__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
}

@media (max-width: 22.5em) {
  .mobile-menu__user-actions {
    gap: 0.625rem;
  }

  .my-event-single__actions {
    gap: 0.625rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .my-event-single .button {
    width: 1.25rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.25rem;
    flex: 0 0 1.25rem;
  }
}

@media (max-width: 21.875em) {
  .gift-basket__bottom-menu-btn .icon {
    right: 0.5rem;
  }

  .gift-basket__bottom-menu-btn::after {
    left: 0.625rem;
  }
}

@media (max-width: 120em) and (min-width: 47.99875em) {
  .btn {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-red {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-red::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-orange {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-orange::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-black {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-black::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-gray {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-gray::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-white {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-white::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .btn-outline-white {
    padding: 0.36458333vw 0.78125vw;
    font-size: 1.14583333vw;
    border-radius: 1.25vw;
  }

  .btn-outline-white::after {
    left: 1.041666666vw;
    width: 0.5208333vw;
    height: 1.041666666vw;
  }

  .input {
    border-radius: 0.416666vw !important;
    padding: 0.416666vw 0.78125vw;
    border: 0.0520833vw solid #d9d6d6;
    font-size: 1.0416666vw;
  }

  .form-title {
    font-size: 0.9375vw;
  }

  .form-title:not(:last-child) {
    margin-bottom: 0.2604166vw;
  }

  .input-title {
    right: 0.625vw;
    font-size: 0.9375vw;
    padding: 0.0520833vw 0.36458333vw 0.2083333vw 0.46875vw;
  }

  .mail-wrapper:not(:last-child) {
    margin-bottom: 0.5208333vw;
  }

  .tel-wrapper::after {
    margin-left: 0.83333vw;
    margin-right: 0.83333vw;
    font-size: 0.9375vw;
  }

  .tel-wrapper:not(:last-child) {
    margin-bottom: 1.041666vw;
  }

  .checkbox:not(:last-child) {
    margin-bottom: 0.260416vw;
  }

  .checkbox__label {
    gap: 0.3645833vw;
  }

  .checkbox__label::before {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.145833vw;
    flex: 0 0 1.145833vw;
    width: 1.145833vw;
    height: 1.145833vw;
    border: 0.0520833vw solid #d9d6d6;
    border-radius: 0.3645833vw;
  }

  .checkbox__label::after {
    width: 0.625vw;
    height: 0.520833vw;
    top: 0.3125vw;
    right: 0.2604166vw;
  }

  .checkbox__text {
    font-size: 0.9375vw;
  }

  .rating {
    font-size: 1.25vw;
  }

  .rating__body {
    width: 4.84375vw;
    height: 0.8854166vw;
  }

  .rating__value {
    padding: 0 0 0 0.520833vw;
  }

  .popup {
    padding: 1.5625vw;
  }

  .popup__content {
    padding: 1.5625vw 1.0416666vw 3.125vw;
    min-height: 31.25vw;
    max-width: 31.25vw;
    border-radius: 2.34375vw;
  }

  .popup__close {
    top: 1.8229166vw;
    left: 2.34375vw;
    width: 1.5625vw;
    height: 1.5625vw;
  }

  .popup__close::before,
  .popup__close::after {
    height: 0.15625vw;
  }

  .popup__body {
    max-width: 19.7916666vw;
  }

  .popup-phones .popup__body {
    max-width: 27vw;
  }

  .popup__bg-ibg img {
    border-radius: 2.34375vw;
  }

  .popup__image-ibg_contain {
    width: 7.65625vw;
    height: 7.34375vw;
  }

  .popup__image-ibg_contain:not(:last-child) {
    margin-bottom: 1.04166vw;
  }

  .popup__title {
    font-size: 1.25vw;
  }

  .popup__big-title {
    font-size: 2.6041666vw;
  }

  .popup__price {
    font-size: 5.208333vw;
  }

  .popup__buttons-container {
    max-width: 14.3229166vw;
    margin-top: 1.041666vw;
  }

  .popup__buttons-container > *:not(:last-child) {
    margin-bottom: 1.041666vw;
  }

  .popup__buttons-container > *::after {
    left: 0.72916666vw;
  }

  .popup__buttons-flex-container {
    gap: 0.5208333vw;
    max-width: 10.15625vw;
    margin: 1.3541666vw auto 0;
  }

  .popup__buttons-flex-container > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 0.2604166vw);
    flex: 0 0 calc(50% - 0.2604166vw);
    width: calc(50% - 0.2604166vw);
    padding-right: 0.3645833vw;
  }

  #item-added-to-cart-popup .popup__buttons-container .btn-white .icon {
    right: 0.625vw;
  }

  #item-added-to-cart-popup .popup__buttons-container .btn-white.gift .icon {
    width: 1.09375vw;
    height: 1.1979166vw;
  }

  #item-added-to-cart-popup .popup__buttons-container .btn-white.favorites .icon {
    width: 1.1979166vw;
    height: 1.510416666vw;
  }

  #item-added-to-cart-popup .popup__buttons-container .btn-white.favorites[data-count]::before {
    width: 1.041666vw;
    height: 1.041666vw;
    font-size: 0.9375vw;
    border: 0.052083vw solid #fff;
  }

  #item-added-to-cart-popup .popup__buttons-container .btn-white.money .icon {
    width: 1.7708333vw;
    height: 1.8229166vw;
  }

  #blessing-goodbye-popup .popup__body {
    padding-top: 2.0833333vw;
  }

  #blessing-created-popup .popup__body {
    max-width: 13.020833vw;
  }

  #blessing-created-popup .popup__buttons-container {
    margin-top: 2.34375vw;
  }

  #blessing-created-popup .popup__buttons-container > * {
    border-radius: 0.9375vw/1.19791666vw;
  }

  #blessing-created-popup .popup__buttons-container > *:not(:last-child) {
    margin-bottom: 0.67708333vw;
  }

  #blessing-created-popup .popup__buttons-container .btn::before {
    right: 0.9375vw;
    width: 1.09375vw;
    height: 1.71875vw;
  }

  #blessing-created-reminder-popup .popup__buttons-container {
    margin-top: 2.34375vw;
  }

  #blessing-created-reminder-popup .popup__buttons-container > * {
    border-radius: 0.9375vw/1.1979166vw;
  }

  #blessing-created-reminder-popup .popup__buttons-container > *:not(:last-child) {
    margin-bottom: 0, 6770833vw;
  }

  #blessing-created-reminder-popup .popup__buttons-container .btn::before {
    right: 0.9375vw;
    width: 1.09375vw;
    height: 1.71875vw;
  }

  #please-choose-category-popup .popup__buttons-container {
    max-width: 8.645833vw;
  }

  #code-not-right-popup-green .popup__image-ibg_contain:not(:last-child) {
    margin-bottom: 0.520833vw;
  }

  #code-not-right-popup-green .popup__buttons-container {
    margin-top: 1.71875vw;
    max-width: 8.6458333vw;
  }

  #code-not-right-popup-pink .popup__buttons-container {
    margin-top: 1.71875vw;
    max-width: 10.416666666vw;
  }

  #we-send-you-sms-popup-green .popup__image-ibg_contain {
    height: 3.3854166vw;
  }

  #we-send-you-sms-popup-green .popup__title {
    margin-top: -0.520833vw;
  }

  #we-send-you-sms-popup-green .popup__buttons-container {
    margin-top: 1.71875vw;
    max-width: 8.64583333vw;
  }

  #we-send-you-sms-popup-pink .popup__title {
    margin-top: -3.645833vw;
  }

  #we-send-you-sms-popup-pink .popup__buttons-container {
    margin-top: 1.71875vw;
    max-width: 10.41666vw;
  }

  #thanks-for-send-present-popup .popup__title {
    font-size: 1.40625vw;
  }

  #thanks-for-send-present-popup .popup__title:first-child {
    font-size: 1.8229166vw;
  }

  #thanks-for-send-present-popup .popup__image-ibg_contain {
    width: 11.71875vw;
    height: 12.7604166vw;
  }

  #thanks-for-send-money-popup .popup__title {
    font-size: 1.40625vw;
  }

  #thanks-for-send-money-popup .popup__title:first-child {
    font-size: 1.8229166vw;
    margin-top: 1.8229166vw;
  }

  #thanks-for-send-money-popup .popup__image-ibg_contain {
    width: 11.71875vw;
    height: 12.7604166vw;
  }

  #event-created-green-popup .popup__body {
    padding-top: 2.083333vw;
  }

  .popup_image-content .popup__content {
    max-width: 40.625vw;
  }

  .popup_image-content .popup__top {
    padding: 4.42708333vw 1.5625vw 2.34375vw;
  }

  .popup_image-content .popup__image-ibg_contain {
    width: 12.7604166vw;
    height: 12.7604166vw;
  }

  .popup_image-content .popup__bottom {
    padding: 3.38541666vw 1.5625vw 4.6875vw;
  }

  .popup_image-content .popup__subtitle {
    font-size: 1.40625vw;
    max-width: 13.854166vw;
  }

  .popup_image-content .popup__subtitle:not(:last-child) {
    margin-bottom: 1.1458333vw;
  }

  .popup_image-content .popup__bold-text {
    font-size: 1.25vw;
  }

  .popup_image-content .popup__bold-text:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .popup_image-content .popup__price {
    font-size: 4.16666vw;
  }

  .popup_image-content .popup__price:not(:last-child) {
    margin-bottom: 1.3020833vw;
  }

  .popup_image-content .popup__buttons-container {
    max-width: 16.666666vw;
  }

  .popup_image-content .popup__buttons-container .btn::before {
    right: 1.041666vw;
    width: 1.25vw;
    height: 1.40625vw;
  }

  .popup_image-content .popup__sad-smile {
    width: 4.6354166vw;
  }

  .popup_image-content .popup__sad-smile:not(:last-child) {
    margin-bottom: 0.52083333vw;
  }

  .bank-popup .popup__title {
    font-size: 2.083333vw;
  }

  .bank-popup .popup__title:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  #bank-empty-popup .popup__subtitle:not(:last-child) {
    margin-bottom: 0.5208333vw;
  }

  #already-bought-popup .popup__content {
    max-width: 26.927083vw;
    padding: 4.16666vw 3.90625vw;
  }

  #already-bought-popup .popup__title {
    font-size: 1.25vw;
  }

  #already-bought-popup .popup__title:not(:last-child) {
    margin-bottom: 1.35416666vw;
  }

  #already-bought-popup .popup__list {
    height: calc(100 * var(--vh) - 15.625vw);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100 * var(--vh) - 15.625vw);
    flex: 0 0 calc(100 * var(--vh) - 15.625vw);
  }

  #already-bought-popup .popup__list-item {
    padding: 0.36458333vw 0;
    border-bottom: 0.05208333vw solid #d9d6d6;
  }

  #already-bought-popup .popup__list-item .avatar {
    width: 2.708333vw;
    height: 2.708333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.708333vw;
    flex: 0 0 2.708333vw;
    margin-left: 0.72916666vw;
  }

  #already-bought-popup .popup__list-item .text {
    font-size: 1.0416666vw;
  }

  #already-bought-popup .popup__list-item .name {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4.0625vw;
    flex: 0 0 4.0625vw;
    margin-left: 1.0416666vw;
  }

  #already-bought-popup .popup__list-item .product {
    padding-left: 1.25vw;
  }

  #already-bought-popup .switch {
    width: 5vw;
    height: 0.9895833vw;
  }

  #already-bought-popup .slider {
    border: 0.0520833vw solid #afaeae;
  }

  #already-bought-popup .slider::before {
    height: 0.78125vw;
    width: 0.78125vw;
    right: 0.1041666vw;
    top: 0.0520833vw;
  }

  #already-bought-popup input:checked + .slider:before {
    -webkit-transform: translateX(-1.5104166vw);
    -ms-transform: translateX(-1.5104166vw);
    transform: translateX(-1.5104166vw);
  }

  #already-bought-popup .slider.round {
    border-radius: 0.520833vw;
  }

  .numbers-steps__list {
    max-width: 28.3854vw;
  }

  .numbers-steps__list::after {
    height: 0.1042vw;
  }

  .numbers-steps__list::before {
    height: 0.1042vw;
  }

  .numbers-steps__step {
    width: 2.1875vw;
    height: 2.1875vw;
    border: 0.1042vw solid var(--text-gray);
    font-size: 1.3021vw;
  }

  .filter-btn-container .filter-btn {
    width: 2.8125vw;
    height: 2.8125vw;
    border-radius: 0.520833333vw;
  }

  .single-event-type .single-event-type__content {
    border-radius: 0.5208vw;
  }

  .single-event-type .single-event-type__text-content {
    padding-bottom: 1.0417vw;
    padding-left: 0.7812vw;
    padding-right: 0.7812vw;
  }

  .single-event-type .single-event-type__icon {
    width: 2.1875vw;
    height: 2.1875vw;
    margin-top: -1.0938vw;
    border-radius: 0.520833333vw;
  }

  .single-event-type .single-event-type__icon:not(:last-child) {
    margin-bottom: 0.5729vw;
  }

  .single-event-type .single-event-type__title {
    font-size: 1.3021vw;
  }

  .single-event-type .single-event-type__title:not(:last-child) {
    margin-bottom: 0.7812vw;
  }

  .single-event-type .single-event-type__btn-container .btn {
    font-size: 0.9375vw;
    max-width: 11.9792vw;
    padding: 0.5208vw 0.7812vw;
  }

  .main-choose-event {
    padding-bottom: 8.8542vw;
  }

  .main-choose-event__header {
    gap: 0.7813vw;
  }

  .main-choose-event__header:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__nav-list {
    gap: 2.6042vw;
    padding: 0.7813vw 0;
  }

  .main-choose-event__button {
    font-size: 1.0417vw;
    padding: 0.2604vw 0;
  }

  .main-choose-event__button::after {
    bottom: -0.7813vw;
    height: 0.1042vw;
  }

  .main-choose-event__filter {
    gap: 0.625vw;
  }

  .main-choose-event__filter .text {
    font-size: 0.9375vw;
    margin-left: 0.5208vw;
  }

  .main-choose-event__filter .arrow {
    width: 0.7813vw;
  }

  .main-choose-event__filter__steps-numbers:not(:last-child) {
    margin-bottom: 1.0417vw;
  }

  .main-choose-event__filter__titles-block:not(:last-child) {
    margin-bottom: 2.0833vw;
  }

  .main-choose-event__filter__pretitle {
    font-size: 1.1458vw;
  }

  .main-choose-event__filter__pretitle:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-choose-event__filter__title {
    font-size: 1.8229vw;
  }

  .main-choose-event__filter__title:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-choose-event__filter__subtitle {
    font-size: 1.1458vw;
  }

  .main-choose-event__filter__list {
    gap: 2.6042vw;
  }

  .main-choose-event__filter__my-events-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__filter__history-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__filter__books-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__steps-numbers:not(:last-child) {
    margin-bottom: 0.9375vw;
  }

  .main-choose-event__titles-block:not(:last-child) {
    margin-bottom: 2.0833vw;
  }

  .main-choose-event__pretitle {
    font-size: 1.1458vw;
  }

  .main-choose-event__pretitle:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-choose-event__title {
    font-size: 1.9271vw;
  }

  .main-choose-event__title:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-choose-event__subtitle {
    font-size: 1.1458vw;
  }

  .main-choose-event__list {
    gap: 2.604166666vw;
  }

  .main-choose-event__my-events-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__history-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-choose-event__books-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .my-event-single__content {
    -webkit-filter: drop-shadow(0 0 1.4583vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0 1.4583vw rgba(0, 0, 0, 0.16));
    border-radius: 0.4167vw;
    padding: 0.6771vw 0.7813vw 0.6771vw 2.6042vw;
  }

  .my-event-single__image-ibg {
    width: 6.5104vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.5104vw;
    flex: 0 0 6.5104vw;
    padding-bottom: 6.5104vw;
    margin-left: 1.8229vw;
  }

  .my-event-single__image-ibg img {
    border-radius: 0.625vw;
  }

  .my-event-single__title {
    font-size: 1.1979vw;
    padding-left: 0.7813vw;
  }

  .my-event-single__date {
    padding-left: 1.0417vw;
    margin-left: 3.6458vw;
    font-size: 0.9375vw;
  }

  .my-event-single__actions {
    gap: 1.0417vw;
    flex: 0 0 15.9896vw;
  }

  .my-event-single__actions .button {
    width: 1.25vw;
    height: 1.458333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.25vw;
    flex: 0 0 1.25vw;
  }

  .my-event-single__actions .button-edit {
    width: 0.9375vw;
    height: 0.9375vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.9375vw;
    flex: 0 0 0.9375vw;
    margin-right: 1.3020833vw;
  }

  .single-history-item__content {
    -webkit-filter: drop-shadow(0 0 1.458vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0 1.458vw rgba(0, 0, 0, 0.16));
    border-radius: 0.417vw;
    padding: 0.677vw 0.833vw 0.677vw 1.458vw;
  }

  .single-history-item__image-ibg {
    width: 6.51vw;
    padding-bottom: 6.51vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.51vw;
    flex: 0 0 6.51vw;
    margin-left: 1.823vw;
  }

  .single-history-item__image-ibg img {
    border-radius: 1.25vw;
  }

  .single-history-item__text-content {
    gap: 1.042vw;
  }

  .single-history-item__title {
    font-size: 1.198vw;
  }

  .single-history-item__date {
    font-size: 0.938vw;
  }

  .single-history-item__actions {
    gap: 1.354vw;
  }

  .single-history-item__actions.delete {
    width: 1.25vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.25vw;
    flex: 0 0 1.25vw;
    height: 1.45833333vw;
  }

  .single-history-item__actions.gift {
    width: 1.45833333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.45833333vw;
    flex: 0 0 1.45833333vw;
    height: 1.6145833vw;
  }

  .single-books-item__content {
    -webkit-filter: drop-shadow(0 0 1.458vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0 1.458vw rgba(0, 0, 0, 0.16));
    border-radius: 0.417vw;
    padding: 0.677vw 0.833vw 0.677vw 1.458vw;
  }

  .single-books-item__content:hover {
    border-radius: 0.417vw 0.417vw 0 0;
  }

  .single-books-item__image-ibg {
    width: 6.51vw;
    padding-bottom: 6.51vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.51vw;
    flex: 0 0 6.51vw;
    margin-left: 1.823vw;
  }

  .single-books-item__image-ibg img {
    border-radius: 0.625vw;
  }

  .single-books-item__text-content {
    gap: 1.042vw;
  }

  .single-books-item__title {
    font-size: 1.198vw;
  }

  .single-books-item__date {
    font-size: 0.938vw;
    padding-left: 1.0417vw;
    margin-left: 3.6458vw;
  }

  .single-books-item__actions {
    gap: 1.354vw;
    flex: 0 0 15.9896vw;
  }

  .single-books-item__actions .button {
    width: 1.25vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.25vw;
    flex: 0 0 1.25vw;
    height: 1.458333vw;
  }

  .single-books-item__actions .button.gift {
    width: 1.458333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.458333vw;
    flex: 0 0 1.458333vw;
    height: 1.61458333vw;
  }

  .single-books-item .arrow {
    width: 0.469vw;
    height: 0.938vw;
    margin-right: 1.042vw;
    margin-bottom: -0.26vw;
  }

  .single-books-item__dropdown-body {
    padding: 0.625vw 1.042vw 0.781vw 1.823vw;
    border-radius: 0 0 0.417vw 0.417vw;
  }

  .single-books-item__dropdown-table {
    border-radius: 0.781vw;
    padding: 1.094vw 2.083vw;
    gap: 1.563vw;
    row-gap: 0.625vw;
  }

  .single-books-item__dropdown-table:not(:last-child) {
    margin-bottom: 1.302vw;
  }

  .single-books-item__dropdown-table-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 16.666vw;
    flex: 0 1 16.666vw;
  }

  .single-books-item__dropdown-table-column:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 22.135vw;
    flex: 0 1 22.135vw;
  }

  .single-books-item__dropdown-table-item {
    gap: 0.521vw;
  }

  .single-books-item__dropdown-table-item:not(:last-child) {
    margin-bottom: 0.625vw;
  }

  .single-books-item__dropdown-table-item .name {
    font-size: 1.042vw;
  }

  .single-books-item__dropdown-table-item .value {
    font-size: 1.042vw;
  }

  .single-books-item__dropdown-bottom-text {
    font-size: 1.042vw;
    padding: 0 0.521vw;
  }

  .events-history .topbar__title {
    padding: 1.0416666vw 4.166666vw;
  }

  .events-history .topbar__title .icon {
    width: 3.645833vw;
  }

  .main-gift-basket__navigation {
    padding: 0.9375vw;
  }

  .main-gift-basket__nav-item button {
    gap: 0.3125vw;
    padding: 0.2604vw 0.1563vw;
  }

  .main-gift-basket__nav-item button::after {
    height: 0.1042vw;
    bottom: -0.5208vw;
  }

  .main-gift-basket__nav-item button .icon {
    width: 0.9375vw;
    height: 0.8333vw;
  }

  .main-gift-basket__nav-item button .text {
    font-size: 1.0417vw;
  }

  .main-gift-basket__header-product-name {
    font-size: 0.9375vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(27.4418604651% + 6.0416666vw);
    flex: 0 0 calc(27.4418604651% + 6.0416666vw);
    padding-right: 1.5625vw;
    padding-left: 1.0417vw;
  }

  .main-gift-basket__header-product-price {
    font-size: 0.9375vw;
    padding-left: 1.0417vw;
  }

  .main-gift-basket__header-product-filter {
    gap: 0.625vw;
    margin-left: 0.5208vw;
  }

  .main-gift-basket__header-product-filter .text {
    font-size: 0.9375vw;
    margin-left: 0.5208333vw;
  }

  .main-gift-basket__header-product-filter .arrow {
    width: 0.7813vw;
  }

  .main-gift-basket__my-purchases-item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .my-purchase-single__content {
    padding: 0.9375vw 0.9375vw 0.9375vw 3.4896vw;
  }

  .my-purchase-single__image-ibg {
    width: 6.0417vw;
    height: 6.0417vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.0417vw;
    flex: 0 0 6.0417vw;
  }

  .my-purchase-single__name {
    font-size: 1.25vw;
    padding-right: 1.8229vw;
    padding-left: 1.0417vw;
  }

  .my-purchase-single__price {
    font-size: 1.25vw;
    padding-left: 1.0417vw;
  }

  .my-purchase-single__actions {
    gap: 2.0833vw;
    padding-right: 1.0417vw;
  }

  .my-purchase-single__actions .button {
    width: 1.6667vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.6667vw;
    flex: 0 0 1.6667vw;
    height: 1.9271vw;
  }

  .my-purchase-single__actions .button.favorites {
    width: 2.0833vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.0833vw;
    flex: 0 0 2.0833vw;
    height: 2.1354vw;
    margin-bottom: -0.4167vw;
  }

  .my-purchase-single__actions .status {
    margin-right: 0.5208vw;
    width: 2.8646vw;
    height: 2.8646vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.8646vw;
    flex: 0 0 2.8646vw;
  }

  .my-purchase-single__actions .status p {
    font-size: 0.9375vw;
  }

  .credentials-section {
    padding: 1.5625vw 0;
    min-height: calc(100 * var(--vh) - 8.125vw);
  }

  .credentials-section__content-window {
    min-height: calc(90 * var(--vh) - 8.125vw);
  }

  .main-contact-us {
    padding-top: 2.864583vw;
    padding-bottom: 5.208333vw;
  }

  .main-contact-us__title {
    font-size: 1.041667vw;
  }

  .main-contact-us__title:not(:last-child) {
    margin-bottom: 1.8229166vw;
  }

  .main-contact-us__form-block:not(:last-child) {
    margin-bottom: 4.166667vw;
  }

  .main-contact-us__socials-title {
    font-size: 1.041667vw;
  }

  .main-contact-us__socials-title:not(:last-child) {
    margin-bottom: 0.677083vw;
  }

  .main-contact-us__socials-list {
    gap: 1.8229166vw;
  }

  .main-contact-us__socials-link {
    width: 1.8229166vw;
    height: 1.8229166vw;
  }

  .main-terms-conditions {
    padding-top: 3.385417vw;
    padding-bottom: 5.208333vw;
  }

  .main-terms-conditions__content {
    font-size: 0.9375vw;
  }

  .main-terms-conditions__content:not(:last-child) {
    margin-bottom: 6.25vw;
  }

  .main-terms-conditions__submit-block .btn-container .btn {
    max-width: 16.666667vw;
  }

  .main-terms-conditions__checkbox-wrap .checkbox__label:not(:last-child) {
    margin-bottom: 1.041667vw;
  }

  .main-terms-conditions__checkbox-wrap .checkbox__text {
    font-size: 0.833333vw;
  }

  .category .topbar .topbar__title {
    font-size: 2.604166666vw;
    max-width: 30.625vw;
    padding: 1.8229166666vw 0;
    margin-left: 1.5625vw;
  }

  .main-category {
    padding-top: 1.3021vw;
    padding-bottom: 5.2083vw;
  }

  .main-category__steps-numbers:not(:last-child) {
    margin-bottom: 2.0833vw;
  }

  .main-category__title {
    font-size: 1.1458vw;
  }

  .main-category__title:not(:last-child) {
    margin-bottom: 3.6458vw;
  }

  .main-category__list {
    -webkit-column-gap: 2.5vw;
    -moz-column-gap: 2.5vw;
    column-gap: 2.5vw;
    row-gap: 2.9167vw;
  }

  .main-category__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.33333% - 1.666666vw);
    flex: 0 0 calc(33.33333% - 1.666666vw);
  }

  .single-category-type__icon {
    width: 2.1875vw;
    height: 2.1875vw;
    margin-top: -1.0938vw;
    border-radius: 0.520833333vw;
  }

  .single-category-type__title {
    font-size: 1.25vw;
  }

  .single-category-type__title:not(:last-child) {
    margin-bottom: 0.0521vw;
  }

  .single-category-type__text {
    font-size: 0.9375vw;
  }

  .single-category-type__text:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .single-category-type__btn-container:not(:last-child) {
    margin-bottom: 1.0938vw;
  }

  .single-category-type__btn-container .btn {
    font-size: 0.9375vw;
    max-width: 11.979166666vw;
    padding: 0.5208333vw 0.78125vw;
  }

  .single-category-type__dropdown-btn {
    padding: 0.625vw 0;
    gap: 0.5208vw;
    font-size: 0.9375vw;
  }

  .single-category-type__dropdown-btn img {
    width: 0.8333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.8333vw;
    flex: 0 0 0.8333vw;
  }

  .single-category-type__dropdown-body {
    padding: 0.5208vw;
    padding-top: 2.9688vw;
  }

  .single-category-type__dropdown-list {
    max-width: 7.2917vw;
  }

  .single-category-type__dropdown-list-item::before {
    width: 0.6771vw;
    height: 0.6771vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.6771vw;
    flex: 0 0 0.6771vw;
    border-radius: 0.15625vw;
  }

  .event-add-presents__topbar .topbar__title {
    padding: 0.52083333vw 8.3333vw;
  }

  .event-add-presents__topbar .topbar__title .icon {
    width: 6.5625vw;
    height: 5.3125vw;
    bottom: -1.1458vw;
  }

  .main-event-add-presents {
    padding-top: 1.5625vw;
    padding-bottom: 7.8125vw;
  }

  .main-event-add-presents__title {
    font-size: 1.0417vw;
  }

  .main-event-add-presents__title:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-event-add-presents__item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .single-event-presents {
    -webkit-filter: drop-shadow(0 0 1.4583vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0 1.4583vw rgba(0, 0, 0, 0.16));
    padding: 1.0417vw 1.0417vw 1.0417vw 2.3438vw;
  }

  .single-event-presents__body {
    gap: 1.3021vw;
  }

  .single-event-presents__image-ibg {
    width: 6.0417vw;
    height: 6.0417vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.0417vw;
    flex: 0 0 6.0417vw;
  }

  .single-event-presents__image-ibg img {
    border-radius: 0.625vw;
  }

  .single-event-presents__title {
    font-size: 1.25vw;
  }

  .single-event-presents__title:not(:last-child) {
    margin-bottom: 0.2604vw;
  }

  .single-event-presents__date {
    font-size: 1.0417vw;
  }

  .single-event-presents__btn-container {
    margin-top: -0.7813vw;
  }

  .single-event-presents__btn-container .btn {
    width: 10.9375vw;
    padding-top: 0.2604vw;
    padding-bottom: 0.2604vw;
  }

  .single-event-presents__btn-container .btn::before {
    right: 0.7813vw;
    width: 1.25vw;
    height: 1.4063vw;
  }

  .edit-profile .topbar__title {
    padding-left: 5.2083333333vw;
    padding-right: 5.2083333333vw;
  }

  .main-edit-profile {
    padding-top: 3.125vw;
    padding-bottom: 7.8125vw;
  }

  .main-edit-profile__title {
    gap: 0.78125vw;
  }

  .main-edit-profile__title:not(:last-child) {
    margin-bottom: 1.30208vw;
  }

  .main-edit-profile__title .icon {
    width: 0.9375vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.9375vw;
    flex: 0 0 0.9375vw;
  }

  .main-edit-profile__title .text {
    font-size: 0.83333vw;
  }

  .main-edit-profile__form-block {
    max-width: 46.875vw;
  }

  .birthday-field {
    margin-top: 2.34375vw;
  }

  .birthday-field__birthday-body .birthday-field__input-wrapper:not(:last-child)::after {
    font-size: 1.30208vw;
    left: -1.04167vw;
  }

  .birthday-field__birthday-title {
    font-size: 0.9375vw;
  }

  .birthday-field__birthday-title:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .form-edit-profile__form-body {
    margin-bottom: 2.864583vw;
  }

  .form-edit-profile__input-wrapper:not(:last-child) {
    margin-bottom: 1.041667vw;
  }

  .form-edit-profile__tel-wrapper::after {
    margin-left: 0.83333vw;
    margin-right: 0.83333vw;
    font-size: 0.9375vw;
  }

  .form-edit-profile__tel-wrapper:not(:last-child) {
    margin-bottom: 1.041667vw;
  }

  .form-edit-profile__col-title {
    font-size: 0.9375vw;
    margin-top: -0.78125vw;
  }

  .form-edit-profile__col-title:not(:last-child) {
    margin-bottom: 1.041667vw;
  }

  .form-edit-profile__address-details {
    margin-top: 3.125vw;
  }

  .form-edit-profile__mail {
    margin-top: 2.083333vw;
  }

  .form-edit-profile__checkbox {
    margin-top: 0.78125vw;
  }

  .form-edit-profile__btn-container .btn {
    max-width: 16.666667vw;
  }

  .products .topbar .topbar__title {
    font-size: 2.604166vw;
    max-width: 30.625vw;
    padding: 3.229166vw 0;
    margin-left: 3.125vw;
  }

  .main-products {
    padding-top: 1.3020833vw;
    padding-bottom: 5.72917vw;
  }

  .main-products_related {
    padding-top: 3.3854166vw;
    padding-bottom: 2.8645833vw;
  }

  .main-products_recent {
    padding-top: 4.16667vw;
    padding-bottom: 7.8125vw;
  }

  .main-products__steps:not(:last-child) {
    margin-bottom: 2.08333vw;
  }

  .main-products__pretitle {
    font-size: 1.145833vw;
  }

  .main-products__pretitle:not(:last-child) {
    margin-bottom: 1.3020833vw;
  }

  .main-products__title {
    font-size: 1.8229166vw;
  }

  .main-products__subtitle {
    font-size: 1.145833vw;
  }

  .main-products__subtitle:not(:last-child) {
    margin-bottom: 2.6041666vw;
  }

  .main-products__slider {
    padding: 2.6041666vw 0.78125vw;
    margin-left: -0.78125vw;
    margin-right: -0.78125vw;
  }

  .main-products__slider .single-products-card {
    -webkit-box-shadow: 0 0 0.52083vw rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0.52083vw rgba(0, 0, 0, 0.16);
  }

  .main-products__slider-prev,
  .main-products__slider-next {
    width: 1.97917vw;
    height: 1.97917vw;
  }

  .main-products__slider-prev {
    right: -3.22917vw;
  }

  .main-products__slider-next {
    left: -3.22917vw;
  }

  .main-products__btn-container .btn-outline-white {
    width: 16.66667vw;
    font-size: 0.9375vw;
    padding-top: 0.83333vw;
    padding-bottom: 0.83333vw;
    border-radius: 2.86458vw;
  }

  .main-products__btn-container .btn-outline-white::after {
    left: 0.67708vw;
  }

  .main-products__btn-container .btn-outline-white::before {
    right: 0.67708vw;
    width: 0.83333vw;
    height: 1.09375vw;
  }

  .main-products__view-type {
    gap: 0.52083vw;
  }

  .main-products__view-type:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .main-products__view-type-btn {
    height: 0.989583vw;
  }

  .main-products__header-title {
    font-size: 1.5625vw;
    padding-right: 0.78125vw;
  }

  .main-products__view-type-mobile {
    gap: 1.04167vw;
  }

  .main-products__view-type-mobile button {
    height: 1.04167vw;
  }

  .main-products__list-prev,
  .main-products__list-next {
    width: 3.125vw;
    height: 3.125vw;
    padding-right: 0.73958vw;
    -webkit-box-shadow: 0 0.52083vw 0.52083vw rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 0.52083vw 0.52083vw rgba(0, 0, 0, 0.1607843137);
  }

  .main-products__list-prev {
    -webkit-box-shadow: 0 -0.52083vw 0.52083vw rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 -0.52083vw 0.52083vw rgba(0, 0, 0, 0.1607843137);
  }

  .single-products-card {
    border-radius: 0.625vw;
    -webkit-box-shadow: 0 0 1.4583333333vw rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 1.4583333333vw rgba(0, 0, 0, 0.16);
  }

  .single-products-card__image-ibg img {
    border-radius: 0.625vw 0.625vw 0 0;
  }

  .single-products-card__buttons {
    top: 0.5208333333vw;
    left: 0.5208333333vw;
    padding: 0.7291666667vw 0.4166666667vw;
  }

  .single-products-card__buttons .button {
    width: 1.40625vw;
    height: 1.7708333333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.7708333333vw;
    flex: 0 0 1.7708333333vw;
  }

  .single-products-card__buttons .button:not(:last-child) {
    margin-bottom: 0.9375vw;
  }

  .single-products-card__buttons_mobile {
    left: 1.0416666667vw;
  }

  .single-products-card__link {
    max-width: 14.5833333333vw;
    font-size: 0.9375vw;
    padding: 0.625vw 1.0416666667vw;
    border-radius: 0.5729166667vw;
  }

  .single-products-card__link::before {
    right: 0.9375vw;
    width: 1.1458333333vw;
    height: 1.40625vw;
  }

  .single-products-card__text-content {
    padding: 1.0416666667vw 1.5625vw;
    border-radius: 0 0 0.625vw 0.625vw;
  }

  .single-products-card__title {
    font-size: 1.1979166667vw;
    padding-bottom: 0.2083333333vw;
  }

  .single-products-card__title:not(:last-child) {
    margin-bottom: 0.9375vw;
  }

  .single-products-card__title::after {
    height: 0.0520833333vw;
  }

  .single-products-card__rating:not(:last-child) {
    margin-bottom: 0.2604166667vw;
  }

  .single-products-card__text {
    font-size: 0.9375vw;
  }

  .single-products-card__text:not(:last-child) {
    margin-bottom: 0.5208333333vw;
  }

  .single-products-card__price {
    gap: 0.5208333333vw;
  }

  .single-products-card__price .new {
    font-size: 1.9791666667vw;
  }

  .single-products-card__price .old {
    font-size: 1.0416666667vw;
    margin-bottom: 0.1041666667vw;
  }

  .single-products-card__price .old .money-icon {
    margin-right: 0.2604166667vw;
  }

  .main-single-product {
    padding-top: 3.6458vw;
  }

  .main-single-product__body {
    padding-bottom: 4.1666666667vw;
  }

  .main-single-product__main-slider-block:not(:last-child) {
    margin-bottom: 1.3020833vw;
  }

  .main-single-product__main-slider-block .search-btn {
    top: 1.0416666667vw;
    left: 0.3645833333vw;
    width: 1.6666666667vw;
    height: 1.6666666667vw;
    border-radius: 0.3125vw;
  }

  .main-single-product__main-slider-block .bottom-buttons {
    bottom: 1.25vw;
    left: 1.25vw;
    gap: 0.625vw;
    padding: 0.4166666667vw 0.78125vw;
    border-radius: 0.8333333333vw;
  }

  .main-single-product__main-slider-block .bottom-buttons button {
    height: 1.9270833333vw;
    width: 1.8229166667vw;
  }

  .main-single-product__main-next,
  .main-single-product__main-prev {
    width: 3.125vw;
    height: 3.125vw;
    padding-right: 0.7291666667vw;
    -webkit-box-shadow: 0 0.4166666667vw 0.5208333333vw rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 0.4166666667vw 0.5208333333vw rgba(0, 0, 0, 0.1607843137);
  }

  .main-single-product__main-prev {
    -webkit-box-shadow: 0 -0.4166666667vw 0.5208333333vw rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 -0.4166666667vw 0.5208333333vw rgba(0, 0, 0, 0.1607843137);
  }

  .main-single-product__image-ibg {
    border: 0.0520833333vw solid #a7a7a7;
  }

  .main-single-product__thumbs-slider-block {
    padding: 0 1.875vw;
  }

  .main-single-product__thumbs-next,
  .main-single-product__thumbs-prev {
    width: 1.458333vw;
    height: 1.458333vw;
  }

  .main-single-product__title {
    font-size: 2.0833333333vw;
  }

  .main-single-product__title:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .main-single-product__price {
    gap: 0.15625vw;
    font-size: 1.5625vw;
    margin-bottom: 0.15625vw;
  }

  .main-single-product__price .new {
    font-size: 2.6041666667vw;
  }

  .main-single-product__price .new .money-icon {
    margin-right: -0.4166666667vw;
  }

  .main-single-product__price:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .justify-conte .main-single-product__rating:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .main-single-product__describe {
    font-size: 0.9375vw;
  }

  .main-single-product__describe:not(:last-child) {
    margin-bottom: 1.0416666667vw;
  }

  .main-single-product__describe h2 {
    font-size: 1.4583333333vw;
  }

  .main-single-product__describe h3 {
    font-size: 1.25vw;
  }

  .main-single-product__describe ul li {
    padding-left: 0.2604166667vw;
  }

  .main-single-product__describe ul li::before {
    margin-left: 0.2604166667vw;
  }

  .main-single-product__describe ol {
    padding-left: 1.3020833333vw;
  }

  .main-single-product__spollers-item {
    border-bottom: 0.0520833333vw solid #a7a7a7;
  }

  .main-single-product__spollers-item:first-child {
    border-top: 0.0520833333vw solid #a7a7a7;
  }

  .main-single-product__spollers-title {
    font-size: 0.9375vw;
    padding: 1.1458333333vw 0;
  }

  .main-single-product__spollers-body {
    padding: 0 0 1.25vw;
  }

  .main-single-product__spollers-body * {
    font-size: 0.7291666667vw;
  }

  .main-single-product__footer {
    padding: 1.25vw 0.5208333333vw 2.0833333333vw 0;
    gap: 1.875vw;
  }

  .main-single-product__bottom-ratings {
    gap: 1.875vw;
  }

  .main-single-product__likes .icon {
    width: 0.8854166667vw;
  }

  .main-single-product__likes .number {
    font-size: 0.9375vw;
  }

  .main-single-product__rating-wrapper .rating:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-single-product__rating-wrapper .rating__body {
    width: 6.4583333333vw;
    height: 1.1458333333vw;
  }

  .main-single-product__rating-wrapper .rating-text {
    font-size: 0.7291666667vw;
  }

  .form-main-single-product__color-selector:not(:last-child) {
    margin-bottom: 1.3541666667vw;
  }

  .form-main-single-product__title {
    font-size: 1.0416666667vw;
  }

  .form-main-single-product__title:not(:last-child) {
    margin-bottom: 0.6770833333vw;
  }

  .form-main-single-product__color-options {
    gap: 0.4166666vw;
  }

  .form-main-single-product__color-options .options__label {
    width: 1.7708333333vw;
    height: 1.7708333333vw;
    border-radius: 0.4166666667vw;
    border: 0.0520833333vw solid #959595;
  }

  .form-main-single-product__color-options .options__label::before {
    top: -0.0520833333vw;
    left: -0.0520833333vw;
    border: 0.15625vw solid var(--main-pink);
    border-radius: 0.4166666667vw;
  }

  .form-main-single-product__size-selector:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .form-main-single-product__size-options {
    gap: 0.4166666vw;
  }

  .form-main-single-product__size-options .options__label {
    width: 1.7708333333vw;
    height: 1.7708333333vw;
    border-radius: 0.4166666667vw;
    border: 0.0520833333vw solid var(--main-pink);
  }

  .form-main-single-product__size-options .options__text {
    font-size: 0.9375vw;
  }

  .form-main-single-product__quantity-wrapper:not(:last-child) {
    margin-bottom: 2.0833333333vw;
  }

  .form-main-single-product__bottom-buttons {
    gap: 0.625vw;
  }

  .form-main-single-product__bottom-buttons .btn,
  .form-main-single-product__bottom-buttons .btn-black {
    font-size: 1.0416666667vw;
    padding: 0.8854166667vw 0.5208333333vw;
    border-radius: 0.9375vw;
  }

  .form-main-single-product__bottom-buttons .btn::after,
  .form-main-single-product__bottom-buttons .btn-black::after {
    left: 0.7291666667vw;
  }

  .form-main-single-product__bottom-buttons .btn {
    width: 17.1875vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 17.1875vw;
    flex: 0 0 17.1875vw;
  }

  .form-main-single-product__bottom-buttons .btn-black {
    width: 10.78125vw;
    padding: 0.8854166667vw 0.5208333333vw 0.8854166667vw 1.0416666667vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10.78125vw;
    flex: 0 0 10.78125vw;
  }

  .quantity {
    border: 0.05208333vw solid #707070;
    border-radius: 0.5729166vw;
    height: 2.96875vw;
    width: 9.375vw;
  }

  .quantity__button {
    font-size: 0.9375vw;
  }

  .quantity__input input {
    border: 0.05208333vw solid #707070;
    font-size: 1.0416666vw;
  }

  .products__bottom-menu {
    -webkit-box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
    box-shadow: 0 -0.4375rem 1.0625rem rgba(0, 0, 0, 0.1607843137);
  }

  .products__bottom-menu-body .gift-link {
    gap: 0.5208333vw;
    padding: 0.5208333vw;
  }

  .products__bottom-menu-body .gift-link .gift-icon {
    width: 1.5104166vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.5104166vw;
    flex: 0 0 1.5104166vw;
  }

  .products__bottom-menu-body .gift-link .text {
    font-size: 1.145833vw;
  }

  .products__bottom-menu-body .gift-link .arrow {
    width: 0.5208333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.5208333vw;
    flex: 0 0 0.5208333vw;
  }

  .products__bottom-menu-body .btn {
    gap: 0.5208333vw;
  }

  .products__bottom-menu-body .btn .icon {
    width: 1.09375vw;
    height: 1.09375vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.09375vw;
    flex: 0 0 1.09375vw;
  }

  .my-bank .topbar__title {
    padding: 1.5625vw 7.8125vw;
  }

  .my-bank .topbar__title .icon {
    width: 7.8125vw;
  }

  .main-my-bank__body {
    padding-top: 5.20833vw;
    padding-bottom: 5.20833vw;
  }

  .main-my-bank__body.empty .main-my-bank__subtitle:not(:last-child) {
    margin-bottom: 0.15625vw;
  }

  .main-my-bank__icon {
    max-width: 12.76042vw;
  }

  .main-my-bank__icon:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .main-my-bank__title {
    font-size: 1.40625vw;
  }

  .main-my-bank__title:not(:last-child) {
    margin-bottom: 0.78125vw;
  }

  .main-my-bank__subtitle {
    font-size: 1.40625vw;
    max-width: 13.80208vw;
  }

  .main-my-bank__subtitle:not(:last-child) {
    margin-bottom: 1.40625vw;
  }

  .main-my-bank__price:not(:last-child) {
    margin-bottom: 2.08333vw;
  }

  .main-my-bank__price-text {
    font-size: 1.25vw;
  }

  .main-my-bank__price-text:not(:last-child) {
    margin-bottom: 0.26042vw;
  }

  .main-my-bank__price-value {
    font-size: 5.20833vw;
  }

  .main-my-bank__price-value .money-icon {
    margin-right: -0.36458vw;
  }

  .main-my-bank__btn-container:not(:last-child) {
    margin-bottom: 3.38542vw;
  }

  .main-my-bank__btn-container > * {
    max-width: 16.66667vw;
  }

  .main-my-bank__btn-container .btn-white::before {
    right: 1.30208vw;
    width: 1.04167vw;
    height: 1.25vw;
  }

  .main-my-bank__bottom-nav-buttons {
    gap: 0.72917vw;
  }

  .main-my-bank__bottom-nav-buttons button {
    width: 1.97917vw;
    height: 1.97917vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.97917vw;
    flex: 0 0 1.97917vw;
    font-size: 1.14583vw;
    border: 0.05208vw solid var(--text-dark-gray);
  }

  .main-my-bank__sad-emogi {
    max-width: 4.63542vw;
    height: 4.42708vw;
  }

  .main-my-bank__sad-emogi:not(:last-child) {
    margin-bottom: 0.52083vw;
  }

  .reminders .topbar__title {
    padding: 1.041666vw 6.7708333vw;
  }

  .main-reminders {
    padding-top: 1.5625vw;
    padding-bottom: 7.8125vw;
  }

  .main-reminders__header {
    gap: 1.0416666667vw;
  }

  .main-reminders__header:not(:last-child) {
    margin-bottom: 2.0833333333vw;
  }

  .main-reminders__nav-list {
    gap: 2.6041666vw;
    padding: 0.78125vw 0;
  }

  .main-reminders__button {
    font-size: 1.04166666vw;
    padding: 0.2604166vw 0;
    gap: 0.3645833vw;
  }

  .main-reminders__button .icon {
    width: 1.09375vw;
    height: 1.09375vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.09375vw;
    flex: 0 0 1.09375vw;
  }

  .main-reminders__filter {
    gap: 0.625vw;
  }

  .main-reminders__filter .text {
    font-size: 0.9375vw;
    margin-left: 0.5208333vw;
  }

  .main-reminders__filter .arrow {
    width: 0.78125vw;
  }

  .main-reminders__item:not(:last-child) {
    margin-bottom: 1.5625vw;
  }

  .single-reminder__content {
    -webkit-filter: drop-shadow(0 0 1.458333vw rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 0 1.458333vw rgba(0, 0, 0, 0.16));
    border-radius: 0.4166666vw;
    padding: 0.88541666vw 0.9895833vw 0.88541666vw 1.40625vw;
  }

  .single-reminder__image-ibg {
    width: 6.041666vw;
    padding-bottom: 6.041666vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 6.041666vw;
    flex: 0 0 6.041666vw;
    margin-left: 1.3020833vw;
  }

  .single-reminder__image-ibg img {
    border-radius: 0.625vw;
  }

  .single-reminder__text-content {
    padding-left: 1.6666666vw;
  }

  .single-reminder__title {
    font-size: 1.25vw;
  }

  .single-reminder__title:not(:last-child) {
    margin-bottom: 0.20833vw;
  }

  .single-reminder__text {
    font-size: 1.04166vw;
  }

  .single-reminder__close {
    width: 1.45833vw;
    height: 1.45833vw;
  }

  .single-reminder__close::before,
  .single-reminder__close::after {
    height: 0.1041666vw;
    border-radius: 0.3125vw;
  }

  .main-all-categories {
    padding-bottom: 8.8542vw;
    padding-top: 1.5625vw;
  }

  .main-all-categories__steps-numbers:not(:last-child) {
    margin-bottom: 0.9375vw;
  }

  .main-all-categories__titles-block:not(:last-child) {
    margin-bottom: 2.0833vw;
  }

  .main-all-categories__title {
    font-size: 1.8229vw;
  }

  .main-all-categories__title:not(:last-child) {
    margin-bottom: 0.3125vw;
  }

  .main-all-categories__subtitle {
    font-size: 1.1458vw;
  }

  .main-all-categories__list {
    gap: 2.6042vw;
  }

  .main-all-categories__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3333% - 1.7361109375vw);
    flex: 0 0 calc(33.3333% - 1.7361109375vw);
  }

  .choosed-presents .topbar {
    padding: 2.60416666vw 0rem 2.08333vw 0rem;
  }

  .choosed-presents .topbar__body {
    gap: 4.1666666vw;
  }

  .choosed-presents .topbar__title {
    font-size: 1.6666vw;
  }

  .timer-topbar__body {
    gap: 1.1458333vw;
  }

  .timer-topbar__section {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(25% - 0.859375vw);
    flex: 0 0 calc(25% - 0.859375vw);
  }

  .timer-topbar__section-number {
    font-size: 1.5625vw;
    height: 2.65625vw;
    border-radius: 0.2604166vw 0.2604166vw 0 0;
  }

  .timer-topbar__section-name {
    font-size: 0.729166vw;
    height: 2.08333vw;
    -webkit-box-shadow: 0 0.3645833vw 0.2604166vw rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.3645833vw 0.2604166vw rgba(0, 0, 0, 0.16);
  }

  .main-choosed-presents {
    padding-top: 2.0833333333vw;
    padding-bottom: 5.2083333333vw;
  }

  .main-choosed-presents__header {
    gap: 0.78125vw;
  }

  .main-choosed-presents__header:not(:last-child) {
    margin-bottom: 3.125vw;
  }

  .main-choosed-presents__nav-list {
    gap: 0.78125vw;
  }

  .main-choosed-presents__nav-button {
    font-size: 1.0416666667vw;
    padding: 0.2604166667vw 0.15625vw;
    gap: 0.3645833333vw;
  }

  .main-choosed-presents__nav-button .icon {
    width: 0.9375vw;
    height: 0.8333333333vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0.9375vw;
    flex: 0 0 0.9375vw;
  }

  .main-choosed-presents__nav-button::after {
    height: 0.1041666667vw;
    bottom: -0.5208333333vw;
  }

  .main-choosed-presents__header-buttons {
    gap: 1.0416666667vw;
  }

  .main-choosed-presents__header-buttons > * {
    width: 14.7916666667vw;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.7916666667vw;
    flex: 0 0 14.7916666667vw;
    font-size: 1.0416666667vw;
  }

  .main-choosed-presents__header-buttons > *::after {
    left: 0.7291666667vw;
  }

  .main-choosed-presents__steps:not(:last-child) {
    margin-bottom: 2.34375vw;
  }

  .main-choosed-presents__title {
    font-size: 1.8229166vw;
  }

  .main-choosed-presents__title:not(:last-child) {
    margin-bottom: 2.2604166667vw;
  }

  .main-choosed-presents__list {
    gap: 1.8229166vw;
    row-gap: 2.8645833vw;
  }

  .main-choosed-presents__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.33333% - 1.2152776vw);
    flex: 0 0 calc(33.33333% - 1.2152776vw);
    width: calc(33.33333% - 1.2152776vw);
  }
}

@media (any-hover: hover) {
  .btn:hover {
    background-color: var(--main-pink);
  }

  .btn-black:hover {
    background-color: var(--green);
  }

  .menu__link:hover {
    color: var(--text-pink);
  }

  .menu__link.dropdown .dropdown__link:hover {
    color: #fff;
    background-color: var(--green);
  }

  .footer__link:hover {
    text-decoration: underline;
  }

  .footer__link-icon:not(p):hover {
    text-decoration: underline;
  }

  .credentials-page_orange .btn:hover {
    background-color: #f8af1d;
  }

  .main-products__slider-prev:hover,
  .main-products__slider-next:hover {
    background-color: #bebebe;
  }

  .main-products__btn-container .btn-outline-white:hover {
    background-color: var(--green);
    color: #fff;
  }

  .main-products__btn-container .btn-outline-white:hover::after,
  .main-products__btn-container .btn-outline-white:hover::before {
    content: "";
    -webkit-filter: invert(1);
    filter: invert(1);
  }

  .products .single-products-card__link:hover {
    background-color: var(--green);
  }

  .main-single-product__describe a:hover,
  .main-single-product__describe button:hover {
    color: #fdc02e;
  }

  .single-reminder__content:hover .single-reminder__title span {
    color: var(--text-pink);
  }

  .main-choosed-presents__header-buttons > *:hover {
    background-color: var(--text-green);
  }

  .main-choosed-presents .single-products-card__link:hover {
    background-color: #ffaf00;
  }

  .filter__option-categories-title:hover {
    background-color: var(--main-pink);
  }

  .filter__option-categories-title:hover .icon {
    background-color: var(--main-pink);
  }

  .filter__option-categories-title:hover .text {
    color: #fff;
  }

  .filter__option-categories-title:hover .arrow svg path {
    stroke: #fff;
  }
}

/* horizontal products */

.main-products__list-wrapper .main-products__list.products-list.column {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__body {
  display: flex;
  justify-content: space-between;
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__image-ibg {
  /* flex: 1; */
  max-width: 10rem;
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__image-ibg {
  padding-bottom: 0;
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__text-content {
  display: flex;
  flex-direction: column;
  /* flex: 3; */
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__title {
  order: -1;
}

.main-products__list-wrapper .main-products__list.products-list.column .single-products-card__price {
  order: -1;
}

/* horizontal products */

.image-popup-container .image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image-popup-container .image-popup-overlay .image-popup-control-wrapper {
  display: flex;
  /* position: absolute; */
  /* top: -50px; */
}
.image-popup-container .image-popup-overlay .image-popup-controls {
  color: #fff;
  font-size: 20px;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  width: 30px;
  height: 30px;
}

.custom-product-shadow .single-products-card {
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.06);
}

.loading {
  position: relative;
  overflow: hidden;
}
.loading::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.35) url("../img/loading.gif") center / 40px no-repeat;
}

@media (max-width: 47.99875em) {
  .loading::after {
    background: rgba(255, 255, 255, 0.35) url("../img/loading.gif") center / 30px no-repeat;
  }
  .main-products__list-wrapper.my-bank-mobile {
    background-color: #fff;
  }
  .main-products__list-wrapper.my-bank-mobile .single-products-card__title {
    color: #000;
  }
  .main-my-bank__bottom-btns {
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    z-index: 10;
  }
  .main-my-bank__bottom-btns .btn {
    width: 50vw;
    padding-left: 2em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 1.7vh;
    padding-bottom: 1.7vh;
  }
  .main-my-bank__bottom-btns .btn.green {
    border-top-right-radius: 0;
  }
  .main-my-bank__bottom-btns .btn-white {
    border-top-left-radius: 0;
  }
  .main-gift-basket__container.mobile {
    margin: 2em 0 4em;
  }
  .main-gift-basket__container.desktop {
    display: none;
  }

  .main-choosed-presents__list {
    padding-bottom: 4em;
  }
}

.btn-who-also-bought {
  cursor: pointer;
  user-select: none;
  position: relative;
}
.btn-who-also-bought .btn-who-also-bought__body {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s ease 0s, visibility 0.3s ease 0s;
  padding: 0.625rem;
  border-radius: 0.25rem;
}
.btn-who-also-bought .btn-who-also-bought__body ul li:not(:last-child) {
  margin-bottom: 0.625rem;
}

.btn-who-also-bought:hover .btn-who-also-bought__body {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

.btn-who-also-bought__buyer-name {
  font-size: 0.875rem;
  line-height: 1.2;
  color: var(--text-primary);
  font-weight: 600;
}
.btn-who-also-bought__buyer-name:not(:last-child) {
  margin-bottom: 0.3125rem;
}
.btn-who-also-bought__product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.btn-who-also-bought__product-name {
  font-size: 0.875rem;
  line-height: 1.2;
  color: var(--text-primary);
  font-weight: 400;
}
.btn-who-also-bought__product-image {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.25rem;
  flex: 0 0 1.875rem;
  overflow: hidden;
  position: relative;
}
.btn-who-also-bought__product-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.street-wrapper {
  display: flex;
  column-gap: 20px;
}
.street-wrapper .building-number {
  flex: 20%;
}
.street-wrapper .building-number .input-title {
  right: 0;
}
.street-wrapper .street {
  flex: 80%;
}

.dropdown-menu.inner.show {
  max-height: 24vh;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.8vh;
  width: 100%;
  z-index: 20;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  color: #212529;
}

.dropdown-menu.inner.show li {
}

.dropdown-menu.inner.show li button {
  border-bottom: 0.0625rem solid #d9d6d6;
  display: block;
  width: 100%;
  text-align: right;
  padding: 0.8vh 1.5vh;
  font-size: 2vh;
  transition: background-color 0.2s ease 0s;
}
.dropdown-menu.inner.show li button:hover {
  background-color: #f7f7f7;
}

.dropdown-menu.inner.show::-webkit-scrollbar {
  width: 0.8vh;
}

.dropdown-menu.inner.show::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--main-pink);
  border-radius: 0.28125rem;
}

.main-list {
  list-style-type: none;
}

.nested-list {
  list-style-type: none;
}

.main-list li,
.nested-list li {
  margin-bottom: 1vh;
}

.terms-title {
  text-align: center;
  font-weight: 700;
  text-decoration: underline;
}

.popup__phone-row {
  display: flex;
  align-items: center;
}
.popup__phone-remove {
  color: var(--text-secondary);
  font-size: 4rem;
  /* padding: 1.2vw; */
  margin-right: 0.3em;
}

.popup__phone-btns {
  padding-left: 2em;
}
.popup-phones__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
}
.popup-phones__submit.add-phone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1vw;
}
.popup-phones .input-wrapper {
  width: 80%;
}
.popup-phones .popup__title {
  margin-bottom: 1.5em;
}

.popup.popup-phone .popup__title {
  margin-bottom: 1rem;
}

.main-choose-event__navigation.my-bank {
  margin-bottom: 2vh;
}

.min-digits-error {
  position: absolute;
  top: calc(100% + 20px);
  right: 2vw;
  color: red;
}

.products-list__item.placeholder {
  position: relative;
  overflow: hidden;
}
.products-list__item.placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #edebeb;
  z-index: 10;
  border-radius: 0.625vw;
}

.products-list__item.placeholder .single-products-card{
  position: relative;
  box-shadow:none;
}

.products-list__item{
  cursor:pointer;
}